.service-page-help,
.service-page-our-consultants {
    display: flex !important; // scss-lint:disable ImportantRule
    flex-direction: column !important; // scss-lint:disable ImportantRule
    justify-content: center;
    margin: 0 auto 60px;
    max-width: 360px;
    padding: 0 30px;

    @include tablet() {
        max-width: 510px;
    }

    @include desktop() {
        flex-direction: row !important; // scss-lint:disable ImportantRule
        flex-wrap: wrap;
        margin: 0 auto 120px;
        max-width: 1200px;
        padding: 0 60px;
    }

    .pagebuilder-column-group {
        flex-direction: column !important; // scss-lint:disable ImportantRule
        flex-wrap: wrap;
        justify-content: center;

        @include desktop() {
            flex-direction: row !important; // scss-lint:disable ImportantRule
        }
    }

    h2 {
        font-size: 40px;
        letter-spacing: $letter-spacing-xs;
        margin-bottom: 35px;

        @include mobile() {
            font-size: 40px;
        }

        @include desktop() {
            font-size: $f-size-xxl;
        }
    }

    h3 {
        font-family: $heading-font-family;
        font-size: $f-size-l;
        font-weight: $f-weight-light;
    }

    p {
        font-weight: $f-weight-light;
        line-height: 1.8;
        padding-bottom: 0;
    }

    .column-width-4 {
        margin: 0 auto 30px;
        max-width: 300px;
        width: 100% !important; // scss-lint:disable ImportantRule

        @include tablet() {
            max-width: 450px;
        }

        @include desktop() {
            margin: 0 17px 30px;
            max-width: 300px;
            width: calc((100% / 3) - 34px) !important; // scss-lint:disable ImportantRule
        }

        [data-content-type="text"] {
            margin-top: 20px;
        }

        p {
            font-size: $f-size-base;
            line-height: $line-height-s;
        }

        h3 {
            letter-spacing: $letter-spacing-sm;
            margin-bottom: 0;
        }
    }

    .top-text {
        margin-bottom: 60px;

        @include desktop() {
            align-items: center;
        }

        [data-content-type="text"] {
            margin: 0 auto;
            max-width: 750px;
        }
    }

    .bottom-text {
        @include desktop() {
            margin-top: 23px;
        }

        p {
            font-size: $f-size-base;
            line-height: normal;
        }
    }

    [data-content-type="buttons"] {
        margin: 0 auto;
        max-width: 240px;

        [data-content-type="button-item"] {
            margin-bottom: 20px;
        }
    }

    &-updated {
        @include tablet() {
            max-width: 100%;
            padding: 0;
        }

        .pagebuilder-column-group {
            @include tablet() {
                flex-direction: row !important; // scss-lint:disable ImportantRule
            }
        }

        .column-width-4 {
            @include tablet() {
                margin-bottom: 20px;
                max-width: 300px;
                width: calc((100% / 3) - 16px) !important; // scss-lint:disable ImportantRule
            }

            @include desktop() {
                width: calc((100% / 3) - 34px) !important; // scss-lint:disable ImportantRule
            }

            &:nth-of-type(1) {
                @include tablet() {
                    margin-left: 0;
                    margin-right: 16px;
                }

                @include desktop() {
                    margin: 0 17px 30px;
                }
            }

            &:nth-of-type(2) {
                @include tablet() {
                    margin-left: 8px;
                    margin-right: 8px;
                }

                @include desktop() {
                    margin: 0 17px 30px;
                }
            }

            &:nth-of-type(3) {
                @include tablet() {
                    margin-left: 16px;
                    margin-right: 0;
                }

                @include desktop() {
                    margin: 0 17px 30px;
                }
            }
        }
    }
}

.our-consultants-slider {
    .slick-list {
        background-color: $c-grey-gainsboro;

        @include tablet() {
            padding: 0 25% 0 0;
        }
    }

    .sw-slider-text,
    .slick-disabled {
        opacity: 0;
    }

    .sw-slider-image {
        position: relative;

        .sw-image {
            max-width: 750px;
            opacity: .2;

            @include desktop() {
                max-height: 640px;
            }
        }
    }

    .slick-current {
        .sw-slider-text,
        .sw-image {
            opacity: 1;
            transition: opacity .5s ease-in-out;
        }

        .slick-disabled {
            opacity: 0;
        }
    }

    .sw-slider-slide {
        position: relative;

        .sw-slider-text {
            background: transparent;
            display: block;
            font-family: $heading-font-family;
            margin: $content-spacing-m $content-spacing-xxl;
            padding: 0;
            position: relative;
            text-align: center;

            @include desktop() {
                left: auto;
                position: absolute;
                right: 6%;
                text-align: left;
                top: 50%;
                transform: translateY(-50%);
                width: 300px;
            }

            p {
                font-size: $f-size-ml;
                font-weight: $f-weight-light;
                letter-spacing: $letter-spacing-xxs;
                line-height: 1;
                padding-bottom: 0;

                &::before {
                    content: open-quote;
                }

                &::after {
                    content: close-quote;
                }
            }

            hr {
                @include size(18px, 1px);
                margin: 30px auto 15px;

                @include desktop() {
                    margin: 30px 0 15px;
                }
            }

            h6 {
                font-family: $base-font-family;
                font-size: $f-size-base;
            }
        }
    }

    .slick-dots {
        display: none !important; // scss-lint:disable ImportantRule

        @include desktop() {
            bottom: 30px;
            display: flex !important; // scss-lint:disable ImportantRule
            left: 50%;
            transform: translateX(-50%);
            width: 515px;
        }

        li {
            border-bottom: 1px solid $c-white;
            margin: 0;
            width: 100%;

            &.slick-active {
                border-bottom: 2px solid $c-white;
            }
        }

        button {
            opacity: 0;

            &::before {
                content: none;
            }
        }
    }

    .slick-prev,
    .slick-next {
        cursor: pointer;
        height: 30px;
        top: 17%;

        @include mobile() {
            top: 25%;
        }

        @include tablet() {
            top: 30%;
        }

        @include desktop() {
            top: 50%;
        }

        &::before {
            content: url('../images/icon-arrow-right-small.svg');
        }
    }

    .slick-next {
        right: 11%;
        transform: translateY(-50%) scale(.6);
    }

    .slick-prev {
        left: 5%;
        transform: translateY(-50%) rotate(180deg) scale(.6);
    }
}

.service-page-our-consultants {
    max-width: none;
    padding: 0;

    @include desktop() {
        padding-left: 120px;
    }

    .top-text,
    .bottom-text {
        padding: 0 30px;

        @include desktop() {
            padding: 0 120px 0 0;
        }
    }
}

.sticky-live-chat {
    bottom: 11px;
    cursor: pointer;
    max-width: 85px;
    position: fixed;
    right: 21px;
    z-index: $z-over-modals;
}

.stores-slider-list {
    padding-left: 15px;
    position: relative;

    @include mobile() {
        padding-left: 30px;
    }

    @include desktop() {
        padding-left: 120px;
    }

    &.slick-initialized {
        margin-bottom: 0;

        .slick-slide {
            display: flex;
            height: 420px;

            @include tablet() {
                height: 486px;
            }
        }
    }

    .slider-overlay {
        position: relative;

        &::after {
            @include tablet() {
                @include size(147px, 485px);
                background: $c-store-slide-gradient;
                content: '';
                pointer-events: none;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }

    .store-item {
        background-color: $c-grey-gallery;
        box-shadow: 0 5px 40px -15px $c-store-slide-shadow-black;
        flex-direction: column;
        margin: 0 15px 60px 0;
        max-width: 280px;

        @include mobile() {
            margin-right: 25px;
            max-width: 305px;
        }

        @include tablet() {
            background-color: $c-white;
            max-width: none;
        }

        .store-image,
        .store-info {
            width: 100%;

            @include tablet() {
                max-height: 260px;
                width: 381px;
            }
        }

        .store-info {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            padding: 30px 15px;
            text-align: center;

            @include tablet() {
                padding: 30px;
            }
        }

        .description {
            display: none;
        }

        h2 {
            font-size: $f-size-l;
        }

        .address {
            font-size: $f-size-base;
            line-height: $line-height-s;

            .phone {
                display: block;
                font-weight: $f-weight-bold;
            }
        }

        .description,
        .address,
        h2,
        .store-bottom-info {
            margin: 0 auto;
        }

        .store-bottom-info {
            @include size(240px, 79px);
            // TODO remove when book a appointment will be ready
            display: flex;
            flex-direction: column;
            // TODO remove when book a appointment will be ready

            .book-an-appointment {
                margin-bottom: 10px;
            }
        }

        .description,
        .address {
            padding-bottom: 0;
            width: 255px;
        }
        &.store-item-noimage {
            .store-image {
                display: none;
            }

            .store-info {
                max-height: none;
                @include desktop() {
                    padding-top: 75px;
                }
            }

            .description {
                display: block;
                font-size: $f-size-s;
                line-height: 1.8;
                margin-top: 40px;
            }

            h2 {
                @include desktop() {
                    font-size: $f-size-xl-mobile;
                    margin-bottom: 20px;
                }
            }
        }
        &.first-store {
            background-color: $c-grey-gallery;

            @include desktop() {
                flex-direction: row;
            }

            .small-image {
                @include desktop() {
                    display: none !important; // scss-lint:disable ImportantRule
                }
            }

            .base-image {
                display: none !important; // scss-lint:disable ImportantRule

                @include desktop() {
                    display: block !important; // scss-lint:disable ImportantRule
                }
            }

            .store-info {
                @include tablet() {
                    max-height: none;
                }

                @include desktop() {
                    padding-top: 75px;
                }
            }

            .description {
                @include desktop() {
                    display: block;
                    font-size: $f-size-s;
                    line-height: 1.8;
                    margin-top: 40px;
                }
            }

            h2 {
                @include desktop() {
                    font-size: $f-size-xl-mobile;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .slick-prev,
    .slick-next {
        cursor: pointer;
        height: 30px;

        &::before {
            content: url('../images/icon-arrow-right-small.svg');
        }

        &.slick-disabled {
            &::before {
                opacity: 0;
            }
        }
    }

    .slick-next {
        transform: translateY(-50%) scale(.6);
    }

    .slick-prev {
        transform: translateY(-50%) rotate(180deg) scale(.6);
    }

    .slick-dots {
        bottom: 0;
        display: flex;
        left: 50%;
        max-width: 305px;
        transform: translateX(-50%);

        @include tablet() {
            max-width: 515px;
        }

        li {
            border-bottom: 1px solid $c-brown-darker;
            margin: 0;
            width: 100%;

            &.slick-active {
                border-bottom: 2px solid $c-brown-darker;
            }

            button {
                width: auto;
            }
        }

        button {
            opacity: 0;

            &::before {
                content: none;
            }
        }
    }

    .first-slide-active {
        left: 0 !important; // scss-lint:disable ImportantRule
        transform: none !important; // scss-lint:disable ImportantRule
    }

    .base-image {
        max-height: 486px;
    }
}

.visit-us-widget-container {
    .slider-title {
        font-size: $f-size-ml;
        letter-spacing: $letter-spacing-xs;
        margin-bottom: 20px;
        text-align: center;

        @include desktop() {
            font-size: $f-size-xl;
        }
    }
}

.get-in-touch {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 60px 15px;

    @include mobile() {
        padding: 60px 30px;
    }

    @include tablet() {
        padding: 60px;
    }

    @include desktop() {
        align-items: normal;
        flex-direction: row;
        padding: 60px 120px;
    }

    .pagebuilder-column-group {
        align-items: center;
        flex-direction: column !important; // scss-lint:disable ImportantRule
        flex-wrap: wrap;
        justify-content: center;

        @include desktop() {
            flex-direction: row !important; // scss-lint:disable ImportantRule
        }
    }

    .block-title {
        max-width: 360px;
        width: 100%;
    }

    .block-title {
        overflow: hidden;
        padding: 0 15px;

        @include mobile() {
            padding: 0 30px;
        }

        @include desktop() {
            margin-bottom: 35px;
            max-width: none;
            padding: 0;
        }

        h2 {
            font-size: 40px;
            letter-spacing: $letter-spacing-xs;
            margin: 0;

            @include tablet() {
                margin-bottom: 10px;
            }

            @include desktop() {
                font-size: $f-size-xxl;
            }
        }
    }

    .col-3 {
        border-bottom: 1px solid $c-gery-silver-border !important; // scss-lint:disable ImportantRule
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 360px;
        padding: 20px 30px 30px !important; // scss-lint:disable ImportantRule
        text-align: center;
        width: 100% !important; // scss-lint:disable ImportantRule
        align-self: auto !important; // scss-lint:disable ImportantRule

        @include desktop() {
            border-bottom: 0 !important; // scss-lint:disable ImportantRule
            border-right: 1px solid $c-gery-silver-border !important; // scss-lint:disable ImportantRule
            padding: 15px 60px !important; // scss-lint:disable ImportantRule
            text-align: left;
            width: calc(100% / 3) !important; // scss-lint:disable ImportantRule
            align-self: stretch;
        }

        p {
            font-size: $f-size-base;
            line-height: $line-height-s;
            padding-bottom: 0;
        }

        &:last-child {
            border: 0 !important; // scss-lint:disable ImportantRule
            padding-bottom: 0 !important; // scss-lint:disable ImportantRule

            @include desktop() {
                padding-bottom: 15px !important; // scss-lint:disable ImportantRule
            }
        }
    }

    button {
        width: 240px;
    }

    .first-row {
        margin-bottom: 30px;

        @include desktop() {
            margin-bottom: 15px;
        }

        p:first-child {
            font-family: $heading-font-family;
            font-size: $f-size-l;
            margin-bottom: 15px;
        }
    }

    .reach-us {
        .first-row {
            p:first-child {
                margin-bottom: 0;
            }

            p:last-child {
                font-family: $heading-font-family;
                font-size: $f-size-xl;
            }
        }
    }

    .call-us,
    .chat-with-us {
        .first-row {
            p:first-child {
                // scss-lint:disable NestingDepth
                &::before {
                    @include size(33px, 35px);
                    content: url('../images/icon-phone.svg');
                    display: inline-block;
                    margin-left: -9px;
                    vertical-align: middle;
                }
                // scss-lint:enable NestingDepth
            }
        }
    }

    .chat-with-us {
        .first-row {
            p:first-child {
                // scss-lint:disable NestingDepth
                &::before {
                    content: url('../images/icon-live-chat.svg');
                }
                // scss-lint:enable NestingDepth
            }
        }
    }
}

.service-page-private-shopping {
    margin: 0 auto 120px;
    padding: 60px 0 30px;

    h2 {
        font-size: 44px;
        padding: 0 30px;

        @include desktop() {
            font-size: 60px;
            margin-bottom: 15px;
        }
    }

    .top-text {
        margin-bottom: 10px;
        padding: 0 15px;

        @include mobile() {
            padding: 0 30px;
        }

        @include desktop() {
            margin-bottom: 30px;
            align-items: center;
        }

        [data-content-type="text"] {
            margin: 0 auto;
            max-width: 750px;
        }

        p {
            font-weight: $f-weight-light;
            line-height: $line-height-s;
            padding-bottom: 0;
        }
    }
}

.updated-black-slider {
    &::before,
    &::after {
        @include size(130px);
        content: '';
        position: absolute;
    }

    &::before {
        background: url(../images/cms/quote-top-2x.png) no-repeat center center;
        background-size: contain;
        left: 4%;
        top: -25px;
    }

    &::after {
        background: url(../images/cms/quote-bottom-2x.png) no-repeat center center;
        background-size: contain;
        bottom: -80px;
        right: 10%;
        z-index: -1;
    }

    &.bf-banner {
        height: auto;
        margin-top: 60px;
        padding: 60px 0 0;
    }

    .stores-slider-list {
        padding: 0;
        height: auto;

        .slick-slide {
            height: 100%;
        }

        .store-item {
            background: none;
            box-shadow: none;
            height: 100%;
            margin: 0;
            padding: 0 7px;

            @include tablet() {
                padding: 0 10px;
            }

            .store-info {
                background-color: $c-grey-fuscous;
                color: $c-white;
                display: block;
                max-height: none;
                padding: 50px 20px;
                text-align: left;
                width: 100%;

                @include tablet() {
                    padding: 100px 60px 90px
                }
            }

            .store-top-info {
                h2 {
                    color: $c-white;
                    display: block;
                    font-family: $f-lafayette148;
                    font-size: 26px;
                    font-weight: $f-weight-light;
                    text-transform: uppercase;

                    @include tablet() {
                        font-size: 44px;
                    }
                }
            }

            .store-bottom-info {
                display: none;
                font-family: $f-perpetua-titling;
                font-size: 15px;
                height:auto;
                margin: 30px 0 0;

                @include tablet() {
                    display: block;
                }
            }
        }
    }
}
