[data-content-type="editorial_slider"] {
    margin: 17.33% auto 33%;
    max-width: $storyblock-max-width;
    position: relative;
    width: 100%;

    @include tablet() {
        margin: 5.41% auto 14.41%;
    }

    @include desktop() {
        margin: 1% auto 9.2%;
    }

    &:not(.slick-initialized) {
        display: flex;
        justify-content: center;

        .slick-slide {
            &:nth-of-type(2) {
                transform: scale(1);
            }

            &:nth-of-type(n+4) {
                display: none;
            }
        }
    }

    .slick-slide {
        max-width: 64.53vw;
        position: relative;
        transform: scale(0.68);
        transition: transform .5s;
        width: 100%;

        @include tablet() {
            max-width: 32.16vw;
            margin: 0 -10px;
        }

        @include desktop() {
            margin: 0 -15px;
            max-width: 26.25vw;
        }

        @include media(1366px) {
            margin: 0 -20px;
        }

        @include media($storyblock-max-width) {
            max-width: 441px;
        }

        &.slick-center {
            transform: scale(1);

            .image-hotspots {
                display: block;
            }
        }

        .image-wrapper {
            padding-top: 167.3469%;
            position: relative;
            width: 100%;

            img {
                position: absolute;
                height: auto;
                left: 0;
                top: 0;
                width: 100%;
            }
        }
    }

    .slick-dots {
        left: 50%;
        padding: 0 !important;
        transform: translateX(-50%);
        white-space: nowrap;
        width: auto;

        li {
            height: 12px;
            width: 12px;

            button {
                background-color: $c-grey-nickle-approx;
                border-radius: 100%;
                height: 12px;
                padding: 0;
                width: 12px;

                &:before {
                    content: '';
                    display: none;
                }
            }

            &.slick-active {
                button {
                    background-color: $c-tundura;
                    color: $c-white;
                }
            }
        }
    }
}
