// Shop menu dropdown
.submenu {
    .topmenu-block,
    .submenu-block {
        @include desktop() {
            display: flex;
            opacity: 0;
            transition: .5s opacity .3s ease-in;
        }

        a,
        .level-top-span {
            color: $c-black;
        }
    }

    .topmenu-block {
        @include desktop() {
            flex-basis: 34%;
            justify-content: flex-end;
            max-width: 248px;
            padding: 45px 30px 38px;
        }

        .top-level-topmenu {
            @include desktop() {
                display: flex !important; // scss-lint:disable ImportantRule
            }
        }
    }

    .submenu-block {
        @include desktop() {
            flex-basis: 66%;
            max-width: 750px;
            padding: 43px 0 38px 34px;

            .sub-right {
                margin-right: 70px;
            }
        }

        .top-level-submenu {
            @include desktop() {
                display: flex !important; // scss-lint:disable ImportantRule
            }
        }
    }

    &[aria-expanded="true"] {
        .topmenu-block,
        .submenu-block {
            @include desktop() {
                opacity: 1;
            }
        }
    }
}

.main-menu.initial-main-menu {
    .level0.category-item:hover {
        .topmenu-block,
        .submenu-block {
            @include desktop() {
                opacity: 1;
            }
        }
    }
}


.top-level-topmenu {
    @include desktop() {
        align-items: flex-end;
        flex-direction: column !important; // scss-lint:disable ImportantRule
        flex-grow: 1;
    }

    .level2 {
        @include desktop() {
            border-bottom: 1px solid $c-grey-darker-border !important; // scss-lint:disable ImportantRule
            max-width: 180px;
            padding: 8px 0;
            text-transform: uppercase;
            width: 100%;
        }

        &.first {
            @include desktop() {
                border-top: 1px solid $c-grey-darker-border !important; // scss-lint:disable ImportantRule
            }
        }

        a {
            @include desktop() {
                font-weight: $f-weight-bold !important; // scss-lint:disable ImportantRule
                letter-spacing: .7px !important; // scss-lint:disable ImportantRule
                line-height: 2.5 !important; // scss-lint:disable ImportantRule
                padding: 0 16px !important; // scss-lint:disable ImportantRule
            }
        }
    }
}

.top-level-submenu {
    @include desktop() {
        flex-grow: 1;
    }

    .level2.category-item {
        @include desktop() {
            flex-grow: 1;
            max-width: 170px;
            padding: 0 10px 0 0;
        }

        > span {
            @include desktop() {
                color: $c-grey-kelp;
                display: block;
                font-size: $f-size-base;
                letter-spacing: .5px;
                line-height: 1;
                margin-bottom: 13px;
                text-transform: uppercase;
            }
        }

        .level3 {
            @include desktop() {
                line-height: 1.15;
                margin-bottom: 8px;
            }

            > a,
            > span {
                @include desktop() {
                    letter-spacing: .5px;
                }
            }
        }
    }
}

.level1.category-item.menu-desktop-image,
.level2.category-item.menu-desktop-image {
    color: $c-darker;
    display: none;
    height: 290px;
    padding-right: 0;
    position: relative;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);

    @include desktop() {
        display: block;
    }

    > a {
        align-items: center;
        color: inherit;
        display: flex;
        flex-direction: column;
        font-family: $f-lafayette148;
        font-size: $f-size-l;
        font-weight: $f-weight-base;
        height: 100%;
        justify-content: center;
        letter-spacing: .8px;
        line-height: 28px;
        padding: 0 10px;
        position: absolute;
        top: 0;
        width: 270px;
        z-index: 1;

        span {
            text-align: center;
        }
    }

    &.menu-desktop-image-bottom {
        > a {
            justify-content: flex-end;
        }
    }

    &.menu-desktop-image-top {
        > a {
            justify-content: flex-start;
        }
    }

    .menu-item-image {
        height: 342px;
        position: absolute;
        top: -28px;
        width: 270px;
    }

    .cta-text {
        font-family: $f-lineto-akkurat;
        font-size: $f-size-base;
        font-weight: $f-weight-bold;
    }
}
