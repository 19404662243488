@mixin heading() {
    .heading {
        margin: 0 auto 0 0;
        max-width: 300px;
        padding: 33px 30px 0 2px;

        @include mobile() {
            max-width: 350px;
        }

        @include tablet() {
            max-width: 400px;
        }

        @include desktop() {
            left: 50px;
            padding: 0;
            position: absolute;
            right: auto;
            text-align: left;
            top: 16%;
        }

        h2 {
            letter-spacing: .15px;
            line-height: 1;
            margin-bottom: 26px;
            text-align: left;

            @include desktop() {
                color: $c-white;
                font-size: $f-size-xxxl-mobile;
                letter-spacing: .2px;
                margin-bottom: 28px;
            }

            a {
                color: $c-brown;
                font-size: 40px;
                font-weight: 300;
                line-height: 1;
                text-decoration: none;

                @include desktop() {
                    color: $c-white;
                    font-size: 50px;
                }

            }
        }

        h6 {
            font-size: 16px;
            margin-bottom: 45px;
            margin-top: -26px;

            @include desktop() {
                color: $c-white;
                margin-top: -20px;
            }

            a {
                color: $c-brown;
                font-family: $f-lineto-akkurat;
                font-size: 16px;
                font-weight: 200;
                line-height: normal;

                @include desktop() {
                    color: $c-white;
                }

            }
        }

        p {
            border-top: 3px solid;
            display: inline-block;
            left: 0;
            line-height: 1.3;
            margin-left: 2px;
            padding: 16px 80px 0 0;
            text-align: left;
            width: auto;

            @include desktop() {
                border-top: 3px solid;
                color: $c-white;
                font-size: 15px;
                letter-spacing: .8px;
                text-align: left;
            }

            a {
                font-size: 16px;
                font-weight: 600;
                letter-spacing: .2px;
                line-height: inherit;
                text-decoration: none;

                @include desktop() {
                    color: $c-white;
                }
            }
        }

        &.hide-on-desktop {
            display: block !important; // scss-lint:disable ImportantRule

            @include desktop() {
                display: none !important; // scss-lint:disable ImportantRule
            }
        }

        &.hide-on-mobile {
            display: none !important; // scss-lint:disable ImportantRule

            @include desktop() {
                display: block !important; // scss-lint:disable ImportantRule
            }
        }

        &.bottom {
            @include desktop() {
                bottom: 6%;
                top: auto;
            }
        }

        &.right {
            @include desktop() {
                left: auto;
                right: 50px;
                text-align: right;
            }

            h2 {
                @include desktop() {
                    text-align: right;
                }
            }

            p {
                @include desktop() {
                    padding-left: 80px;
                    padding-right: 0;
                    text-align: right;
                }
            }
        }

        &.vertical-center {
            @include desktop() {
                top: 50%;
                transform: translateY(-50%);
            }

            &.horizontal-center {
                @include desktop() {
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &.horizontal-center {
            @include desktop() {
                left: 50%;
                max-width: 980px;
                padding: 0 30px;
                transform: translateX(-50%);
            }
        }

        @content;
    }
}

@mixin heading-right() {
    .heading {
        @include desktop() {
            left: auto;
            right: 49px;
            text-align: right;
        }

        h2 {
            @include desktop() {
                text-align: right;
            }
        }

        p {
            @include desktop() {
                padding-left: 80px;
                padding-right: 0;
                text-align: right;
            }
        }

        &.bottom {
            @include desktop() {
                bottom: 7%;
            }
        }

        @content;
    }
}
