// Select dropdowns - Default
body {
    &.has-dropdown {
        height: 100%;
        max-height: 100vh;
        overflow: hidden;

        .modal-popup {
            overflow: hidden !important; // scss-lint:disable ImportantRule
        }
    }
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: $c-white url('../images/icon-pointer.svg') 90% center no-repeat;
    background-size: 6px 4px;
    border: 1px solid $c-input-border;
    border-radius: 0;
    color: $c-grey-fuscous;
    cursor: pointer;
    display: block;
    font-family: $f-lafayette148;
    font-size: 13px;
    font-weight: 400;
    height: 40px;
    line-height: 39px;
    max-width: 141px;
    overflow: hidden;
    padding: 0 43px 0 $content-spacing-l;
    position: relative;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;


    @include tablet() {
        color: $c-grey-fuscous;
        height: 45px;
        line-height: 44px;
        max-width: 201px;
    }

    &::-ms-expand {
        display: none;
    }
}

#sorter { // scss-lint:disable IdSelector
    background: $c-white url('../images/icon-pointer.svg') 100% center no-repeat;
    background-size: 8px 6px;
    color: $c-black;
    font-size: 15px;
    font-weight: 700;
    line-height: 27px;
    margin-left: 0;

    padding-right: 16px;
    text-align-last: right;
    text-transform: uppercase;
}

// Select dropdowns - Chosen
.chosen-container {
    display: inline-block;
    font-size: 13px;
    max-width: 141px;
    overflow: hidden;
    position: relative;
    user-select: none;
    vertical-align: middle;

    @include tablet() {
        max-width: 201px;
    }

    &.chosen-with-drop {
        overflow: visible;

        .chosen-drop {
            border: 1px solid $c-input-border;
            clip: auto;
            display: block;

            .chosen-results {
                height: auto;
            }
        }
    }

    .out-of-stock-message {
        float: right;
        margin-right: 8px;
    }

    &-single {
        &.chosen-container-single-nosearch {
            .chosen-search {
                align-items: flex-start;
                display: flex;
                height: 0;
                justify-content: flex-start;
                opacity: 0;

                overflow: hidden;
                pointer-events: none;
                position: absolute;
                top: -45px;
                width: 0;
            }

            .chosen-search-input {
                height: 45px;

                overflow: hidden;
                padding: 0;
                position: absolute;
                top: 0;
                width: 200px;
            }
        }
    }

    &-active.chosen-with-drop .chosen-single,
    .chosen-single {
        background: $c-white;
        border: 1px solid $c-input-border;
        border-radius: 0;
        cursor: pointer;
        display: block;
        height: 50px;
        line-height: 50px;
        overflow: hidden;
        padding: 0;
        position: relative;
        text-decoration: none;
        white-space: nowrap;

        @include tablet() {
            height: 50px;
            line-height: 48px;
        }

        div {
            display: block;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: 43px;

            b {
                background: url('../images/icon-pointer.svg') center center no-repeat;
                background-size: 6px 4px;
                display: block;
                height: 100%;
                width: 100%;
            }
        }

        > span {
            color: $c-grey-fuscous;
            display: block;
            font-family: $f-lafayette148;
            font-size: 13px;
            font-weight: 400;
            height: 100%;
            margin: 0 43px 0 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: calc(100% - (#{$content-spacing-l} + 43px));
        }
    }

    &-active.chosen-with-drop {
        .chosen-single {
            border-bottom-color: $c-input-border;
            border-top-color: $c-input-border;
            z-index: 1020;

            &.first {
                border-top-color: $c-input-border;
            }

            &.last {
                border-bottom-color: $c-input-border;
            }
        }

        b {
            transform: scaleY(-1);
        }
    }

    &-active:not(.chosen-with-drop),
    &.focus-within {
        outline: none;
    }

    .chosen-drop {
        background: $c-white;
        border-radius: 0;
        box-shadow: none;
        clip: rect(0, 0, 0, 0);
        margin-top: 0;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 1010;

        // scss-lint:disable PropertySortOrder
        .chosen-results {
            -webkit-overflow-scrolling: touch;
            height: 0;
            margin: 0;
            max-height: 300px;
            overflow-x: hidden;
            overflow-y: auto;
            -ms-overflow-style: scrollbar;
            padding: 0 !important; // scss-lint:disable ImportantRule
            position: relative;

            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $c-grey-main;
            }
        }
        // scss-lint:enable PropertySortOrder

        li {
            display: none;
            line-height: 15px;
            list-style: none;
            margin: 0;
            -webkit-touch-callout: none;
            word-wrap: break-word;
        }

        .active-result {
            color: $c-grey-fuscous;
            cursor: pointer;
            display: list-item;
            font-family: $f-lafayette148;
            font-size: $f-size-base;
            font-weight: 400;
            line-height: 2.5;
            margin: 0 25px 0 $content-spacing-l;
            overflow: hidden;
            padding: 0;
            text-overflow: ellipsis;
            text-transform: uppercase;
            white-space: nowrap;
            width: calc(100% - (#{$content-spacing-l} + 25px));

            @include tablet() {
                color: $c-grey-fuscous;
            }

            &.result-selected {
                border-top: 0 none;
            }

            &.highlighted {
                background: transparent;
                color: $c-grey-fuscous;
                opacity: .7;
            }
        }

        &.disabled-result {
            cursor: default;
            display: list-item;
        }

        &.no-results {
            display: list-item;
        }
    }

    &.no-scroll {
        .chosen-drop {
            left: -10000px;
        }

        &.chosen-container-active.chosen-with-drop {
            .chosen-drop {
                left: 0;
            }
        }
    }

    .chosen-focus-input {
        border: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        width: 0 !important; // scss-lint:disable ImportantRule
    }
}
