.hero-block {
    display: block !important; // scss-lint:disable ImportantRule
    margin-bottom: 75px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: calc(90vh * 16 / 9);

    @include desktop() {
        margin-bottom: 75px;
    }

    @include heading() {
        padding: 30px 0 0 33px;
        text-align: left;

        @include desktop() {
            left: 20.8%;
            max-width: none;
        }

        h2 {
            line-height: 1;
            margin-bottom: 26px;

            @include desktop() {
                letter-spacing: .2px;
                margin-bottom: 28px;
            }

            a {
                line-height: 1;

                @include desktop() {
                    font-size: 60px;
                }
            }
        }

        &.bottom {
            @include desktop() {
                bottom: 14%;
            }
        }

        &.right {
            @include desktop() {
                right: 22.5%;
            }
        }
    }

    img {
        width: 100%;
    }
}
