[data-content-type="banner_block"] {
    display: flex;
    flex-direction: column;

    @include tablet() {
        flex-direction: row;
    }

    .information-wrapper {
        padding: 42px 15px;
        text-align: center;

        @include tablet() {
            display: flex;
            flex: 1 1 50%;
            flex-direction: column;
            justify-content: center;
            margin-top: 5px;
            padding: 20px 15px 20px 4.24%;
            text-align: left;
        }

        [data-element="title"] {
            font-family: $f-lafayette148;
            font-size: 23px;
            font-weight: 300;
            letter-spacing: .1px;
            line-height: 19px;
            margin-bottom: 23px;

            @include desktop() {
                font-size: 32px;
                line-height: 27px;
                margin-bottom: 15px;
            }

            &:first-line {
                font-family: $f-perpetua-titling;
            }
        }

        [data-element="content"] {
            font-family: $f-lafayette148;
            font-size: 15px;
            font-weight: 300;
            line-height: 19px;
            margin-bottom: 18px;

            @include desktop() {
                line-height: 20px;
                margin-bottom: 15px;
            }

            p {
                font-size: inherit;
                line-height: inherit;
                margin: 0;
                padding: 0;
            }
        }

        [data-element="button"] {
            font-family: $f-lafayette148;
            font-size: 15px;
            font-weight: 700;
            letter-spacing: -.2px;
            line-height: 19px;
        }
    }

    .image-wrapper {
        @include media(max-width $bp-tablet - 1px) {
            min-height: auto !important;
        }

        @include tablet() {
            flex: 1 1 50%;
            overflow: hidden;
        }

        .image-container {
            height: 100%;
            position: relative;
            overflow: hidden;
            width: auto;

            @include media(max-width $bp-tablet - 1px) {
                padding-top: 0 !important;
            }
        }

        img {
            max-width: none;
            vertical-align: middle;
            width: 100%;

            @include tablet() {
                height: 100%;
                left: 50%;
                max-width: none;
                position: absolute;
                top: 0;
                transform: translateX(-50%);
                width: auto;
            }
        }
    }
}
