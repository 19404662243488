.cms-denim {
    .l-shape-block-1 {
        .l-shape-block {
            [data-element="caption_wrapper"] {
                padding-right: 5vw;

                @include tablet() {
                    margin-right: -1.5vw;
                    padding-right: 0;
                    top: 25%;
                }
            }

            .caption-text {
                color: $c-white !important; // scss-lint:disable ImportantRule
                font-weight: 300;
                line-height: 12px;
                text-align: right;

                @include tablet() {
                    color: $c-black !important; // scss-lint:disable ImportantRule
                    max-width: 250px;
                }

                @include desktop() {
                    max-width: 550px;
                }

                strong {
                    font-family: $f-lafayette148;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 12px;
                    text-transform: uppercase;

                    &:first-of-type {
                        line-height: 27px;
                    }
                }

                span {
                    display: block;
                    font-family: $f-lafayette148;
                    line-height: 34px;
                    padding-bottom: 10px;

                    @include tablet() {
                        padding-bottom: 0;
                    }

                    @include desktop() {
                        line-height: 50px;
                    }

                    &:first-line {
                        font-family: $f-perpetua-titling;
                    }
                }
            }
        }
    }

    .tile-storyblock-2 {
        .text-storyblock {
            .text-secondary {
                margin-right: 20px;

                @include tablet() {
                    margin-right: 22vw;
                }

                @include desktop() {
                    margin-right: 1.5vw;
                }

                @include media($storyblock-max-width) {
                    margin-right: 25px;
                }
            }
        }
    }
}
