.fw-image {
    margin: 0 30px 75px;

    @include media(max-width $bp-desktop) {
        background-color: transparent !important; // scss-lint:disable ImportantRule
    }

    @include desktop() {
        margin: 0 auto 75px;
        max-width: 1200px;
        padding: 0;
        position: relative;
    }

    @include heading() {
        h2 {
            @include desktop() {
                letter-spacing: .7px;
            }
        }

        &.bottom {
            @include desktop() {
                bottom: 6.5%;
            }
        }
    }

    img {
        vertical-align: middle;
        width: 100%;
    }
}
