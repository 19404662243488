// Footer social links
.uw-share-block,
.page-footer {
    .social-link-wrapper {
        display: flex;

        @include tablet() {
            display: none;
        }

        @include desktop() {
            display: flex;
            flex-grow: 1;
            margin-bottom: 48px;
        }

        &.tablet {
            display: none;

            @include tablet() {
                display: flex;
                flex-grow: 1;
                justify-content: flex-start;
            }
        }

        p {
            font-size: 13px;
            letter-spacing: 1px;
            line-height: 3.9;
            margin-left: 20px;
            padding-bottom: 5px;
            text-transform: uppercase;

            @include desktop() {
                font-size: $f-size-m-larger;
                line-height: 3.1;
                margin-left: 35px;
            }
        }

        a {
            background: no-repeat center center;
            flex-grow: 0;
            margin-left: 20px;
            opacity: .5;

            &:hover {
                opacity: 1;
            }

            &:nth-of-type(1) {
                margin-left: 0;
            }

            &.facebook {
                background-image: url('../images/social/icon-facebook.svg');
                background-size: 100% 100%;
                height: 40px;
                width: 32px;

                @include desktop() {
                    height: 43px;
                    width: 43px;
                }
            }

            &.twitter {
                background-image: url('../images/social/icon-twitter.svg');
                height: 40px;
                width: 32px;

                @include tablet() {
                    height: 31px;
                    width: 31px;
                }

                @include desktop() {
                    height: 34px;
                    width: 31px;
                }
            }

            &.instagram {
                background-image: url('../images/social/icon-instagram.svg');
                height: 40px;
                width: 32px;

                @include desktop() {
                    height: 43px;
                    width: 43px;
                }
            }

            &.pinterest {
                background-image: url('../images/social/icon-pinterest.svg');
                height: 40px;
                width: 32px;

                @include tablet() {
                    height: 32px;
                    width: 32px;
                }

                @include desktop() {
                    height: 34px;
                }
            }

            span {
                display: none;
            }
        }
    }
}
