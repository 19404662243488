[data-content-type="l_shape_new_slider_storyblock"] {
    margin-bottom: 59px !important;

    @include tablet() {
        margin-bottom: 62px !important;
    }

    &:not(.slick-initialized) {
        .pagebuilder-slide:nth-of-type(n+2) {
            display: none;
        }
    }

    .slick-dots {
        bottom: -35px;
    }
}

.l-shape-new-block {
    margin: 0 auto;
    max-width: $storyblock-max-width;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    width: 100%;

    &[data-video-enabled="true"] {
        &::before {
            @include position(absolute, 0 0 0 0);
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            z-index: 5;
        }
    }

    .video-wrapper {
        position: static;
        width: 100%;

        .video-desktop, .video-mobile, iframe {
            @include size(100%);
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            vertical-align: middle;
        }

        .video-mobile {
            @include tablet() {
                display: none;
            }
        }

        .video-desktop {
            display: none;

            @include tablet() {
                display: block;
            }
        }
    }

    .image-wrapper {
        display: flex;
        flex-direction: row-reverse;
        height: 100%;
        justify-content: space-between;
        position: relative;
        width: 100%;

        &::after {
            background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 75%);
            bottom: 0;
            content: '';
            display: block;
            height: 59%;
            left: 0;
            position: absolute;
            width: 100%;

            @include tablet() {
                height: 42%;
            }
        }

        picture {
            width: 100%;
        }

        img {
            max-width: 1680px;
            vertical-align: middle;
            width: 100vw;
        }

        .image {
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            width: 100%;
        }
    }

    &[data-overlay-enabled="false"] {
        .l-shape-new-wrapper {
            display: none;
        }

        [data-element="caption_wrapper"] {
            left: 50%;
        }
    }

    [data-element="caption_wrapper"] {
        bottom: 14.777%;
        left: 50%;
        padding: 0;
        position: absolute;
        text-align: center;
        transform: translateX(-50%);
        width: auto;
        white-space: nowrap;
        z-index: 10;

        @include tablet() {
            bottom: 4.116%;
            left: calc(50.019% + (100% - 50.019%) / 2);
        }

        .caption-text {
            color: $c-white;
            display: block;
            font-family: $f-lafayette148;
            font-size: 28px;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: unset;
            margin: unset;
            text-transform: uppercase;

            @include desktop() {
                font-size: 32px;
                line-height: 27px;
            }

            &::first-line {
                font-family: $f-perpetua-titling;
                font-weight: 300;
            }
        }

        @include new-cms-button-large();

        .button-wrapper {
            display: flex;
            justify-content: center;
        }
    }
}

.l-shape-new-wrapper {
    background-color: transparent;
    border-radius: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 74.133%;

    @include tablet() {
        left: 9.047%;
        width: 40.972%;
    }

    .vertical {
        background: $c-white;
        border-radius: 0 6px 0 0;
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        width: 13.309%;

        @include tablet() {
            border-radius: 6px;
            left: 19.622%;
            width: 24.273%;
        }

        @include desktop() {
            border-radius: 9px;
        }
    }

    .horizontal {
        background: $c-white;
        border-radius: 0 6px 0 0;
        bottom: 0;
        height: 4.049%;
        left: 0;
        position: absolute;
        width: 100%;

        @include tablet() {
            border-radius: 6px;
            height: 17.68%;
        }

        @include desktop() {
            border-radius: 10px;
        }
    }

    .corners {
        bottom: 4.049%;
        height: 6px;
        left: 0;
        margin-left: -6px;
        overflow: hidden;
        position: absolute;
        width: calc(13.309% + 12px);

        @include tablet() {
            bottom: 17.68%;
            left: 19.622%;
            width: calc(24.273% + 12px);
        }

        @include desktop() {
            height: 10px;
            margin-left: -10px;
            width: calc(24.273% + 20px);
        }

        &::before,
        &::after {
            border-radius: 6px;
            box-shadow: 0 0 0 24px $c-white;
            content: '';
            margin: -6px;
            padding: 6px;
            position: absolute;

            @include desktop() {
                border-radius: 10px;
                box-shadow: 0 0 0 40px $c-white;
                margin: -10px;
                padding: 10px;
            }
        }

        &::before {
            display: none;

            @include tablet() {
                display: block;
            }
        }

        &::after {
            right: 0;
        }
    }
}

[data-reverse-l-shape="true"] {
    [data-element="caption_wrapper"] {
        @include tablet() {
            left: auto;
            right: calc(50.019% + 1.191% + (100% - 50.019%) / 2);
            transform: translateX(50%);
        }
    }

    .l-shape-new-wrapper {
        left: auto;
        right: 0;

        @include tablet() {
            right: 9.047%;
        }

        .vertical {
            border-radius: 6px 0 0 0;
            left: auto;
            right: 0;

            @include tablet() {
                border-radius: 6px;
                right: 19.622%;
            }

            @include desktop() {
                border-radius: 10px;
            }
        }

        .horizontal {
            border-radius: 6px 0 0 0;

            @include tablet() {
                border-radius: 6px;
            }

            @include desktop() {
                border-radius: 10px;
            }
        }

        .corners {
            left: auto;
            margin-right: -6px;
            right: 0;

            @include tablet() {
                right: 19.622%;
            }

            @include desktop() {
                margin-right: -10px;
            }

            &::before {
                display: block;
            }

            &::after {
                display: none;

                @include tablet() {
                    display: block;
                }
            }

            &::after {
                left: auto;
                right: 0;
            }
        }
    }
}
