[data-content-type="editorial_block_large_slider"] {
    margin: 6.2% 0 11.2%;
    padding-left: 7.467%;
    padding-right: 7.467%;

    @include tablet() {
        margin: 6.2% 0 11.2%;
        padding-left: 9.115%;
        padding-right: 9.115%;
    }

    @include desktop() {
        margin: 6.2% 0 11.2%;
        padding-left: 10.893%;
        padding-right: 10.893%;
    }

    &[data-show-dots="true"] {
        padding-bottom: 42px;

        .slick-prev,
        .slick-next {
            top: calc(50% - 30px);
        }
    }

    [data-content-type="editorial_block_large"] {
        &[data-appearance="framed-small"] {
            margin: 0;
            padding: 0;
        }
    }

    .slick-arrow {
        @include size(16px, 31px);
        padding: 0;

        &::before {
            @include size(16px, 31px);
            background: url('../images/nav-arrow-right-icon.svg') no-repeat;
            background-size: 16px 31px;
            content: '';
            display: block;
            margin: 0 auto;
        }

        &:hover {
            opacity: .7;
        }
    }

    .slick-next {
        right: 0.9%;

        @include tablet() {
            right: 5%;
        }

        @include desktop() {
            right: 7.8%;
        }
    }

    .slick-prev {
        left: 0.9%;

        @include tablet() {
            left: 5%;
        }

        @include desktop() {
            left: 7.8%;
        }

        &::before {
            transform: rotate(180deg);
        }
    }

    .slick-dots {
        bottom: 0;
        left: 50%;
        padding: 0 !important;
        transform: translateX(-50%);
        white-space: nowrap;
        width: auto;

        li {
            height: 12px;
            width: 12px;

            button {
                background-color: $c-grey-nickle-approx;
                border-radius: 100%;
                height: 12px;
                padding: 0;
                width: 12px;

                &:before {
                    content: '';
                    display: none;
                }
            }

            &.slick-active {
                button {
                    background-color: $c-tundura;
                    color: $c-white;
                }
            }
        }
    }
}
