// Main Unsubscribe page
.cms-unsubscribe {
    .page-title-wrapper {
        display: none;
    }

    [data-content-type="row"].main-content {
        flex-direction: column !important; // scss-lint:disable ImportantRule
        padding: 0;

        @include tablet() {
            flex-direction: initial !important; // scss-lint:disable ImportantRule
        }

        @include desktop() {
            flex-direction: row-reverse !important; // scss-lint:disable ImportantRule
            justify-content: space-between;
            margin: 0 auto;
            max-width: 1300px;
            padding: 92px 0 70px;
        }

        .main-content-wrapper {
            padding: 0 30px;

            @include tablet() {
                padding: 0 50px 12px;
            }

            @include desktop() {
                max-width: 540px;
                padding: 0 0 0 22px;
            }
        }
    }

    [data-content-type="form_hero_block"] {
        margin-bottom: 27px !important; // scss-lint:disable ImportantRule

        @include tablet() {
            margin-bottom: 44px !important; // scss-lint:disable ImportantRule
        }

        @include desktop() {
            margin: 6px 24px 4px;
        }
    }

    .hero-background,
    .hero-background-desktop {
        background-position-y: 0;
    }

    .gen-info-block {
        h1 {
            font-family: $f-lafayette148;
            font-size: 42px;
            font-weight: 300;
            letter-spacing: -1.1px;
            line-height: 36px;
            margin-bottom: 31px;
            text-transform: uppercase;

            @include tablet() {
                font-size: 55px;
                letter-spacing: -1.3px;
                line-height: 46px;
                margin-bottom: 29px;
                width: 50%;
            }

            @include desktop() {
                margin-bottom: 29px;
            }

            &:first-line {
                font-family: $f-perpetua-titling;
                letter-spacing: 1.8px;
            }
        }

        span {
            font-size: 42px !important; // scss-lint:disable ImportantRule

            @include tablet() {
                font-size: 55px !important; // scss-lint:disable ImportantRule
            }
        }

        p {
            font-family: $f-lafayette148;
            font-size: 15px;
            font-weight: $f-weight-light;
            letter-spacing: 0;
            line-height: 27px;
            max-width: 421px;
            padding: 0 3px 25px 0;

            @include tablet() {
                max-width: 100%;
                padding-bottom: 31px;
            }

            @include desktop() {
                padding-bottom: 25px;
            }
        }
    }

    .info-block.col-2 {
        width: 100% !important; // scss-lint:disable ImportantRule

        h3 {
            font-family: $f-lafayette148;
            font-size: $f-size-l;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 27px;
            margin-bottom: 6px;
            text-transform: uppercase;
        }

        p {
            font-family: $f-lafayette148;
            font-size: 15px;
            font-weight: $f-weight-light;
            letter-spacing: .05px;
            line-height: 27px;
            max-width: 421px;
            padding-bottom: 26px;

            @include tablet() {
                letter-spacing: 0;
                max-width: 100%;
                padding-bottom: 23px;
            }
        }

        [data-content-type="button-item"] {
            background-color: $c-grey-fuscous;
            height: 48px;
            margin-bottom: 27px;
            max-width: 320px;

            @include tablet() {
                margin-bottom: 30px;
                max-width: 150px;
            }

            .pagebuilder-button-secondary {
                font-family: $f-lafayette148;
                font-size: $f-size-m;
                font-weight: $f-weight-base;
                padding: 5px;

                &:hover {
                    background-color: $c-tapa-other;
                }
            }
        }
    }
}
