// Footer newsletter
.page-footer {
    .block.newsletter {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include tablet() {
            margin-bottom: 20px;
        }

        @include tablet-landscape() {
            margin-bottom: 35px;
        }

        .subscribe {
            position: relative;

            @include desktop() {
                background-size: 15px;
            }
        }

        .title {
            color: $c-brown;
            letter-spacing: 0;
            line-height: normal;
            margin-bottom: 15px;
            margin-left: -2px;

            @include tablet-landscape() {
                margin-bottom: 38px;
            }

            @include desktop() {
                margin-bottom: 25px;
            }

            span {
                font-family: $f-perpetua-titling;
                font-size: 22px;
                text-transform: uppercase;

                @include desktop() {
                    font-size: $f-size-ml;
                }
            }

            p {
                font-size: $f-size-xs;
                padding-bottom: 0;
                padding-top: $content-spacing-m;

                @include desktop() {
                    font-size: 17px;
                    padding-top: 30px;
                }
            }
        }

        .content {
            @include tablet() {
                width: 100%;
            }
        }

        .control {
            margin-bottom: 0;
            position: relative;

            #newsletter-error {// scss-lint:disable IdSelector
                bottom: 0;
                position: absolute;
                transform: translateY(150%);
            }
        }

        input {
            background-color: $c-white;
            border: 1px solid;
            font-family: $f-lafayette148;
            font-size: 13px;
            height: 62px;
            line-height: 17px;
            max-width: 100%;
            padding: 12px 45px 16px 22px;

            @include tablet() {
                padding-left: $content-spacing-l;
                width: 100%;
            }

            @include desktop() {
                padding-left: 25px;
            }

            &:not(.mage-error) {
                border-color: $c-black;
            }

            &::placeholder {
                font-family: $f-lafayette148;
                font-size: 13px;
                text-transform: uppercase;
            }
        }

        .actions {
            position: absolute;
            right: 1px;
            top: 1px;
        }

        button {
            background: $c-grey-newsletter url('../images/icon-pointer-black.svg') 25px center no-repeat;
            border-left: 1px #000 solid;
            height: 60px;
            padding: 0;
            width: 56px;

            @include tablet-landscape() {
                background: $c-grey-newsletter url('../images/icon-pointer-black.svg') 30px center no-repeat;
                width: 75px;
            }

            span {
                display: none;
            }
        }
    }
}
