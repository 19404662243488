.bf-overlap {
    @include heading();

    bottom: 0;
    margin: 0 30px 75px;
    width: auto;

    @include desktop() {
        margin: 0 auto 75px;
        width: 1200px;
    }

    [data-content-type="column-group"]:nth-of-type(1) {
        margin-top: 0;
        max-width: none;
        width: 100% !important; // scss-lint:disable ImportantRule

        @include desktop() {
            margin-top: 85px;
            position: relative;
            width: auto !important; // scss-lint:disable ImportantRule
        }

        img {
            width: 100%;
        }
    }

    [data-content-type="column-group"]:nth-of-type(2) {
        left: auto;
        position: relative;
        right: auto;
        width: 100%;

        @include heading-right() {
            display: block;
        };

        @include tablet() {
            left: 0;
            right: 0;
        }

        @include desktop() {
            height: 100%;
            margin-bottom: auto;
            margin-left: -245px;
            position: relative;
        }

        img {
            width: 100%;
        }
    }

    &.right {
        @include heading-right();

        @include tablet() {
            margin-left: 30px;
            margin-right: 30px;
        }

        @include desktop() {
            margin: 0 auto 75px;
        }

        [data-content-type="column-group"]:nth-of-type(1) {
            @include desktop() {
                margin-top: 85px;
                position: relative;
            }
        }

        [data-content-type="column-group"]:nth-of-type(2) {
            @include heading();

            @include tablet() {
                right: 120px;
            }

            @include desktop() {
                margin: 0 -245px auto 0;
                position: relative;
                right: 0;
            }
        }
    }
}
