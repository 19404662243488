.storefinder-events-index,
.storefinder-stores-index,
.storefinder-store-index {
    .page-wrapper {
        max-width: 100vw;
        overflow-x: hidden;
    }

    .page-title {
        display: none;
    }
}


.storefinder-list-page {
    .list-page-menu {
        display: flex;
        flex-direction: row;
        padding: $content-spacing-l 18px 0;

        @include tablet() {
            padding: $content-spacing-l 9.114583% 0;
        }

        @include tablet-landscape() {
            padding: $content-spacing-l 6.835937% 0;
        }

        @include desktop() {
            padding: 105px 6.835937% 0;
        }

        @include desktop-extra-big() {
            padding: 105px 12.380952% 0;
        }
    }

    .menu-store-button,
    .menu-event-button {
        cursor: pointer;
        flex: 1 1 50%;
        line-height: 46px;
        padding: 0;
        position: relative;

        .label {
            color: $c-grey-main;
            font-family: $f-perpetua-titling;
            font-size: $f-size-l-larger;

            @include tablet() {
                font-size: 37px;
            }

            @include desktop() {
                font-size: 63px;
            }
        }

        &::after {
            background-color: $c-grey-cms-background;
            bottom: 0;
            content: '';
            display: block;
            height: 4px;
            position: absolute;
            transition: width .5s;
            width: 0;
        }

        &.active {

            .label {
                color: $c-grey-cms-background;
            }

            &::after {
                width: 100%;
            }
        }
    }

    .menu-store-button, .menu-event-button {
        text-align: center;

        &::after {
            right: 0;
        }

        @include tablet() {
            line-height: 0.8;
            padding: 45px 0;
        }
    }

    .menu-event-button {   
        &::after {
            left: 0;
        }
    }

    .store-list-wrapper,
    .event-list-wrapper {
        background-color: $c-white;
        display: none;
        min-height: 475px;
        padding: 38px 18px 0;

        @include tablet() {
            padding: 84px 9.114583% 0;
        }

        @include tablet-landscape() {
            padding: 67px 6.835937% 0;
        }

        @include desktop() {
            position: relative;
            padding: 33px 6.835937% 0;
        }

        @include desktop-extra-big() {
            padding: 33px 8.65% 0;
        }

        &.active {
            display: block;
        }

        &::before {
            background-color: $c-white;
            bottom: 0;
            content: '';
            display: none;
            left: -10000px;
            position: absolute;
            right: -10000px;
            top: 0;
            z-index: -100;

            @include desktop() {
                display: block;
            }
        }
    }

    .instore-container {
        &.stores-list-instore {
            .instore-big-title {
                @include desktop() {
                    font-size: $f-size-xxxl-desktops;
                    letter-spacing: 2px;
                }
            }

            .bluefoot-row {
                @include desktop() {
                    max-width: 1200px;
                }

                &:last-of-type {
                    @include desktop() {
                        margin: 0 auto;
                    }
                }
            }
        }

        .instore-hr {
            display: none;
        }
    }
}

.list-wrapper {
    margin-bottom: 50px;
    position: relative;

    @include desktop() {
        padding-top: $content-spacing-l;
    }

    .list-store-switch {
        display: flex;

        .store-switch-item {
            border-bottom: 5px solid $c-gery-silver-border;
            color: $c-grey-main;
            flex: 1;
            font-family: $f-perpetua-titling;
            font-size: $f-size-m;
            font-weight: $f-weight-base;
            line-height: 1.5;
            text-align: center;
            padding-bottom: 12px;

            @include tablet() {
                border-bottom: 6px solid $c-gery-silver-border;
                font-size: $f-size-l-small;
                line-height: 1.15;
                padding-bottom: 23px;
            }

            @include desktop() {
                text-align: left;
                flex: 1 1 auto;
                padding-left: $content-spacing-base;
            }

            &:hover {
                color: $c-brown;
                text-decoration: none;
            }

            &.active {
                border-color: $c-brown;
                color: $c-brown;
            }

            &.fake-active {
                border-color: $c-brown;
            }

            span {
                position: relative;

                &::before {
                    background: url('../images/icon-pin-black.svg') no-repeat center;
                    background-size: cover;
                    display: none;
                    height: 22px;
                    width: 16px;
                    vertical-align: middle;

                    @include tablet-landscape() {
                        content: '';
                        display: inline-block;
                        height: 33px;
                        width: 24px;
                        margin-right: 12px;
                    }
                }
            }

            &:nth-of-type(2) {
                span::before {
                    background-image: url('../images/icon-pin-gray.svg');
                }
            }

            &:nth-of-type(3) {
                span::before {
                    background-image: url('../images/icon-pin-empty.svg');
                }
            }
        }
    }
}
