// Page footer
.page-footer {
    padding: 75px 0 0;
    position: relative;
    z-index: 49;

    @include tablet() {
        padding: 92px 0 0;
    }

    @include tablet-landscape() {
        padding: 80px 0 0;
    }

    @include desktop-extra-big() {
        padding: 130px 0 0;
    }

    .content {
        @include desktop-extra-big() {
            margin: 0 auto;
            max-width: 100%;
        }
    }

    .footer-wrapper-top {
        display: flex;
        flex-direction: column;

        @include tablet() {
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0 25px 36px;
        }

        @include tablet-landscape() {
            padding: 0 25px 45px;
        }

        @include desktop-extra-big() {
            align-items: flex-start;
            flex-wrap: nowrap;
            justify-content: center;
            margin: 0 auto;
            max-width: 1350px;
            padding: 0;
        }

        .footer-wrapper {
            &-left {
                margin-bottom: 10px;
                margin-left: $content-spacing-l;
                margin-right: 22px;

                @include tablet() {
                    align-items: flex-start;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: auto;
                    margin-left: 0;
                    margin-right: 0;
                    width: 50%;
                    padding: 8px 10px 0 28px;
                }

                @include tablet-landscape() {
                    padding: 15px 50px 0 70px;
                }

                @include desktop() {
                    width: 40%;
                    padding: 15px 0 0 50px;
                }

                @include desktop-extra-big() {
                    flex-grow: .75;
                    margin-bottom: 0;
                    margin-left: 14%;
                    max-width: 445px;
                    padding: 0;
                }
            }

            &-middle {
                padding-left: $content-spacing-l;
                padding-right: $content-spacing-l;

                @include tablet() {
                    display: flex;
                    padding-left: $content-spacing-l;
                    padding-right: $content-spacing-l;
                    width: 50%;
                }

                @include tablet-landscape() {
                    padding-left: 45px;
                    padding-right: 0;
                }

                @include desktop() {
                    flex-grow: 1.1;
                    padding-left: 0;
                    padding-right: 0;
                    width: auto;
                }
            }

            &-right {
                display: flex;
                flex-wrap: wrap;
                padding: 0 $content-spacing-l $content-spacing-base $content-spacing-l;

                @include tablet() {
                    display: none;
                }
            }
        }
    }

    ul {
        list-style: none outside none;
    }

    .footer-wrapper-left {
        .social-link-wrapper {
            order: 3;
        }

        .navigation {
            order: 2;
            width: 100%;

            @include tablet() {
                margin-bottom: 20px;
            }

            @include desktop() {
                margin-bottom: 40px;
            }

            ul {
                display: none;

                @include tablet() {
                    display: flex;
                    justify-content: space-between;
                }
            }

            .icon {
                &::before {
                    margin: -4px -1px 0 -6px;

                    @include desktop() {
                        background-size: 23px;
                    }
                }

                span {
                    text-transform: uppercase;

                    @include tablet() {
                        font-size: 9px;
                    }

                    @include desktop() {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .footer-wrapper-right {

        @include  tablet() {
            margin-bottom: 47px;
        }

        @include desktop() {
            border-bottom: 0 none;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .social-link-block {
            display: block;
            text-align: right;
            width: 100%;

            ul {
                display: flex;
                justify-content: space-between;
            }

            a {
                display: inline-block;
            }

            span {
                font-size: 8px;
                text-transform: uppercase;
            }

            .icon {
                &::before {
                    margin: -4px -1px 0 -6px;

                    @include desktop() {
                        background-size: 25px;
                    }
                }
            }
        }

        ul {
            li {
                line-height: normal;
                padding-bottom: 25px;
            }
        }
    }

    .footer-wrapper-middle {
        padding-bottom: 38px;

        @include tablet() {
            border: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        @include desktop() {
            border-bottom: 0 none;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .navigation {
            width: 100%;

            @include tablet-landscape() {
                flex-grow: 17;
                padding-top: 9px;
            }

            > ul {
                display: flex;
                flex-wrap: wrap;

                @include desktop() {
                    flex-wrap: nowrap;
                }
            }

            a {
                font-weight: $f-weight-base;
            }
        }

        .level0 {
            flex-basis: 50%;
            flex-grow: 2;
            order: 3;

            @include desktop() {
                margin-top: 17px;
                order: 2;
            }

            &.first {
                flex-grow: 2;
                order: 1;

                @include tablet() {
                    padding-left: 0;
                }

                .submenu {
                    @include tablet() {
                        margin-top: 10px;
                    }

                    @include tablet-landscape() {
                        margin-top: 17px;
                    }
                }
            }

            &.last {
                flex-grow: 1;
                order: 2;
                padding-left: 6px;

                @include tablet() {
                    padding-left: 0;
                }

                @include tablet-landscape() {
                    padding-left: $content-spacing-xl;
                }

                @include desktop-extra-big() {
                    padding-left: 25px;
                }

                .submenu {
                    @include tablet() {
                        margin-top: 10px;
                    }

                    @include tablet-landscape() {
                        margin-top: 17px;
                    }
                }
            }

            > span {
                color: $c-brown;
                display: block;
                font-size: $f-size-xs;
                font-weight: $f-weight-bold;
                letter-spacing: $letter-spacing-xs;
                line-height: 31px;
                text-transform: uppercase;

                @include tablet() {
                    line-height: 30px;
                }

                @include tablet-landscape() {
                    font-size: $f-size-m;
                }

                @include desktop-extra-big() {
                    font-size: $f-size-m-large;
                    line-height: 0;
                    padding-bottom: 20px;
                }
            }

            .empty {
                display: none;
            }
        }

        .level1 {
            font-size: $f-size-base;
            line-height: 32px;

            @include tablet-landscape() {
                line-height: 35px;
            }

            @include desktop-extra-big() {
                line-height: 43px;
            }

            a {
                font-size: $f-size-xs;
                letter-spacing: .9px;

                @include desktop-extra-big() {
                    font-size: $f-size-m;
                }
            }
        }
    }

    .livechat-trigger {
        @extend %hyper-link;
        cursor: pointer;
        line-height: 1.8;
        font-size: 15px;
    }

    .footer-wrapper-bottom {
        order: 4;
        padding: 32px $content-spacing-l $content-spacing-l;
        background-color: #F2F2F2;

        @include tablet() {
            padding-left: $content-spacing-xxl * 2;
            padding-right: $content-spacing-xxl * 2;
            padding-top: 22px;
        }

        @include desktop-extra-big() {
            padding: 40px 0;
        }

        .footer-link-wrapper {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            @include tablet() {
                flex-direction: row;
                justify-content: center;
            }

            @include desktop-extra-big() {
                margin: 0 auto;
                max-width: 1400px;
            }
        }

        .navigation {
            display: flex;
            text-transform: uppercase;

            @include tablet() {
                margin-bottom: 0;
            }

            @include desktop-extra-big() {
                margin-bottom: 0;
                order: 2;
            }

            .category-item {
                @include tablet() {
                    padding-left: 16px;
                }
            }

            ul {
                display: flex;
                flex-grow: 1;
                flex-wrap: wrap;
                justify-content: space-between;

                li:nth-child(-n+2) {
                    margin-right: 20px;
                }

                @include tablet() {
                    li:nth-child(-n+3) {
                        margin-right: 20px;
                    }
                }
            }
        }

        .level0 {
            line-height: 2.5;

            @include tablet() {
                line-height: 1;
            }

            a {
                @include tablet-landscape() {
                    font-size: $f-size-s;
                    line-height: 1.6;
                }
            }

            &.first {
                text-align: left;

                @include tablet() {
                    text-align: center;
                }
            }

            &.last {
                @include tablet() {
                    text-align: center;
                }
            }
        }

        .copyright {
            color: $c-grey-kelp;
            font-size: $f-size-m;
            font-weight: $f-weight-light;
            letter-spacing: $letter-spacing-s;
            margin-bottom: $content-spacing-xl;
            text-transform: uppercase;

            @include tablet() {
                margin: 2px 0 auto;
            }

            @include desktop-extra-big() {
                order: 1;
                text-align: right;
            }

            span {
                @include tablet() {
                    border-right: 1px solid;
                    font-size: $f-size-base;
                    padding-right: 15px;
                }

                @include tablet-landscape() {
                    font-size: $f-size-s;
                }
            }
        }
    }
}

// separate style only on cart and checkout
.checkout-cart-index,
.checkout-index-index,
.checkout-onepage-success {
    .page-footer {
        padding-top: 0;
    }

    // hide all footer links except the first one
    .footer-link-wrapper {

        .category-item {
            display: none;
        }

        .nav-1 {
            display: block;
        }
    }
}

.checkout-onepage-success {
    position: relative;

    .page-main {
        margin-bottom: 52px;
    }

    .page-footer {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
    }
}
