$sb-max-width: 1680px;

$sb-lane-mobile: .18;
$sb-lane-tablet: .14;
$sb-lane-desktop: .1;

.tile-storyblock {
    display: block !important; // scss-lint:disable ImportantRule

    h1,
    h2 {
        font-family: $f-lafayette148;
        font-weight: 300;
        line-height: .85;
        text-transform: uppercase;

        &::first-line {
            font-family: $f-perpetua-titling;
        }
    }

    h1 {
        font-size: 63px;
    }

    h2 {
        font-size: 37px;

        @include tablet {
            font-size: 55px;
        }
    }

    p {
        font-family: $f-lafayette148;
        font-size: 15px;
        font-weight: 300;
        line-height: 1.8;
    }

    a {
        background: transparent;
        font-family: $f-lafayette148;
        font-size: 15px;
        font-weight: bold;
        height: auto;
        line-height: 1;
        margin: 10px 0;
        padding: 0;
        text-transform: uppercase;
        width: auto;

        &.button {
            border: 1px solid;
            font-size: 12px;
            font-weight: normal;
            margin: 25px 0;
            padding: 4px 25px;

            @include tablet {
                font-size: 15px;
                padding: 10px 40px;
            }

            @include desktop {
                font-size: 17px;
            }

            &:hover {
                background-color: $c-grey-transparent;
            }
        }
    }

    .pagebuilder-column-group {
        flex-direction: column;

        > .pagebuilder-column-line {
            flex-direction: column;
        }

        &:nth-of-type(2) {
            flex-direction: column-reverse;

            > .pagebuilder-column-line {
                flex-direction: column-reverse;
            }
        }
    }

    .pagebuilder-column {
        &.sliderblock-above-imageblock {
            position: relative;

            [data-content-type="product_slider_quickshopblock"] {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;

                .slick-slider {
                    margin-bottom: 0;
                }
            }
        }
    }

    .pagebuilder-column {
        width: 100% !important; // scss-lint:disable ImportantRule

        > div {
            margin-bottom: 100vw * $sb-lane-mobile;

            &[data-content-type="product_slider_quickshopblock"] {
                margin-bottom: 100vw * $sb-lane-mobile !important;
            }

            @include tablet {
                margin-bottom: 100vw * $sb-lane-tablet;

                &[data-content-type="product_slider_quickshopblock"] {
                    margin-bottom: 100vw * $sb-lane-tablet !important;
                }
            }
        }
    }

    &:not(.reversed) [data-align-mobile='left'],
    &.reversed [data-align-mobile='right'] {
        margin-right: 100vw * $sb-lane-mobile;

        + [data-content-type="product_slider_quickshopblock"] {
            right: 100vw * $sb-lane-mobile;
        }

        @include tablet {
            margin-right: 100vw * $sb-lane-tablet;

            + [data-content-type="product_slider_quickshopblock"] {
                right: 100vw * $sb-lane-tablet;
            }
        }
    }

    &:not(.reversed) [data-align-mobile='right'],
    &.reversed [data-align-mobile='left'] {
        margin-left: 100vw * $sb-lane-mobile;

        + [data-content-type="product_slider_quickshopblock"] {
            left: 100vw * $sb-lane-mobile;
        }

        @include tablet {
            margin-left: 100vw * $sb-lane-tablet;

            + [data-content-type="product_slider_quickshopblock"] {
                left: 100vw * $sb-lane-tablet;
            }
        }
    }

    &.reversed-mobile {
        .pagebuilder-column-group {
            flex-direction: column-reverse !important; // scss-lint:disable ImportantRule

            > .pagebuilder-column-line {
                flex-direction: column-reverse !important; // scss-lint:disable ImportantRule
            }
        }
    }
}

@include desktop {
    .tile-storyblock {
        .pagebuilder-column-group {
            flex-direction: row !important; // scss-lint:disable ImportantRule

            > .pagebuilder-column-line {
                flex-direction: row !important; // scss-lint:disable ImportantRule
            }
        }

        .pagebuilder-column {
            margin: 0 -5% 0 0;
            width: 55% !important; // scss-lint:disable ImportantRule

            > div {
                margin: 0;

                &[data-content-type="product_slider_quickshopblock"] {
                    margin: 0 !important;
                }
            }

            &:nth-child(2n) {
                margin: 0 0 0 -5%;
            }
        }

        &.four-tile {
            .pagebuilder-column-group {
                &:nth-of-type(1) .pagebuilder-column {
                    &:nth-of-type(1) {
                        margin-bottom: -5vw;

                        @include media($sb-max-width) {
                            margin-bottom: -$sb-max-width * .05;
                        }
                    }

                    &:nth-of-type(2) {
                        margin-bottom: 5vw;

                        @include media($sb-max-width) {
                            margin-bottom: $sb-max-width * .05;
                        }
                    }
                }

                &:nth-of-type(2) .pagebuilder-column {
                    &:nth-of-type(1) {
                        margin-top: 5vw;

                        @include media($sb-max-width) {
                            margin-top: $sb-max-width * .05;
                        }
                    }

                    &:nth-of-type(2) {
                        margin-top: -5vw;

                        @include media($sb-max-width) {
                            margin-top: -$sb-max-width * .05;
                        }
                    }
                }
            }
        }

        &.reversed {
            .pagebuilder-column-group {
                flex-direction: row-reverse !important; // scss-lint:disable ImportantRule

                > .pagebuilder-column-line {
                    flex-direction: row-reverse !important; // scss-lint:disable ImportantRule
                }

                .pagebuilder-column {
                    margin: 0 0 0 -5%;

                    &:nth-child(2n) {
                        margin: 0 -5% 0 0;
                    }
                }
            }
        }

        &.reversed-mobile {
            .pagebuilder-column-group {
                flex-direction: row !important; // scss-lint:disable ImportantRule

                > .pagebuilder-column-line {
                    flex-direction: row !important; // scss-lint:disable ImportantRule
                }
            }

            &.reversed {
                .pagebuilder-column-group {
                    flex-direction: row-reverse !important; // scss-lint:disable ImportantRule

                    > .pagebuilder-column-line {
                        flex-direction: row-reverse !important; // scss-lint:disable ImportantRule
                    }
                }
            }
        }

        &.shifted .pagebuilder-column-group .pagebuilder-column {
            &:first-of-type {
                margin-bottom: 5%;
            }

            &:last-of-type {
                margin-top: 5%;
            }
        }
    }
}
