.homepage-style {
    .cms-button {
        font-size: 12px;
        margin-top: 20px;
        padding: 4px 15px 5px;

        @include desktop() {
            font-size: 17px;
            margin-top: 30px;
            padding: 7px 30px 8px;
        }

        &.cms-button-outline-light {
            background: none;
        }
    }

    &.tile-storyblock {
        &.reversed {
            .pagebuilder-column {
                &:nth-of-type(1) {
                    @include media(max-width $bp-tablet - 1px) {
                        order: 2;
                    }
                }

                &:nth-of-type(2) {
                    @include media(max-width $bp-tablet - 1px) {
                        order: 1;
                    }
                }
            }
        }

        a.button {
            font-size: 12px;
            padding: 4px 15px 5px;

            @include desktop() {
                font-size: 15px;
                padding: 7px 30px 8px;
            }

        }
    }

    [data-reverse-block='true'] {
        @include tablet {
            [data-element='caption_wrapper'] {
                &.center {
                    text-align: right;

                    .button-wrapper {
                        justify-content: flex-end;
                    }
                }
            }
        }
    }

    .l-shape-block {
        [data-element='caption_wrapper'] {
            &[data-caption-position-mobile='caption-under'] {
                @include media(max-width $bp-tablet - 1px) {
                    padding-bottom: 70px;
                }
            }

            .caption-text {
                font-size: 30px;

                @include desktop() {
                    font-size: 58px;
                }
            }
        }
    }

    .pagebuilder-column .image-storyblock {
        &[data-caption-position='below'] {
            .caption {
                @include desktop() {
                    margin-top: 15px;
                }
            }
        }
    }

    .image-storyblock {
        .caption {
            [data-element='caption_text'] {
                display: none;

                @include tablet() {
                    display: block;
                }
            }
        }
    }
}

// L-Shape Storyblock
@include media(min-width $bp-tablet max-width $bp-desktop - 1px) {
    .homepage-style {
        .l-shape-block {
            &[data-reverse-block='true']:not([data-reverse-l-shape='true']) {
                .l-shape-wrapper {
                    left: calc(30% - (100vw * .12));
                    right: auto;
                }
            }
            &[data-reverse-block='false']:not([data-reverse-l-shape='true']) {
                .l-shape-wrapper {
                    left: auto;
                    right: calc(30% - (100vw * .12));
                }
            }
        }
    }
}

// Image Storyblock
@include media(min-width $bp-tablet max-width $bp-desktop - 1px) {
    .homepage-style {
        .pagebuilder-column .image-storyblock {
            .caption {
                left: 10%;
                margin: 0;
                position: absolute;
                right: 10%;
                text-align: left;
                transform: none;

                .caption-title {
                    font-size: 30px;
                }

                .caption-text {
                    font-size: 11px;
                }
            }

            .image-link {
                bottom: -35px;
                left: 0;
                position: absolute;
            }


            &[data-small-block='true'] {
                .image-wrapper {
                    float: right;
                    width: 75%;
                }

                .image-link {
                    left: auto;
                    right: 0;
                    width: 75%;
                }
            }

            &[data-caption-position='top'] {
                .caption {
                    top: 5%;
                }
            }

            &[data-caption-position='middle'] {
                .caption {
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            &[data-caption-position='bottom'] {
                .caption {
                    bottom: 10%;
                    top: auto;
                }
            }

            &[data-caption-position='below'] {
                .caption {
                    left: 20%;
                    margin-top: 10px;
                    position: relative;
                    transform: none;
                    width: 80%;

                    .caption-text {
                        margin: 15px 0;
                    }
                }
            }

            &[data-spacing~='top'] {
                margin-top: 10vw;

                &[data-small-block='true'] {
                    margin-top: 12.2vw;
                }
            }

            &[data-spacing~='bottom'] {
                margin-bottom: 10vw;
            }
        }

        &.tile-storyblock:not(.reversed) .pagebuilder-column .image-storyblock {
            &[data-spacing~='right'] {
                margin-right: 10vw;
            }

            &:not([data-spacing~='right']) {
                margin-right: 0;
            }

            &[data-spacing~='left'] {
                margin-left: 10vw;
            }
        }

        &.reversed .pagebuilder-column .image-storyblock {
            &[data-spacing~='right'] {
                margin-left: 10vw;
            }

            &:not([data-spacing~='right']) {
                margin-left: 0;
            }

            &[data-spacing~='left'] {
                margin-right: 10vw;
            }
        }

        &.tile-storyblock:not(.reversed) .pagebuilder-column .image-storyblock {
            &[data-spacing~='left'] {
                margin-left: 10vw;
            }

            &:not([data-spacing~='left']) {
                margin-left: 0;
            }

            &[data-spacing~='right'] {
                margin-right: 10vw;
            }
        }

        &.reversed .pagebuilder-column .image-storyblock {
            &[data-spacing~='left'] {
                margin-right: 10vw;
            }

            &:not([data-spacing~='left']) {
                margin-right: 0;
            }

            &[data-spacing~='right'] {
                margin-left: 10vw;
            }
        }

        &.tile-storyblock.four-tile [data-small-block='true'] {
            .image-wrapper {
                width: 55%;
            }

            .image-link {
                left: auto;
                right: 0;
                width: 55%;
            }
        }

        &.tile-storyblock:not(.reversed) .pagebuilder-column:nth-child(2) .image-storyblock,
        &.reversed .pagebuilder-column:nth-child(1) .image-storyblock {
            .caption {
                left: 10%;
                right: 10%;
                text-align: right;

                .caption-text {
                    margin: 15px 0 15px auto;
                }
            }

            &[data-caption-position='none'] {
                .image-link {
                    left: auto;
                    right: 0;
                }
            }

            &[data-caption-position='below'] {
                .caption {
                    right: auto;
                }
            }

            &[data-small-block='true'] {
                .image-wrapper {
                    float: none;
                }

                .image-link {
                    left: 0;
                    right: auto;
                }
            }
        }
    }
}

// Tile Storyblock
@include media(min-width $bp-tablet max-width $bp-desktop - 1px) {
    .homepage-style {
        &.tile-storyblock {
            .pagebuilder-column-group {
                flex-direction: row !important; // scss-lint:disable ImportantRule
            }

            .pagebuilder-column {
                margin: 0 -5% 0 0;
                width: 55% !important; // scss-lint:disable ImportantRule

                > div {
                    margin: 0;
                }

                &:nth-child(2n) {
                    margin: 0 0 0 -5%;
                }
            }

            &.four-tile {
                .pagebuilder-column-group {
                    &:nth-of-type(1) .pagebuilder-column {
                        &:nth-of-type(1) {
                            margin-bottom: -5vw;

                            @include media($sb-max-width) {
                                margin-bottom: -$sb-max-width * .05;
                            }
                        }

                        &:nth-of-type(2) {
                            margin-bottom: 5vw;

                            @include media($sb-max-width) {
                                margin-bottom: $sb-max-width * .05;
                            }
                        }
                    }

                    &:nth-of-type(2) .pagebuilder-column {
                        &:nth-of-type(1) {
                            margin-top: 5vw;

                            @include media($sb-max-width) {
                                margin-top: $sb-max-width * .05;
                            }
                        }

                        &:nth-of-type(2) {
                            margin-top: -5vw;

                            @include media($sb-max-width) {
                                margin-top: -$sb-max-width * .05;
                            }
                        }
                    }
                }
            }

            &.reversed {
                .pagebuilder-column-group {
                    flex-direction: row-reverse !important; // scss-lint:disable ImportantRule

                    .pagebuilder-column {
                        margin: 0 0 0 -5%;

                        &:nth-child(2n) {
                            margin: 0 -5% 0 0;
                        }
                    }
                }
            }

            &.shifted .pagebuilder-column-group .pagebuilder-column {
                &:first-of-type {
                    margin-bottom: 5%;
                }

                &:last-of-type {
                    margin-top: 5%;
                }
            }
        }
    }
}

@include desktop() {
    .homepage-style {
        .pagebuilder-column {
            .image-storyblock {
                .caption {
                    left: 14%;
                }

                &[data-caption-position='top'] {
                    .caption {
                        top: 16.5%;
                    }
                }
            }
        }

        [data-reverse-block='true'] {
            [data-element='caption_wrapper'] {
                &.center {
                    left: 45.5%;
                }
            }
        }
    }
}
