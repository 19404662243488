* {
    box-sizing: border-box;

    &:focus {
        outline: thin dotted $c-grey-main-darker;
    }
}

body {
    background: $c-white;
    overflow-x: hidden;
}

// Temporary fix to remove additional sidebar from homepage
.cms-index-index {
    .sidebar-additional {
        display: none;
    }

    .column {
        &.main {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
        }
    }
}

// Layout containers
.page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    z-index: $z-main-content;
}

.page-main {
    flex: 1 1 auto;
    margin: 0 auto;
    max-width: $content-max-width;
    min-height: 500px;
    width: 100%;
    z-index: 50;
}

.page-layout-2columns-left,
.page-layout-2columns-right {
    .columns {
        .column {
            width: 100%;

            @include desktop() {
                width: $column-width;
            }
        }

        .sidebar {
            width: 100%;

            @include desktop() {
                padding: 30px 40px;
                width: $sidebar-width;
            }
        }
    }
}

.page-layout-2columns-left {
    .columns {
        @include desktop() {
            .column {
                float: right;
            }

            .sidebar {
                float: left;
            }
        }
    }
}

.page-layout-2columns-right {
    .columns {
        @include desktop() {
            .column {
                float: left;
            }

            .sidebar {
                float: right;
            }
        }
    }
}

.modal-popup .columns .column.main {
    width: 100%;
}

.columns {
    @include clearfix;
    margin: 0 auto;
}

// Display classes
.no-display {
    display: none;
}

.no-display-forced {
    display: none !important; // scss-lint:disable ImportantRule
}

.hide-on-mobile {
    display: none !important; // scss-lint:disable ImportantRule

    @include desktop() {
        display: block !important; // scss-lint:disable ImportantRule
    }
}

.hide-above-tablet {
    display: block !important; // scss-lint:disable ImportantRule

    @include tablet() {
        display: none !important; // scss-lint:disable ImportantRule
    }
}

.show-on-desktop {
    display: none !important; // scss-lint:disable ImportantRule

    @include desktop() {
        display: block !important; // scss-lint:disable ImportantRule
    }
}

.hide-on-desktop {
    display: block !important; // scss-lint:disable ImportantRule

    @include desktop() {
        display: none !important; // scss-lint:disable ImportantRule
    }
}

.accessibility-hidden {
    // scss-lint:disable ImportantRule
    clip: rect(1px, 1px, 1px, 1px) !important;
    height: 1px !important;
    overflow: hidden !important;
    position: absolute !important;
    width: 1px !important;
    // scss-lint:enable ImportantRule
}
