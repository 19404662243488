// Homepage
.cms-index-index {
    .page-title-wrapper {
        display: none;
    }

    .page-main {
        padding: 0;

        @include desktop() {
            margin-top: 0;
        }
    }

    .column {
        .main {
            @include desktop() {
                margin: 0 auto;
                max-width: 100%;
            }
        }
    }
}
