.unordinary {
    .uw-email-capture {
        padding: 0;

        @include tablet {
            margin-bottom: 52px;
        }

        @include desktop {
            margin: 0 auto 145px;
            max-width: 1440px;
            padding: 0 120px;
        }

        @include desktop-extra-big {
            margin: 0 auto;
        }

        // scss-lint:disable ImportantRule
        .pagebuilder-column-group {
            display: block !important;

            @include desktop {
                display: flex !important;
                justify-content: space-between;
                margin: 0 auto;
                max-width: 1200px;
            }

            .pagebuilder-column {
                width: 100% !important;

                @include desktop {
                    width: 48% !important;
                }

                &:last-of-type {
                    margin-top: 73px;
                    padding: 0 30px;

                    @include tablet {
                        padding: 0 105px;
                    }

                    @include desktop {
                        margin-top: 0;
                        padding: 0;
                        width: 45% !important;
                    }
                }
            }
        }
        // scss-lint:enable ImportantRule

        .actions-toolbar {
            margin-top: 25px;

            @include tablet {
                margin-top: 35px;
            }

            .action {
                &.submit {
                    background-color: $c-unordinary-title;
                    color: $c-black;

                    @include tablet {
                        max-width: 111px;
                        width: 111px;
                    }

                    @include desktop {
                        max-width: 115px;
                        width: 115px;
                    }
                }
            }
        }

        .subscribe-mail {
            .checkbox {
                &.mage-error {
                    border-color: $c-red;

                    // scss-lint:disable NestingDepth
                    ~.label span {
                        color: $c-red;
                    }
                    // scss-lint:enable NestingDepth
                }
            }

            div {
                &.mage-error {
                    color: $c-red;
                    margin-top: 10px;
                }
            }

            span {
                font-size: $f-size-base;
            }
        }

        .customer-account-create {
            margin-top: 17px;

            @include tablet {
                margin-top: 29px;
            }

            .control {
                @include tablet {
                    margin-bottom: 11px;
                }
            }

            .fieldset.create.account {
                margin-bottom: 10px;

                @include tablet {
                    margin-bottom: 20px;
                }
            }

            input[type="text"],
            input[type="email"] {
                @include tablet {
                    width: 100%;
                }
            }
        }

        [data-content-type="text"] {
            &:first-of-type {
                h1 {
                    font-size: 42px;
                    font-weight: $f-weight-light;
                    line-height: 34px;
                    margin-bottom: 38px;

                    @media screen and (max-width: 399px) {
                        font-size: 10vw;
                    }

                    @include tablet() {
                        font-size: 37px;
                        letter-spacing: -1px;
                        line-height: 44px;
                        margin-bottom: 40px;
                    }

                    @include desktop() {
                        font-size: 55px;
                        margin-bottom: 66px;
                    }
                }
            }

            &:nth-of-type(2) {
                > div {
                    font-size: 15px;
                    font-weight: $f-weight-light;
                    line-height: $line-height-sm;
                }
            }

            &:last-of-type {
                p {
                    // scss-lint:disable NestingDepth
                    &:empty {
                        display: none;
                    }
                    // scss-lint:enable NestingDepth
                }
            }
        }

        .image-storyblock {
            @include desktop {
                margin-bottom: 0 !important; // scss-lint:disable ImportantRule
                margin-top: 0 !important; // scss-lint:disable ImportantRule
            }
        }

        // scss-lint:disable NestingDepth
        &.full {
            margin-top: 43px;

            @include tablet {
                margin-top: 0;
            }

            @include desktop {
                margin-top: 14px;
            }

            [data-content-type="text"] {
                h1 {
                    margin-bottom: 7px;

                    @include tablet {
                        margin-bottom: 12px;
                    }

                    @include desktop {
                        margin-bottom: 65px;
                    }
                }

                &.text {
                    &.mobile {
                        @include tablet {
                            display: none;
                        }

                        h1 {
                            font-family: $f-lafayette148;

                            &:first-of-type {
                                font-family: $f-perpetua-titling;
                                margin-bottom: 0;
                            }
                        }
                    }

                    &.desktop {
                        display: none;

                        @include tablet {
                            display: block;
                            margin-bottom: 6px;
                            margin-top: 42px;
                        }

                        @include desktop {
                            margin-bottom: 0;
                            margin-top: 11px;
                        }

                        h1 {
                            font-family: $f-lafayette148;
                            font-size: 37px;
                            font-weight: $f-weight-light;
                            line-height: 30px;

                            @include desktop {
                                font-size: 3.7vw;
                                line-height: 44px;
                            }

                            @media screen and (min-width: 1500px) {
                                font-size: $f-size-xxxml-mobile;
                            }

                            &:first-of-type {
                                font-family: $f-perpetua-titling;
                                margin-bottom: 0;
                            }

                            &:last-of-type {
                                @include desktop {
                                    margin-bottom: 17px;
                                }
                            }
                        }
                    }
                }
            }

            .email-text {
                font-size: $f-size-m;
                font-weight: $f-weight-light;
                line-height: 27px;
            }

            .social-campaign-form {
                margin-top: 17px;
                padding: 0;

                @include tablet {
                    margin-top: 29px;
                }

                @include desktop {
                    margin-top: 22px;
                }

                .field {
                    @include tablet {
                        padding-right: 0;
                    }

                    &.apartment {
                        @include tablet {
                            padding-right: 6px;
                            width: calc(50% - 1.5px);
                        }

                        @include desktop {
                            padding-right: 6.5px;
                        }
                    }

                    &.city {
                        @include tablet {
                            padding-left: 6px;
                            padding-right: 0;
                            width: calc(50% - 1.5px);
                        }

                        @include desktop {
                            padding-left: 6.5px;
                        }
                    }

                    &.text {
                        margin-top: 34px;
                        padding-bottom: 1px;

                        @include tablet {
                            margin-top: 15px;
                        }

                        @include tablet {
                            margin-top: 7px;
                        }

                        span {
                            color: $c-grey-fuscous;
                            font-size: $f-size-xs;
                            font-weight: $f-weight-light;
                            line-height: 18px;

                            a {
                                font-weight: $f-weight-light;
                                text-decoration: underline;
                            }
                        }
                    }

                    .control {
                        margin-bottom: 12px;

                        @include tablet {
                            margin-bottom: 11px;
                        }
                    }
                }

                .actions-toolbar {
                    @include tablet {
                        margin-top: 22px;
                    }

                    @include tablet {
                        margin-top: 18px;
                    }
                }

                .short-field-wrapper {
                    .field {
                        &:first-of-type {
                            @include tablet {
                                padding-right: 6px;
                                width: calc(50% - 1.5px);
                            }

                            @include desktop {
                                padding-right: 6.5px;
                            }
                        }

                        &:last-of-type {
                            @include tablet {
                                padding-left: 6px;
                                padding-right: 0;
                                width: calc(50% - 1.5px);
                            }

                            @include desktop {
                                padding-left: 6.5px;
                            }
                        }
                    }
                }
            }

            .pagebuilder-column {
                &:last-of-type {
                    margin-top: 61px;

                    @include desktop {
                        margin-top: 0;
                    }

                    > div {
                        > p {
                            padding-bottom: 1px;

                            @include tablet {
                                padding-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        // scss-lint:enable NestingDepth
    }
}
