.editorial-block {
    position: relative;

    [data-element="wrapper"] {
        position: relative;
    }

    .image-wrapper {
        position: relative;

        img {
            vertical-align: middle;
            width: 100%;
        }
    }

    .caption {
        position: absolute;
    }

    [data-element="title"] {
        font-family: $f-lafayette148;
        font-size: 37px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 30px;

        @include tablet() {
            margin-bottom: 17px;
        }

        @include desktop() {
            font-size: 63px;
            line-height: 50px;
            margin-bottom: 38px;
        }

        &:first-line {
            font-family: $f-perpetua-titling;
            letter-spacing: 1px;
        }
    }

    [data-element="subtitle"] {
        font-family: $f-perpetua-titling;
        font-size: 27px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 50px;
        margin-bottom: 6px;
    }

    [data-element="content"] {
        margin-bottom: 10px;

        p {
            font-family: $f-lafayette148;
            font-size: 15px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 27px;
            margin-bottom: 0;
            padding: 0;

            @include desktop() {
                font-size: 17px;
            }

            a {
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    [data-element="link"] {
        font-family: $f-lafayette148;
        font-size: 15px;
        font-weight: 700;
    }
}