$default-feature: min-width;

@mixin mobile() {
    @include media($bp-mobile) {
        @content;
    }
}

@mixin tablet() {
    @include media($bp-tablet) {
        @content;
    }
}

@mixin tablet-big() {
    @include media($bp-tablet-big) {
        @content;
    }
}

@mixin tablet-landscape() {
    @include media($bp-tablet-landscape) {
        @content;
    }
}

@mixin desktop-sm() {
    @include media($bp-desktop-sm) {
        @content;
    }
}

@mixin desktop() {
    @include media($bp-desktop) {
        @content;
    }
}

@mixin desktop-extra-big() {
    @include media($bp-desktop-extra-big) {
        @content;
    }
}

@mixin mobile-only() {
    @include media(max-width $bp-tablet) {
        @content;
    }
}

@mixin tablet-only() {
    @include media(min-width $bp-tablet max-width $bp-desktop - 1px) {
        @content;
    }
}

@mixin mobile-tablet-only() {
    @include media(max-width $bp-desktop - 1px) {
        @content;
    }
}
