.promo-banner {
    margin: 0 auto 75px;
    max-width: $bp-desktop;
    position: relative;
    text-align: left;

    @include tablet() {
        text-align: center;
    }

    @include desktop() {
        text-align: center;
    }

    &--background {
        display: block;
        margin: 0 auto;
        overflow: hidden;
        width: 100%;

        img {
            height: 100%;
            min-width: 100%;
        }
    }

    &--content {
        font-family: $f-freight;
        padding: 32px 35px 24px;

        @include tablet() {
            padding: 30px 0 22px;
        }

        @include desktop() {
            padding: 29px 0 24px;
        }

        .title {
            font-size: 32px;
            font-weight: 100;
            letter-spacing: .2px;
            line-height: 1.1;
            max-width: 250px;
            padding-bottom: 14px;

            @include tablet() {
                font-size: 36px;
                max-width: none;
                padding-bottom: 12px;
            }

            @include desktop() {
                line-height: 1;
                padding-bottom: 20px;
            }
        }

        .subtitle {
            font-size: 18px;
            letter-spacing: -.3px;
            line-height: 1.4;
            padding-bottom: 10px;

            @include tablet() {
                font-size: 23px;
                letter-spacing: 1px;
                padding-bottom: 13px;
            }

            @include desktop() {
                font-size: 23px;
                padding-bottom: 15px;
            }
        }

        .link {
            a {
                font-size: 16px;
            }
        }

        p {
            font-size: inherit;
            line-height: inherit;
            padding: 0;
        }
    }

    &.promo-banner--image {
        margin-bottom: 75px;
        position: relative;

        .promo-banner--background {
            height: 235px;

            @include desktop() {
                height: 181px;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }

        .promo-banner--content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-storyblock {
        margin: 0 auto;
        max-height: 555px;
        max-width: $storyblock-max-width;
        position: relative;
        width: 100%;

        @include media(max-width $bp-tablet - 1px) {
            height: auto !important; // scss-lint:disable ImportantRule
            max-height: none !important; // scss-lint:disable ImportantRule
        }

        .information-wrapper {
            left: 15%;
            position: absolute;
            top: 50%;
            transform: translateY(-48%);

            @include tablet() {
                left: 0.8%;
                text-align: center;
                width: 100%;
            }
        }

        .title,
        .subtitle,
        .link {
            p {
                padding: 0;
            }
        }

        .title,
        .subtitle {
            padding: 0;

            p {
                font-family: $f-lafayette148;
                line-height: 1.3;
            }
        }

        .title {
            p {
                font-size: 27px;
                letter-spacing: 0;

                @include tablet() {
                    padding-bottom: 20px;
                }

                @include desktop() {
                    font-size: 80px;
                    letter-spacing: 2px;
                    padding-bottom: 36px;
                }
            }
        }

        .subtitle {
            max-width: 220px;
            padding-right: 20px;
            padding-top: 10px;

            @include tablet() {
                max-width: 100%;
                padding-bottom: 30px;
                padding-right: 0;
            }

            @include desktop() {
                padding-bottom: 48px;
            }

            p {
                font-size: 20px;

                @include desktop(){
                    font-size: 34px;
                }
            }
        }

        .link {
            padding-top: 7px;
            p a {
                text-decoration: underline;
                text-transform: uppercase;

                @include desktop(){
                    font-size: 21px;
                }
            }
        }

        .image-primary {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: none;

            @include tablet() {
                display: block;
                max-height: 555px;
            }
        }

        .image_primary_mobile {
            background-repeat: no-repeat;
            background-size: 100% 100%;

            @include tablet() {
                display: none;
            }
        }
    }
}
