.unordinary {
    .conversation-block {
        background: $c-unordinary-conversation-background;

        @include desktop() {
            background: $c-white;
        }

        .hero-block {
            margin-top: 0;
        }
    }

    .conversation {
        margin-bottom: 0;
        min-height: 400px;

        @include desktop() {
            min-height: auto;
        }

        .description {
            left: 0;
            padding: 0 0 0 32px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            @include tablet-big() {
                padding: 12px calc(50vw - 350px + 32px) 0;
            }

            @include desktop() {
                padding: 0 0 10px 155px;
            }
        }

        h3 {
            color: $c-white;
            margin-bottom: 0;

            @include desktop() {
                color: $c-unordinary-title;
                padding-left: 0;
            }
        }

        h5 {
            a {
                color: $c-white;
                font-size: 30px;
                margin-bottom: 0;
                margin-top: 10px;
                padding-left: 3px;

                @include desktop() {
                    color: $c-unordinary-title;
                    font-size: 35px;
                    letter-spacing: -.3px;
                    margin-top: 4px;
                    padding: 0;
                }
            }
        }

        p {
            color: $c-white;
            font-size: 16px;
            letter-spacing: .5px;
            line-height: 20px;
            padding: 0 0 0 3px;

            @include desktop() {
                color: $c-unordinary-title;
                font-size: 15px;
                letter-spacing: 0;
                padding: 0 0 16px;
            }
        }

        picture {
            display: none;

            @include desktop() {
                display: block;
            }

            img {
                margin-left: auto;
                margin-right: 0;
                min-height: 366px;
            }
        }
    }
}
