.sign-up {

    &--background {
        display: block;
        margin: 0 auto;
        overflow: hidden;
        width: 100%;

        img {
            height: 100%;
            min-width: 100%;
        }
    }

    &--content {
        font-family: $f-freight;
        padding: 32px 35px 24px;

        @include tablet() {
            padding: 30px 0 22px;
        }

        @include desktop() {
            padding: 29px 0 24px;
        }

        .title {
            font-size: 32px;
            font-weight: 100;
            letter-spacing: .2px;
            line-height: 1.1;
            max-width: 250px;
            padding-bottom: 14px;

            @include tablet() {
                font-size: 36px;
                max-width: none;
                padding-bottom: 12px;
            }

            @include desktop() {
                line-height: 1;
                padding-bottom: 20px;
            }
        }

        .subtitle {
            font-size: 18px;
            letter-spacing: -.3px;
            line-height: 1.4;
            padding-bottom: 10px;

            @include tablet() {
                font-size: 23px;
                letter-spacing: 1px;
                padding-bottom: 13px;
            }

            @include desktop() {
                font-size: 23px;
                padding-bottom: 15px;
            }
        }

        .link {
            a {
                font-size: 16px;
            }
        }

        p {
            font-size: inherit;
            line-height: inherit;
            padding: 0;
        }
    }

    &.sign_up--image {
        margin-bottom: 75px;
        position: relative;

        .sign_up--background {
            height: 235px;

            @include desktop() {
                height: 181px;
            }

            img {
                height: 100%;
                width: 100%;
            }
        }

        .sign_up--content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-storyblock {
        margin: 0 auto;
        max-height: 555px;
        max-width: $storyblock-max-width;
        position: relative;
        width: 100%;

        @include media(max-width $bp-tablet - 1px) {
            height: auto !important; // scss-lint:disable ImportantRule
            max-height: none !important; // scss-lint:disable ImportantRule
        }

        &.tablet-enabled {
            @include tablet() {
                height: auto !important; // scss-lint:disable ImportantRule
                max-height: none !important; // scss-lint:disable ImportantRule
            }

            .image-primary.desktop {
                display: none;

                @include desktop() {
                    display: block;
                }
            }

            .image-primary.tablet {
                display: none;

                @include tablet() {
                    display: block;
                }

                @include desktop() {
                    display: none;
                }
            }
        }

        .information-wrapper {
            align-items: flex-start;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            left: 0;
            padding: 5vw;
            position: absolute;
            right: 0;
            top: 0;

            @include tablet() {
                padding: 9vw 23vw 9vw 9vw;
            }

            @include desktop {
                padding: 9vw 23vw 9vw 12.5vw;
            }

            @include media($storyblock-max-width) {
                padding: $storyblock-max-width * .09 $storyblock-max-width * .23 $storyblock-max-width * .09 $storyblock-max-width * .125;
            }
        }

        .title,
        .subtitle {
            padding: 0;

            p, h2 {
                font-family: $f-lafayette148;
                line-height: 1.3;
                padding: 0;
            }
        }

        .title {
            p, h2 {
                font-size: 37px;
                font-weight: 300;
                line-height: .85;
                letter-spacing: 0;
                text-transform: uppercase;

                @include desktop {
                    font-size: 55px;
                }

                &::first-line {
                    font-family: $f-perpetua-titling;
                }
            }
        }

        .subtitle {
            margin-top: 25px;
            max-width: 100%;

            p {
                font-size: 15px;
                font-weight: 300;
                line-height: 1.8;
            }
        }

        .link {
            padding-top: 15px;

            p {
                padding: 0;

                a {
                    font-size: 15px;
                    text-decoration: none;
                    text-transform: uppercase;
                }
            }
        }

        .image-primary {
            background-position: right center;
            background-repeat: no-repeat;
            background-size: cover;
            display: none;

            @include tablet() {
                display: block;
                max-height: 555px;
            }
        }

        .image_primary_mobile {
            background-position: right center;
            background-repeat: no-repeat;
            background-size: cover;

            @include tablet() {
                display: none;
            }
        }

        .caption-tablet{
            display: none;

            @include tablet() {
                display: initial;
            }
        }

        &.center {
            margin-bottom: 18vw;

            @include tablet(){
                margin-bottom: 14vw;
            }

            @include desktop() {
                margin-bottom: 0;
            }

            .information-wrapper {
                @include tablet() {
                    padding: 9vw;
                }
            }

            .title {
                display: flex;
                justify-content: center;
                width: 100%;

                p, h2 {
                    font-family: $f-lafayette148;
                    font-size: 31px;
                    line-height: 31px;
                    text-align: center;
                    width: 75%;

                    @include tablet() {
                        width: 80%;
                    }

                    @include desktop() {
                        font-size: 44px;
                        line-height: 44px;
                    }
                }
            }

            .subtitle {
                display: flex;
                justify-content: center;
                margin-top: 50px;

                @include desktop() {
                    margin-top: 65px;
                }

                p {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;
                    width: 80%;

                    @include tablet() {
                        font-size: 31px;
                        font-weight: 300;
                        line-height: 31px;
                        width: 100%;
                    }

                    @include desktop() {
                        font-size: 35px;
                        line-height: 44px;
                        width: 68%;
                    }
                }
            }
        }


        &.left {
            .title {
                p, h2 {
                    @include desktop() {
                        max-width: 500px;
                    }
                }
            }

            .subtitle,
            .link {
                p {
                    @include desktop() {
                        max-width: 445px;
                    }
                }
            }
        }
    }
}
