// L Shape overlay logic

$radius-coeff: .01;

@mixin overlay($calculation-width, $width-coeff, $radius-coeff) {
    background-color: transparent;
    border-radius: 0;
    height: 100%;
    left: -100%;
    display: block;
    position: absolute;
    right: calc(30% - (#{$calculation-width} * #{$width-coeff}));
    top: 0;
    width: 100%;

    .horizontal {
        background: $c-white;
        border-radius: calc(#{$calculation-width * $radius-coeff}) calc(#{$calculation-width * $radius-coeff}) 0 0;
        bottom: 0;
        height: calc(#{$calculation-width} * #{$width-coeff});
        left: calc((-1) * (#{$calculation-width} * #{$width-coeff}) * (2 / 3));
        position: absolute;
        width: 100%;
    }

    .corners {
        bottom: calc(#{$calculation-width} * #{$width-coeff});
        height: calc(#{$calculation-width * $radius-coeff});
        margin-left: calc(-1 * #{$calculation-width * $radius-coeff});
        max-width: calc(#{$calculation-width} * #{$width-coeff} + (#{$calculation-width * $radius-coeff} * 2));
        overflow: hidden;
        padding-left: calc(-1 * #{$calculation-width * $radius-coeff});
        position: absolute;
        width: calc((#{$calculation-width} * #{$width-coeff}) + (#{$calculation-width * $radius-coeff} * 2));

        &::before,
        &::after {
            border-radius: calc(#{$calculation-width * $radius-coeff});
            box-shadow: 0 0 0 calc(#{$calculation-width * $radius-coeff} * 4) $c-white;
            content: '';
            margin: calc(-1 * #{$calculation-width * $radius-coeff});
            padding: calc(#{$calculation-width * $radius-coeff});
            position: absolute;
        }

        &::after {
            right: 0;
        }
    }
}

.image-storyblock {
    min-height: 1px; // Fix for IE11 flex height issues
    position: relative;

    @include media(max-width $bp-tablet - 1px) {
        background-color: unset !important;
    }

    .image-wrapper {
        position: relative;

        img {
            vertical-align: middle;
            width: 100%;
        }
    }

    .caption {
        margin-top: 20px;
        text-align: right;

        .caption-title {
            font-size: 30px;
            margin: 20px 0;

            @include tablet {
                font-size: 40px;
            }
        }

        .caption-text {
            margin: 15px 0 15px auto;
            padding: 0;
            width: 95%;

            @include tablet {
                font-size: 17px;
                width: 85%;
            }
        }

        .caption-link {
            margin: 0;
        }
    }

    .image-link {
        bottom: 0;
        font-weight: normal;
        left: 10px;
        position: absolute;
    }

    &[data-small-block='true'] {
        .image-wrapper {
            width: 80%;
        }
    }

    &[data-l-shape-enabled="true"] {
        .l-shape-wrapper {
            display: none;
            transform: scaleX(-1);

            @include desktop() {
                @include overlay(100vw, $sb-lane-desktop, $radius-coeff);
            }

            @include media($sb-max-width) {
                @include overlay($sb-max-width, $sb-lane-desktop, $radius-coeff);
            }
        }
    }
}

.tile-storyblock.four-tile [data-small-block='true'] .image-wrapper {
    width: 65%;
}

.tile-storyblock:not(.reversed) .image-storyblock[data-align-mobile='right'],
.reversed .image-storyblock[data-align-mobile='left'] {
    .caption {
        text-align: left;

        .caption-text {
            margin: 15px 0;
        }
    }

    .image-link {
        left: auto;
        right: 10px;
    }

    &[data-small-block='true'] {
        .image-wrapper {
            float: right;
        }
    }
}

.reversed .image-storyblock .l-shape-wrapper {
    left: 100%;
    transform: none;
}

.stories-block {
    .image-storyblock {
        .caption-title {
            &:first-line {
                font-family: $f-lafayette148;
            }
        }
    }
}

@include desktop {
    .pagebuilder-column .image-storyblock {
        .caption {
            left: 10%;
            margin: 0;
            position: absolute;
            text-align: left;
            transform: translateY(-50%);

            .caption-title {
                font-size: 58px;
            }
        }

        .image-link {
            bottom: -35px;
            left: 0;
            position: absolute;
        }


        &[data-small-block='true'] {
            .image-wrapper {
                float: right;
                width: 75%;
            }

            .image-link {
                left: auto;
                right: 0;
                width: 75%;
            }
        }

        &[data-caption-position='top'] {
            .caption {
                top: 15%;
            }
        }

        &[data-caption-position='middle'] {
            .caption {
                top: 50%;
            }
        }

        &[data-caption-position='bottom'] {
            .caption {
                top: 80%;
            }
        }

        &[data-caption-position='below'] {
            .caption {
                left: 20%;
                margin-top: 60px;
                position: relative;
                transform: none;
                width: 80%;

                .caption-text {
                    margin: 15px 0;
                }
            }
        }

        &[data-spacing~='top'] {
            margin-top: 10vw;

            &[data-small-block='true'] {
                margin-top: 12.2vw;
            }
        }

        &[data-spacing~='bottom'] {
            margin-bottom: 10vw;
        }
    }

    .tile-storyblock:not(.reversed) .pagebuilder-column .image-storyblock {
        &[data-spacing~='right'] {
            margin-right: 10vw;
        }

        &:not([data-spacing~='right']) {
            margin-right: 0;
        }

        &[data-spacing~='left'] {
            margin-left: 10vw;
        }
    }

    .reversed .pagebuilder-column .image-storyblock {
        &[data-spacing~='right'] {
            margin-left: 10vw;
        }

        &:not([data-spacing~='right']) {
            margin-left: 0;
        }

        &[data-spacing~='left'] {
            margin-right: 10vw;
        }
    }

    .tile-storyblock:not(.reversed) .pagebuilder-column .image-storyblock {
        &[data-spacing~='left'] {
            margin-left: 10vw;
        }

        &:not([data-spacing~='left']) {
            margin-left: 0;
        }

        &[data-spacing~='right'] {
            margin-right: 10vw;
        }
    }

    .reversed .pagebuilder-column .image-storyblock {
        &[data-spacing~='left'] {
            margin-right: 10vw;
        }

        &:not([data-spacing~='left']) {
            margin-right: 0;
        }

        &[data-spacing~='right'] {
            margin-left: 10vw;
        }
    }

    .tile-storyblock.four-tile [data-small-block='true'] {
        .image-wrapper {
            width: 55%;
        }

        .image-link {
            left: auto;
            right: 0;
            width: 55%;
        }
    }

    .tile-storyblock:not(.reversed) .pagebuilder-column:nth-child(2) .image-storyblock,
    .reversed .pagebuilder-column:nth-child(1) .image-storyblock {
        .caption {
            left: auto;
            right: 10%;
            text-align: right;

            .caption-text {
                margin: 15px 0 15px auto;
            }
        }

        &[data-caption-position='none'] {
            .image-link {
                left: auto;
                right: 0;
            }
        }

        &[data-caption-position='below'] {
            .caption {
                right: auto;
            }
        }

        &[data-small-block='true'] {
            .image-wrapper {
                float: none;
            }

            .image-link {
                left: 0;
                right: auto;
            }
        }
    }

    .cta-left,
    .cta-right {
        .image-link {
            text-align: left;
            width: 100%;
        }

        &[data-small-block='false'] {
            .image-link {
                left: 0 !important;
                right: 0 !important;
            }
        }
    }

    .cta-right {
        .image-link {
            text-align: right;
        }
    }
}

@include media($sb-max-width) {
    .pagebuilder-column .image-storyblock {
        &[data-spacing~='top'] {
            margin-top: $sb-max-width * $sb-lane-desktop;

            &[data-small-block='true'] {
                margin-top: 212px;
            }
        }

        &[data-spacing~='bottom'] {
            margin-bottom: $sb-max-width * $sb-lane-desktop;
        }
    }

    .tile-storyblock:not(.reversed) .pagebuilder-column .image-storyblock {
        &[data-spacing~='right'] {
            margin-right: $sb-max-width * $sb-lane-desktop;
        }

        &[data-spacing~='left'] {
            margin-left: $sb-max-width * $sb-lane-desktop;
        }
    }

    .reversed .pagebuilder-column .image-storyblock {
        &[data-spacing~='right'] {
            margin-left: $sb-max-width * $sb-lane-desktop;
        }

        &[data-spacing~='left'] {
            margin-right: $sb-max-width * $sb-lane-desktop;
        }
    }
}
