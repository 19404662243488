.text-storyblock {
    padding: 0 (100vw * $sb-lane-mobile) 0 5vw;

    @include tablet {
        padding: 0 (100vw * $sb-lane-tablet);
    }

    &.inclusivesizing {
        margin-top: 14vw;

        @include desktop() {
            margin-top: 0;
        }

        .text-title {
            width: 90%;
        }
    }

    &.buttonView {
        .button-wrapper {
            background-color: $c-grey-fuscous;
            display: flex;
            height: 50px;
            justify-content: center;
            max-width: 222px;

            &:hover {
                background-color: $c-tapa-other;
            }

            .text-link {
                color: $c-white;
                line-height: 49px;
                margin-top: 0;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &[data-secondary-enabled="true"] {
            .button-wrapper {
                margin-left: 18vw;

                @include tablet() {
                    margin-left: 14vw;
                }

                @include desktop() {
                    margin-left: 5vw;
                }

                @include media($storyblock-max-width) {
                    margin-left: 84px;
                }

                .text-link {
                    margin-left: 0 !important; // scss-lint:disable ImportantRule
                }
            }
        }
    }

    .button-wrapper {
        .second-btn {
            margin-left: 30px !important; // scss-lint:disable ImportantRule
        }
    }

    .text-title {
        margin: 25px 0;
        width: 90%;
    }

    .text-primary,
    .text-secondary {
        a {
            font-size: 15px;
            font-weight: $f-weight-light;
            line-height: 1.8;
            text-transform: none;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .text-primary {
        font-family: $f-lafayette148;
        font-size: 15px;
        line-height: 1.8;
        margin: 0 0 20px;
        padding: 0;
    }

    .text-secondary {
        display: block;
        font-family: $f-lafayette148;
        font-size: 15px;
        line-height: 1.8;
        margin-bottom: 20px;
        margin-left: 100vw * $sb-lane-mobile;
        padding: 0;

        @include tablet {
            margin-left: 100vw * $sb-lane-tablet;
        }
    }

    .text-link {
        background: none;
        color: $c-black;
        font-family: $f-lafayette148;
        font-size: 15px;
        font-weight: 400;
        height: auto;
        line-height: 1;
        margin: 20px 0 0;
        padding: 0;
        text-transform: uppercase;
        width: auto;
    }

    &[data-secondary-enabled="true"] {
        padding: 0 5vw;

        .text-primary {
            font-weight: 700;
            text-transform: uppercase;

            @include tablet {
                margin-right: 5vw;
            }

            a {
                font-weight: $f-weight-bold;
                text-transform: uppercase;
            }
        }

        .text-link {
            margin-left: 100vw * $sb-lane-mobile;

            @include tablet {
                margin-left: 100vw * $sb-lane-tablet;
            }
        }
    }
}

@include desktop {
    .pagebuilder-column .text-storyblock[data-content-type='text_storyblock'] {
        padding: 100vw * $sb-lane-desktop;

        @include media($sb-max-width) {
            padding: $sb-max-width * $sb-lane-desktop;
        }

        &.four-tile-top {
            @include desktop() {
                padding-bottom: 5vw;
            }

            @include media($storyblock-max-width) {
                padding-bottom: 84px;
            }
        }

        .text-primary {
            width: 65%;
        }

        .text-secondary {
            margin-bottom: 20px;
            margin-left: 5vw;

            @include media($sb-max-width) {
                margin-left: $sb-max-width * .05;
            }
        }

        .text-link {
            margin-left: 0;
        }

        h2 + .text-primary,
        &[data-secondary-enabled="true"] .text-primary {
            width: 75%;
        }

        &[data-secondary-enabled="true"] {
            .text-link {
                margin-left: 5vw;

                @include media($sb-max-width) {
                    margin-left: $sb-max-width * .05;
                }
            }
        }
    }
}
