.store-list-wrapper .storefinder-store {
    margin-bottom: 39px;

    @include desktop() {
        border-bottom: 2px solid $c-grey-background-enhanced;
        display: flex;
        margin-bottom: 58px;
        padding-bottom: 57px;
    }

    &:last-of-type {
        @include desktop() {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }

    .left-column {
        display: block;
        position: relative;

        @include desktop() {
            flex: 0 0 50%;
        }

        &:hover::after {
            background-color: $c-white-transparent;
            bottom: 0;
            content: '';
            display: block;
            left: 0;
            opacity: .3;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .image {
        height: auto;
        width: 100%;
    }

    .right-column {
        margin-top: 2px;

        @include desktop() {
            flex: 0 0 50%;
            margin: 0;
            padding: 100px 0 100px 119px;
        }
    }

    .title-wrapper {
        display: block;
        margin: 10px 0 3px;

        @include desktop() {
            margin-top: 11px;
        }

        &:hover {
            color: $c-grey-main;
            text-decoration: none;
        }
    }

    .title {
        font-family: $f-freight;
        font-size: 35px;
        line-height: 35px;

        @include desktop() {
            font-size: 36px;
            letter-spacing: 0;
        }

        &:hover {
            color: $c-grey-main;
        }
    }

    .address {
        line-height: 18px;
    }

    .direction-url {
        display: inline-block;
        position: relative;
    }

    .direction-icon {
        background: url('../images/icon-pin.svg') -8px -7px no-repeat;
        background-size: 28px 28px;
        display: block;
        height: 15px;
        left: 0;
        position: absolute;
        top: 7px;
        width: 12px;
    }

    .direction-label {
        display: inline-block;
        margin-left: 18px;
        margin-top: 4px;
    }

    .store-contact-title {
        color: $c-grey-kelp;
        font-family: $f-lineto-akkurat;
        font-size: $f-size-xxs;
        font-weight: $f-weight-bold;
        letter-spacing: .4px;
        line-height: 12px;
        margin: 22px 0 7px;
    }

    .phone-row,
    .email-row {
        line-height: 20px;
        position: relative;
    }

    .phone-icon,
    .email-icon {
        display: block;
        height: 15px;
        left: 0;
        position: absolute;
        top: 7px;
        width: 15px;
    }

    .phone-label,
    .email-label {
        display: inline-block;
        font-weight: $f-weight-bold;
        margin: 4px 2px 0 27px;
    }

    .phone-icon {
        background: url('../images/icon-phone.svg') -8px -9px no-repeat;
        background-size: 30px 30px;
    }

    .email-icon {
        background: url('../images/icon-email.svg') -8px -10px no-repeat;
        background-size: 30px 30px;
    }
}
