._has-modal {
    height: 100%;
    max-height: 100vh;
    overflow: hidden;

    .modals-overlay {
        background: $c-grey-overlay;
        height: 100%;
        left: 0;
        opacity: .85;
        position: fixed;
        top: 0;
        width: 100%;
    }

    &.allow-scroll-custom {
        overflow-y: auto;
    }
}

.modal-slide,
.modal-popup {
    background: $c-grey-background;
    box-shadow: 1px 3.9px 8px 1px $c-box-shadow;
    display: block;
    left: 0;
    margin: 0 auto;
    min-height: 100px;
    opacity: 0;
    padding: 40px $content-spacing-xl;
    position: fixed;
    right: 0;
    text-align: center;
    top: 5%;
    transition: visibility 0s .3s, opacity .3s ease;
    visibility: hidden;
    width: 320px;
    z-index: $z-modals;

    @include tablet() {
        box-shadow: none;
        padding: 44px;
        width: 600px;
    }

    @include desktop() {
        max-height: 60vh;
        max-width: 60%;
        top: 20%;
    }

    &._inner-scroll {
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $c-grey-main;
        }
    }

    &.bottom-popup {
        background-color: #f2f2f2;
        bottom: 0;
        margin: 0;
        max-height: none;
        max-width: none;
        padding: 28px;
        position: fixed;
        top: auto;
        transform: translateY(40%);
        transition: 1s ease-in-out;
        transition-duration: .3s;
        width: 100%;

        @include desktop() {
            padding: 25px 49px 13px;
        }

        &._show {
            overflow-y: visible;
            top: 0;
            transform: translateY(0);
            transition-duration: .3s;

            @include mobile() {
                bottom: 0;
                top: auto;
            }
        }

        + .modals-overlay {
            display: none;
        }

        .modal-header {
            text-align: center;

            @include desktop() {
                text-align: right;
            }
        }
    }

    &._show {
        opacity: 1;
        transition: opacity .3s ease;
        visibility: visible;
    }

    .action-close {
        background: url('../images/icon-close-bold.svg') 2px 2px no-repeat transparent;
        background-size: 30px;
        border: 0 none;
        height: 33px;
        min-width: 34px;
        padding: 0;
        width: 30px;

        span {
            display: none;
        }
    }

    .title {
        margin-top: $content-spacing-xxl;

        span {
            display: block;
            font-family: $f-freight;
            font-size: $f-size-xl;
            letter-spacing: .5px;
            line-height: 1.1;
        }
    }

    .modal-inner-wrap {
        margin: 0 auto;
    }

    .content {
        p {
            font-size: $f-size-s;
            margin: 0 auto;
            max-width: 284px;
            padding-bottom: 25px;

            @include tablet() {
                font-size: $f-size-base;
                max-width: 420px;
            }

            &:last-of-type {
                padding-bottom: 0;
            }

            &.short {
                @include tablet() {
                    max-width: 350px;
                }
            }

            a {
                font-size: $f-size-base;
            }
        }
    }
}
