.storefinder-event {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 38px;
    padding-bottom: $content-spacing-xl;

    @include tablet-landscape() {
        padding-bottom: 0;
        margin-bottom: $content-spacing-xl;
        flex-wrap: nowrap;
        border-bottom: 3px solid #acacac
    }

    .title {
        color: $c-grey-kelp;
        font-size: $f-size-m;
        margin: $content-spacing-base 0 0;
        font-weight: $f-weight-bold;
    }

    .column-date,
    .column-name,
    .column-address,
    .column-info {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        justify-content: center;
    }

    .column-date {
        align-items: flex-start;
        flex: 0 0 125px;
        font-family: $f-perpetua-titling;
        padding: 22px 0 33px 0;
        text-indent: 25px;

        @include tablet() {
            flex: 0 0 187px;
            text-indent: 70px;
        }

        @include tablet-landscape() {
            flex: 0 0 25%;
            min-height: 213px;
            padding: 0 0 0 5%;
            text-indent: 0;
        }

        @include desktop() {
            flex: 0 0 24.3%;
        }

        .month {
            font-size: $f-size-xs;

            @include tablet-landscape() { 
                font-size: $f-size-l-larger;
                line-height: 1;
                margin-top: -20px;
            }
        }

        .day {
            font-size: 31px;
            line-height: 26px;

            @include tablet-landscape() { 
                font-size: 55px;
                line-height: 40px;
            }
        }
    }

    .column-name {
        flex: 0 0 auto;
        margin-left: auto;
        padding-right: $content-spacing-xxl;
        max-width: calc(100% -155px);

        @include tablet() {
            margin-left: $content-spacing-xxl;
            padding-right: 0;
            max-width: calc(100% - 217px);
        }

        @include tablet-landscape() {
            flex: 0 0 22.5%;
            max-width: none;
            margin-left: 0;
            align-items: center;
        }

        @include desktop() {
            flex: 0 0 18.25%;
        }

        .name {
            font-size: $f-size-m;
            font-weight: $f-weight-semi-bold;
            margin-bottom: $content-spacing-xs;
            max-width: 100%;

            @include tablet-landscape() {
                font-size: $f-size-m;
                font-weight: $f-weight-semi-bold;
                margin-bottom: 17px;
                max-width: 100%;
            }
        }

        .location {
            font-size: $f-size-xs;
            max-width: 100%;

            @include tablet-landscape() {
                font-size: $f-size-m;
                max-width: 100%;
            }
        }
    }

    .column-address {
        padding: 0 $content-spacing-m 0 $content-spacing-xl;

        @include tablet() {
            padding-left: 70px;
        }

        @include tablet-landscape() {
            flex: 0 1 42%;
            padding: 30px;
        }

        @include desktop() {
            align-items: center;
        }
    }

    .address-item {
        font-size: $f-size-xs;
        text-transform: none;

        .store-name {
            letter-spacing: $letter-spacing-xs;
            margin-bottom: $content-spacing-xs;

            @include tablet-landscape() {
                font-size: $f-size-m;
                margin-bottom: $content-spacing-m;
            }
        }

        .address {
            letter-spacing: $letter-spacing-xs;

            @include tablet-landscape() {
                font-size: $f-size-m;
            }
            
            br {
                display: none;
            }
        }

        .direction-url-block {
            @include desktop() {
                margin: 10px 0 0 -5px;
                text-align: left;
            }
        }
    }

    .address-block {
        .title {
            @include tablet-landscape() {
                margin-top: 0;
                margin-bottom: $content-spacing-m;
            }
        }

        .address-list {
            height: 0;
            overflow: hidden;
            transition: height 1s;

            .address-item {
                margin-top: 16px;
            }
        }

        .show-more-addresses {
            cursor: pointer;
            display: inline-block;
            font-weight: 700;
            line-height: 19px;
            margin-top: 10px;
            padding-left: 20px;
            position: relative;
            width: 100%;

            &::before {
                background: url('../images/icon-circle-arrow-down.svg') no-repeat -9px -9px;
                background-size: 34px;
                content: '';
                display: inline-block;
                height: 16px;
                left: 0;
                position: absolute;
                top: 0;
                transition: transform 1s;
                width: 16px;
            }
        }

        &.show-more {
            .address-item {
                display: block;
            }

            .show-more-addresses::before {
                transform: rotate(180deg);
            }
        }
    }

    .time-block {
        margin-top: 6px;
        display: flex;
        align-items: center;

        @include tablet-landscape() {
            margin-top: 13px;
        }
        
        .title {
            font-size: $f-size-xs;
            margin: 0 $content-spacing-xs 0 0;

            @include tablet-landscape() {
                font-size: $f-size-m;
                font-weight: $f-weight-base;
            }
        }

        .time-string {
            font-size: $f-size-xs;

            @include tablet-landscape() {
                font-size: $f-size-m;
            }
        }
    }

    .more-info-button {
        cursor: pointer;

        @include tablet-landscape() {
            display: none;
        }

        .icon {
            background: url('../images/icon-circle-arrow-down.svg') no-repeat -9px -4px;
            background-size: 34px 34px;
            display: inline-block;
            height: 26px;
            transition: transform .5s;
            width: 16px;
        }

        .label {
            display: inline-block;
            font-weight: 700;
        }
    }

    .column-info {
        max-width: 100%;
        padding-left: $content-spacing-xl;
        margin-top: $content-spacing-base;

        @include tablet() {
            padding-left: 70px;
        }

        @include tablet-landscape() {
            flex: 1 1 15%;
            padding: 0 15px 0 0;
            align-self: flex-start;
        }
        
        .title {
            display: none;
            font-size: $f-size-s;
            margin-top: 0;

            @include tablet-landscape() {
                font-size: $f-size-m;
                text-align: right;
            }

            @include desktop() {
                display: block;
            }
        }

        .info-block {
            height: 0;
            overflow: hidden;
            transition: height .3s ease-in;

            @include desktop() {
                height: auto;
            }
        }

        .info-text {
            word-break: break-all;
            text-transform: none;
            font-size: $f-size-xs;

            @include desktop() {
                margin-top: 9px;
            }

            p {
                font-size: $f-size-base;
                line-height: 18px;

                @include tablet-landscape() {
                    text-align: right;
                }
            }
        }

        &.info-shown {
            .more-info-button .icon {
                transform: rotate(180deg);
            }
        }
    }

    .rsvp-block {
        margin-top: 16px;
    }

    .direction-url {
        display: inline-block;
        position: relative;
    }

    .direction-icon {
        background: url('../images/icon-pin.svg') -8px -7px no-repeat;
        background-size: 28px 28px;
        display: block;
        height: 15px;
        left: 0;
        position: absolute;
        top: 7px;
        width: 12px;
    }

    .direction-label {
        display: inline-block;
        margin-left: 15px;
        margin-top: 4px;
    }
}

.storefinder-event:nth-child(1),
.storefinder-event:nth-child(2),
.storefinder-event:nth-child(3) {
    background: #f2f2f2;

    @include tablet-landscape() {
        border-bottom: 0;
    }

    .column-date {
        background-color: #f2f0ea;
    }
    
    .column-info {
        .title {
            display: block;
        }

        .more-info-button {
            display: none;
        }

        .info-block {
            height: auto;
        }
    }
}

