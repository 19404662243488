.hero-block.hero-video {
    @import 'home/heading-mixin';
    @import 'home/home-hero';

    margin-left: auto;
    margin-right: auto;

    @include heading() {
        padding: 30px 0 0 33px;
        z-index: 100;

        @include desktop() {
            left: 20.8%;
        }

        h2 {
            line-height: 1;
            margin-bottom: 26px;

            @include desktop() {
                letter-spacing: .2px;
                margin-bottom: 28px;
            }

            a {
                line-height: 1;

                @include desktop() {
                    font-size: 60px;
                }
            }
        }

        &.bottom {
            @include desktop() {
                bottom: 14%;
            }
        }

        &.right {
            @include desktop() {
                right: 22.5%;
            }
        }
    }

    .pagebuilder-video-container {
        .vjs-big-play-button {
            @include desktop() {
                display: none;
            }
        }

        &::after {
            @include desktop() {
                background-color: transparent;
            }
        }
    }

    .vjs-big-play-button {
        outline: 0;
        transition: none;
        z-index: 300;

        &:focus,
        &:active {
            outline: 0;
        }
    }

    .video-js.fs-button:not(.redirect) {
        background-color: transparent;

        .vjs-big-play-button {
            border: 0;
            border-radius: 0;
            height: 100%;
            outline: 0;
            width: 100%;
            z-index: 0;

            @include desktop() {
                left: 0;
                top: 0;
            }

            span,
            &::after {
                display: none;
            }

            &:focus,
            &:active {
                outline: 0;
            }
        }

        .video-thumbnail,
        &::after {
            display: none;
        }

        &.video-played {
            .vjs-big-play-button {
                display: block;
            }
        }

        .hero-video-redirect {
            display: none;
        }
    }

    .video-js.redirect.fs-button {
        background-color: transparent;

        .vjs-big-play-button {
            display: none;
        }
    }

    .hero-video-redirect {
        display: none;

        @include desktop() {
            cursor: pointer;
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 290;
        }
    }
}
