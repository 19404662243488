// Form elements
fieldset {
    border: 0 none;
    margin: 0;
    padding: 0;
    width: 100%;

    @include tablet() {
        width: 100%;
    }

    &.short {
        @include tablet() {
            width: 234px;
        }
    }

    legend {
        display: none;
    }

    + .actions-toolbar {
        margin: $content-spacing-base 0;

        button {
            width: 100%;

            @include tablet() {
                width: 479px;
            }
        }
    }

    .note {
        line-height: $line-height-s;
        margin-bottom: $content-spacing-xl;

        &-required {
            margin-bottom: $content-spacing-base;
            text-align: right;
        }
    }
}

#{$all-text-inputs} {
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid $c-input-border;
    border-radius: 0;
    box-shadow: none;
    color: $c-grey-fuscous;
    font-family: $f-lafayette148;
    font-size: $f-size-xs;
    font-weight: $f-weight-base;
    height: 50px;
    line-height: 16px;
    padding: 14px 15px;
    width: 100%;

    @include tablet() {
        width: 479px;
    }

    &.password-field {
        padding: 14px 40px 14px 15px;
    }

    .short {
        @include tablet() {
            width: 234px;
        }
    }

    &::placeholder {
        color: $c-grey-fuscous;
        font-size: $f-size-xs;
        font-weight: $f-weight-base;
        opacity: 1;
    }

    &.mage-error {
        border: 1px solid $c-red;
        color: $c-red;

        &::placeholder {
            color: $c-red;
        }
    }

    + .mage-error {
        color: $c-red;
        margin-top: $content-spacing-base;
    }

    // scss-lint:disable PseudoElement, VendorPrefix
    &:focus::-webkit-input-placeholder {
        color: transparent;
    }

    &:focus:-moz-placeholder {
        color: transparent;
    }

    &:focus::-moz-placeholder {
        color: transparent;
    }

    &:focus:-ms-input-placeholder {
        color: transparent;
    }
    // scss-lint:enable PseudoElement, VendorPrefix
}

.control {
    margin-bottom: 13px;
    position: relative;
}

label {
    &.label {
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute;
        width: 1px;
    }

    &.label-visible {
        clip: auto;
        height: auto;
        overflow: auto;
        position: static;
    }
}

textarea {
    min-height: 133px;
    padding: $content-spacing-base $content-spacing-l;
}

.checkbox {
    @include size(14px);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: solid 1px $c-grey-fuscous;
    border-radius: 0;
    cursor: pointer;
    position: relative;
    vertical-align: middle;

    &.checked::after,
    &:checked::after {
        @include size(14px);
        content: '×';
        font-size: 16px;
        left: -.5px;
        line-height: 14px;
        position: absolute;
        text-align: center;
        top: 0;
    }

    + label {
        clip: auto;
        color: $c-grey-fuscous;
        cursor: pointer;
        display: block;
        float: right;
        font-family: $f-lafayette148;
        font-size: 13px;
        font-weight: $f-weight-light;
        height: auto;
        letter-spacing: -.1px;
        line-height: 15px;
        overflow: auto;
        position: static;
        width: calc(100% - 22px);

        a {
            color: $c-black;
            font-family: $f-lafayette148;
            font-size: 13px;
            font-weight: $f-weight-light;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.radio {
    @include size(16px);
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 1px solid $c-brown;
    border-radius: 50%;
    cursor: pointer;
    margin-top: -2px;
    position: relative;
    vertical-align: middle;

    &:checked,
    &.checked {
        &::after {
            @include size(11px);
            background-color: $c-brown;
            border-radius: 50%;
            content: '';
            display: block;
            font-size: $f-size-m-larger;
            left: 50%;
            line-height: 100%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    + label {
        cursor: pointer;
        float: right;
        line-height: $line-height-s;
        padding-top: 3px;
        width: calc(100% - 35px);
    }
}

.field-error {
    color: $c-red;
}

.unmask-password {
    @include size(18px, 9px);
    background: transparent url('../images/icon-unmask.svg') no-repeat center;
    background-size: contain;
    border: 0;
    padding: 1px 5px 0;
    position: absolute;
    right: 16px;
    top: 18px;

    &::before {
        color: $c-grey-survey-text;
        content: '|';
        font-size: 22px;
        font-weight: 300;
        height: 10px;
        left: 21px;
        position: absolute;
        top: -13px;
        transform: rotate(45deg);
        width: 10px;
    }

    &.unmasked {
        &::before {
            display: none;
        }
    }
}

.field-tooltip {
    @include size(10px);
    position: absolute;
    right: 18px;
    top: 15px;

    @include tablet() {
        top: 18px;
    }

    .field-tooltip-action {
        @include size(10px);
        border: 1px solid $c-brown;
        border-radius: 50%;
        display: block;

        &::after {
            @include size(8px);
            content: '?';
            display: block;
            font-size: 6px;
            padding-left: 1px;
            text-align: center;
        }

        span {
            display: none;
        }
    }

    .field-tooltip-content {
        background-color: $c-white;
        border: 1px solid $c-brown;
        bottom: 100%;
        display: none;
        padding: 5px;
        position: absolute;
        right: -21px;
        transform: translateY(-12px);
        width: 200px;

        &::before,
        &::after {
            border: 10px solid transparent;
            border-top-color: $c-brown;
            bottom: -20px;
            content: '';
            position: absolute;
            right: 15px;
            z-index: 20;
        }

        &::after {
            border-top-color: $c-white;
            bottom: -19px;
            z-index: 30;
        }
    }

    &._active {
        .field-tooltip-content {
            display: block;
        }
    }
}
