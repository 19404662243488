.unordinary {
    .how-works {
        display: none !important; // scss-lint:disable ImportantRule
        margin: 0 30px 80px;

        @include tablet-landscape() {
            margin-bottom: 136px;
        }

        .pagebuilder-column-group {
            display: block !important; // scss-lint:disable ImportantRule

            @include tablet-landscape() {
                display: flex !important; // scss-lint:disable ImportantRule
            }
        }

        &__left {
            width: 100% !important; // scss-lint:disable ImportantRule

            @include tablet-landscape() {
                width: 50% !important; // scss-lint:disable ImportantRule
            }

            &__image {
                max-width: 100%;

                @include tablet-landscape() {
                    display: flex;
                }

                // scss-lint:disable NestingDepth
                picture,
                img {
                    height: 100%;
                    width: 100%;

                    @include tablet-landscape() {
                        margin: auto;
                        margin-right: 0;
                        max-width: 541px;
                    }
                }
                // scss-lint:enable NestingDepth
            }
        }

        &__right {
            margin: 0;
            margin-top: 40px;
            text-align: center !important; // scss-lint:disable ImportantRule
            width: 100% !important; // scss-lint:disable ImportantRule

            @include tablet-landscape() {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: auto 0;
                padding: 0 30px 8px;
                text-align: left !important; // scss-lint:disable ImportantRule
                width: 50% !important;  // scss-lint:disable ImportantRule
            }

            @include desktop() {
                padding: 36px 96px 6px;
            }

            &__title {
                color: $c-brown-darker;
                margin-bottom: 19px;

                @include tablet-landscape() {
                    margin-bottom: 23px;
                }
            }

            &__description {
                margin: 0 6px;
                text-align: center;

                @include tablet-landscape() {
                    max-width: 420px;
                    text-align: left;
                }

                // scss-lint:disable NestingDepth
                p {
                    font-size: 16px;
                    font-weight: 100;
                    letter-spacing: 0;
                    line-height: 28px;
                    padding-bottom: 14px;

                    @include tablet-landscape() {
                        padding-bottom: 14px;
                    }
                }
                // scss-lint:enable NestingDepth
            }

            &__create {
                margin: 15px;
                text-align: center;

                @include tablet-landscape() {
                    margin: 15px 0 0 6px;
                    text-align: left;
                }

                // scss-lint:disable NestingDepth
                &__btn {
                    background: $c-brown-darker;
                    font-size: 13.5px;
                    letter-spacing: .4px;
                    max-width: 260px;
                    min-height: 51px;
                    padding: 12px 5px;
                }
                // scss-lint:enable NestingDepth
            }
        }
    }
}
