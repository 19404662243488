[data-content-type="two_image_block"] {
    margin-bottom: 37px;

    @include tablet() {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 70px;
    }

    [data-content-type="image_item"] {
        background-color: $c-black;
        flex: 1 1 50%;
        overflow: hidden;
        position: relative;

        &[data-small-image="true"] {
            margin: 37.5px;

            @include tablet() {
                flex-basis: 41.56%;
                margin: 0 4.22% 0;
            }

            &[data-animate="true"] {
                @include tablet() {
                    opacity: 0;
                    position: relative;
                    transition: transform ease-in .5s, opacity ease-in .5s;
                    transform: translateY(100px);
                }

                &.animated {
                    @include tablet() {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
            }

            .image-wrapper {
                .image-link {
                    padding-top: 148.9971%;
                }
            }
        }

        &[data-small-image="false"] {
            &[data-animate="true"] {
                .caption {
                    @include tablet() {
                        opacity: 0;
                        transition: transform ease-in .5s, opacity ease-in .5s;
                        transition-delay: .3s;
                        transform: translateY(100px) translateX(-50%);
                    }
                }

                &.animated {
                    .caption {
                        @include tablet() {
                            transform: translateY(0) translateX(-50%);
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .image-wrapper {
            position: relative;

            &::after {
                background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 85%);
                bottom: 0;
                content: '';
                display: block;
                height: 33%;
                left: 0;
                position: absolute;
                width: 100%;
            }

            .image-link {
                display: block;
                height: 0;
                overflow: hidden;
                padding-top: 138.5714%;
                position: relative;
                width: 100%;
            }

            img {
                left: 0;
                position: absolute;
                top: 0;
                vertical-align: middle;
                width: 100%;
            }
        }

        .caption {
            bottom: 28px;
            left: 50%;
            position: absolute;
            text-align: center;
            transform: translateX(-50%);

            @include tablet() {
                bottom: 34px;
            }

            .caption-title {
                color: $c-white;
                display: block;
                font-family: $f-lafayette148;
                font-size: 23px;
                font-weight: 300;
                letter-spacing: unset;
                line-height: 18px;

                @include desktop() {
                    font-size: 32px;
                    line-height: 27px;
                }

                &::first-line {
                    font-family: $f-perpetua-titling;
                }
            }

            @include new-cms-button-large();

            .cms-button {
                margin-top: 16px;

                @include desktop() {
                    margin-top: 33px;
                }
            }
        }
    }

    [data-content-type="image_item_slider"] {
        padding: 21px 21px 0;
        margin-bottom: 51px;
        width: 100%;

        @include tablet() {
            flex: 1 1 50%;
            margin-bottom: 30px;
            padding: 0 11.25%;
            width: auto;
        }

        &[data-small-block="true"] {
            align-self: center;

            &[data-animate="true"] {
                @include tablet() {
                    opacity: 0;
                    position: relative;
                    transition: transform ease-in .5s, opacity ease-in .5s;
                    transition-delay: .3s;
                    transform: translateY(100px);
                }

                &.animated {
                    @include tablet() {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
            }

            .image-wrapper {
                &::after {
                    display: none;
                }
            }

            .caption {
                display: none;
            }
        }
    }
}

[data-content-type="image_item_slider"] {
    min-width: 0;
    position: relative;

    &:not(.slick-initialized) {
        margin-bottom: 30px;

        [data-content-type="image_item"]:nth-of-type(n + 2) {
            display: none;
        }
    }

    [data-content-type="image_item"] {
        flex: auto;
        vertical-align: middle;

        .image-wrapper {
            .image-link {
                display: block;
                height: auto;
                overflow: hidden;
                padding: 0 !important;
                position: relative;
                width: 100%;
            }

            img {
                max-width: 100%;
                position: static;
                vertical-align: middle;
                width: 100%;
            }
        }
    }

    .slick-dots {
        bottom: -30px;

        @include tablet() {
            bottom: -27px;
        }
    }
}
