.storefinder-event-list {
    overflow-x: visible;

    .head-image {
        display: none;

        @include desktop() {
            display: block;
            padding: 60px 0 40px;
        }
    }

    .storefinder-list-head {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @include tablet-landscape() {
            flex-wrap: nowrap;
            margin-bottom: 60px;
        }
    }

    .storefinder-events {
        padding: 0 16px;

        @include tablet() {
            padding: 0;
        }
    }

    .storefinder-list-title {
        flex: 1 1 100%;
        font-size: 26px;
        font-family: $f-perpetua-titling;
        font-weight: 300;
        margin: 24px 0 48px 0px;
        text-align: center;
        order: 2;
        letter-spacing: unset;

        @include tablet-landscape() {
            order: 1;
            font-size: 44px;
            flex: 1 0 auto;
            text-align: left;
            margin: 0;
        }

        @include desktop() {
           
        }
    }

    .storefinder-list-filters {
        flex: 1 1 100%;
        margin: 21px 0 35px;

        @include tablet() {
            flex: 0 1 54%;
        }

        @include tablet-landscape() {
            order: 2;
            flex: 0 1 417px;
            margin: 0 0 0 30px;
        }

        > select,
        > div {
            height: 48px;
            max-width: none;
            width: 100%;

            @include tablet-landscape() {
                height: 58px;
            }
        }

        > div {
            .chosen-single {
                border-color: $c-black;
                border-width: 2px;
                height: 100%;
                background-color: #efefef;

                div {
                    b {
                        background-size: 12px 8px;

                        @include tablet-landscape() {
                            background-size: 18px 12px;
                        }
                    }
                }
            }
        }

        > select {
            background-size: 12px 8px;
            text-transform: uppercase;
            background-position-x: calc(100% - 15px);
            border-color: $c-black;
            padding: 0 0 0 18px;
            border-width: 2px;
            background-color: #efefef;
        }
    }

    .storefinder-events {

        @include desktop() {
            margin-top: 10px;
        }
    }

    .loading-mask {
        align-items: center;
        display: none;
        height: 70px;
        justify-content: center;
        position: relative;
        width: 100%;

        &.visible {
            display: flex;
        }

        img {
            height: 80%;
        }
    }
}
