// Reset
dd {
    margin: 0;
}

img {
    max-width: 100%;
}

// Buttons
#{$all-buttons},
.button {
    background-color: $c-black;
    border: 0 none;
    border-radius: 0;
    color: $c-white;
    cursor: pointer;
    font-family: $base-font-family;
    font-size: 15px;
    font-weight: 400;
    height: 49px;
    letter-spacing: .1px;
    line-height: 27px;
    max-width: 100%;
    padding: 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;

    &:disabled {
        background-color: $c-grey-quill;
        color: $c-brown;
    }

    &.secondary {
        background-color: transparent;
        border: solid .5px $c-brown;
        color: $c-brown;
        opacity: .5;
    }

    &:not([data-content-type="button-item"]) {
        padding: $content-spacing-xs;
    }

    a,
    div {
        color: $c-white;
        cursor: pointer;
        display: inline-block;
        font-size: $f-size-base;
        font-weight: $f-weight-bold;
        height: 100%;
        line-height: 35px;
        max-width: 100%;
        padding: $content-spacing-xs;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        width: 100%;

        &:disabled,
        &.secondary {
            color: $c-brown;
        }
    }
}

body > img {
    left: -10000px;
    position: absolute;
    visibility: hidden; // To hide tracking pixels
}

.hidden-overflow {
    overflow: hidden !important; // scss-lint:disable ImportantRule
}

.button-transparent {
    background-color: transparent;
    border: 2px solid $c-grey-main;
    color: $c-brown;

    &:focus,
    &:hover {
        background-color: $c-grey-main;
        color: $c-white;
    }
}

a {
    &.button {
        display: inline-block;
        line-height: 30px;

        @include tablet() {
            line-height: 35px;
        }

        &:hover {
            background-color: $c-brown;
            text-decoration: none;
        }
    }
}

#{$all-buttons-focus},
#{$all-buttons-hover},
#{$all-buttons-active} {
    background-color: $c-brown;
    text-decoration: none;

    &:disabled {
        background-color: $c-grey-quill;
        cursor: default;
    }

    a,
    div {
        text-decoration: none;
    }
}

// Lists
ul,
ol {
    list-style: disc inside none;
    margin: 0;
    padding: 0;

    li {
        font-size: $f-size-m;
        line-height: $line-height-sm;
    }
}

// Tables
table {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
        border-bottom: .5px solid $c-grey-light-border;
        border-top: .5px solid $c-grey-light-border;
        font-weight: $f-weight-base;
        letter-spacing: $letter-spacing-xxs;
        line-height: $line-height-ml;
        padding: $content-spacing-m $content-spacing-base $content-spacing-m 0;
        text-align: left;
    }

    caption {
        font-weight: $f-weight-bold;
        letter-spacing: $letter-spacing-xxs;
        line-height: $line-height-ml;
        margin-bottom: $content-spacing-m;
        text-align: left;
    }
}

// Back to top button
.back-to-top {
    display: none;
    z-index: 50;

    .action {
        @include size (44px, 43px);
        background: $c-white url('../images/arrow-up.png') center center no-repeat;
        background-size: 25px;
        border: solid 2px $c-grey-main;
        border-radius: 999rem;
        bottom: 135px;
        min-width: 43px;
        position: fixed;
        right: 30px;
        z-index: 50;

        @include tablet() {
            @include size(60px, 58px);
            min-width: 60px;
            right: 60px;
        }
    }

    &:hover {
        .action {
            background-color: $c-white;
        }
    }

    span {
        display: none;
    }
}

#CybotCookiebotDialog {
    box-shadow: 2px 2px 20px 2px $c-grey-background !important;
}

// Breadcrumbs
.breadcrumbs {
    display: none;

    @include tablet() {
        display: block;
        margin: 0 auto;
        max-width: 786px;
        min-height: 30px;
    }

    @include tablet-landscape {
        max-width: 1024px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {
        max-width: 790px;
    }

    @include desktop() {
        max-width: 1230px;
    }

    .item {
        color: $c-grey-main;
        display: inline-block;

        &:nth-of-type(1) {
            &::before {
                content: '/';
                left: 2px;
                position: relative;
            }

            &::after {
                content: '/';
            }
        }

        a,
        strong {
            color: $c-grey-kelp;
            font-size: $f-size-base;
            font-weight: $f-weight-base;
            line-height: 27px;
        }

        a {
            font-weight: $f-weight-base;
        }
    }
}

.social-share-wrapper {
    .share-options {
        display: flex;
        justify-content: space-between;
        margin-left: 0;
        opacity: 0;
        overflow: hidden;
        padding-top: 4px;
        transition: .7s width ease-in, .3s opacity ease-in, .7s margin ease-in;
        width: 0;

        .icon-share {
            @include size(15px);
            cursor: pointer;
            display: block;
            overflow: hidden;
            position: static;
            z-index: 100;

            img {
                @include size(100%);
                vertical-align: top;
            }
        }

        .email-share-button {
            img {
                @include size(28px);
                left: -7px;
                max-width: none;
                position: relative;
                top: -6px;
            }
        }
    }

    &.show-share {
        .share-options {
            margin-left: 15px;
            opacity: 1;
            transition: .3s width ease-in, .7s opacity ease-in, .3s margin ease-in;
            width: 75px;
        }
    }
}

.loading-mask {
    background-color: $c-white-transparent;
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;

    .loader {
        > img {
            bottom: 0;
            display: none;
            left: 0;
            margin: auto;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 100;
        }

        > p {
            display: none;
        }
    }
}

.v-slider {
    .v-slider-item {
        &.expanded {
            .v-slider-item-title {
                &::before {
                    background: linear-gradient(transparent 5px,#8f8f8f 0,#8f8f8f 7px,transparent 0);

                    @include tablet() {
                        background: linear-gradient(transparent 7px, #8f8f8f 0, #8f8f8f 9px, transparent 0);
                    }
                }
            }
        }
    }

    .v-slider-item-title {
        font-size: 24px;
        position: relative;
        cursor: pointer;

        label, h2 {
            cursor: pointer;
            font-weight: 400;
            font-size: 24px;
            font-family: "FreightBigPro", "Arial", sans-serif;
            letter-spacing: 1px;

            @include tablet() {
                letter-spacing: 2px;
            }
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            width: 12px;
            height: 12px;
            right: 20px;
            transform: translate(0, -50%);
            background: linear-gradient(transparent 5px,#8f8f8f 0,#8f8f8f 7px,transparent 0),linear-gradient(90deg,transparent 5px,#8f8f8f 0,#8f8f8f 7px,transparent 0);

            @include tablet() {
                right: 0;
                width: 16px;
                height: 16px;
                background: linear-gradient(transparent 7px, #8f8f8f 0, #8f8f8f 9px, transparent 0),linear-gradient(90deg, transparent 7px, #8f8f8f 0, #8f8f8f 9px, transparent 0);
            }
        }
    }

    .v-slider-item-wrapper {
        display: none;
        line-height: 26px;
        font-size: 12px;
        letter-spacing: 0.5px;
        margin: 17px 0 0 0;

        @include tablet() {
            margin: 28px 0 0 0;
            font-size: 15px;
            line-height: 33px;
        }
    }
}

.ui-tooltip {
    background-color: $c-white;
    color: $c-black;
    display: block;
    font-family: $f-lafayette148;
    font-size: 13px;
    font-weight: 300;
    padding: 8px 13px;
    position: absolute;
    z-index: 50;

    @include tablet() {
        padding-right: 25px;
    }

    &:after {
        @include tablet() {
            border: solid $c-grey-main;
            border-width: 1px 1px 0 0;
            content: '';
            display: block;
            height: 8px;
            position: absolute;
            right: 13px;
            top: 50%;
            transform: rotate(45deg) translateY(-50%);
            width: 8px;
        }
    }
}

.ui-helper-hidden-accessible {
    @extend .accessibility-hidden;
}
