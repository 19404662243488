.unordinary {
    .uw-video-block-2021 {
        padding: 27px 0 45px;

        @include tablet {
            padding: 31px 0 0;
        }

        @include desktop {
            margin: 0 auto;
            max-width: 1440px;
            padding: 100px 120px 0;
            width: 100%;
        }

        .title {
            padding: 0 30px 35px;

            @include tablet {
                padding: 0 60px 30px 105px;
            }

            @include desktop {
                padding: 0 0 3px;
            }

            &.first {
                padding-bottom: 0;

                p {
                    letter-spacing: 1.6px;
                    margin-bottom: 0;
                }
            }

            &.second {
                padding-bottom: 15px;

                @include tablet {
                    padding-bottom: 6px;
                }

                @include desktop {
                    padding-bottom: 0;
                }

                p {
                    font-family: $f-lafayette148;

                    @include desktop {
                        margin-bottom: 47px;
                    }
                }
            }

            p {
                color: $c-black;
                font-size: 37px;
                letter-spacing: 0;
                line-height: 30px;

                @include desktop {
                    font-size: $f-size-xxxml-mobile;
                    line-height: 44px;
                }
            }
        }

        .bf-video.wrapped {
            @include tablet {
                padding: 94px 0 71px;
            }

            @include desktop {
                padding: 94px 0 87px;
            }

            a {
                font-family: $f-lafayette148;
                font-size: $f-size-m;
                font-weight: $f-weight-light;
                margin-right: 42px;
                text-decoration: none;

                @include tablet {
                    margin-right: 7px;
                }
            }
        }

        .uw-video-text {
            font-family: $f-lafayette148;
            font-size: $f-size-m;
            font-weight: $f-weight-light;
            line-height: 27px;
            padding: 30px 35px 23px;

            @include tablet {
                padding: 35px 60px 0;
            }

            @include desktop {
                padding: 51px 0 0;
            }

            > * {
                margin-left: 15%;

                @include tablet {
                    margin-left: 18%;
                }

                @include desktop {
                    margin-left: 63%;
                }
            }

            + [data-content-type="text"] {
                @include tablet {
                    padding: 0 60px !important; // scss-lint:disable ImportantRule
                }

                @include desktop {
                    padding: 0 !important; // scss-lint:disable ImportantRule
                }
            }
        }

        .vjs-big-play-button {
            height: 66px;
            width: 66px;

            @include tablet {
                height: 50px;
                width: 50px;
            }

            @include desktop {
                height: 102px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 102px;
            }

            &:focus,
            &:hover {
                font-size: 36px;
            }

            &:after {
                display: none;
            }

            .vjs-icon-placeholder {
                &:before {
                    color: $c-white;
                    font-size: 46px;
                    left: 2px;
                    top: -8px;

                    @include tablet {
                        font-size: 36px;
                        left: 3px;
                        top: -8px;
                    }

                    @include desktop {
                        content: '\25B8';
                        font-size: 72px;
                        left: 6px;
                        top: -13px;
                    }
                }
            }
        }
    }
}
