// Contact Us Page
.contact-index-index {
    .contact-page-content-wrapper {
        width: 100%;

        [data-content-type="form_hero_block"] {
            margin-bottom: 54px !important; // scss-lint:disable ImportantRule

            @include tablet() {
                background-position-y: -145px;
                margin-bottom: 44px !important; // scss-lint:disable ImportantRule
            }

            @include desktop() {
                background-position-y: 0;
                margin: 1px -15px;
            }
        }

        [data-content-type="row"] {
            @include desktop() {
                max-width: 607px;
            }

            &:first-child {
                @include desktop() {
                    margin-top: 4px;
                }
            }

            &:last-child {
                @include desktop() {
                    max-width: 550px;
                }
            }
        }

        .page-title-wrapper {
            @include desktop() {
                margin-top: 55px;
            }
        }

        .contact-cms-content {
            display: flex;
            flex-wrap: wrap;

            @include desktop() {
                flex-direction: row-reverse;
                justify-content: space-between;
                margin: 0 auto 70px;
                max-width: 1310px;
                padding: 92px 20px 0;
            }
        }

        .messages {
            .message {
                background-color: $c-grey-overlay;
            }

            .required-field-indicator {
                margin-bottom: 13px;
                text-align: right;

                p {
                    padding-bottom: 0;
                }
            }

            .contact-form-wrapper,
            .contact-cms-content {
                @include desktop() {
                    width: 50%;
                }
            }

            .top-text {
                margin-bottom: 50px;
            }

            .info-row {
                @include tablet() {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

        .info-column {
            padding: 0 30px;
            width: 100%;

            @include tablet() {
                flex: inherit;
                padding: 0 48px;
                width: 100% !important; // scss-lint:disable ImportantRule
            }

            @include desktop() {
                margin-left: -1px;
                padding: 0;
            }

            h1 {
                font-family: $f-lafayette148;
                font-size: 42px;
                font-weight: $f-weight-light;
                line-height: 34px;
                margin-bottom: 32px;
                text-transform: uppercase;

                @include tablet() {
                    font-size: 55px;
                    letter-spacing: 1.8px;
                    line-height: 44px;
                    margin-bottom: 31px;
                }

                @include desktop() {
                    margin-bottom: 30px;
                }

                &::first-line {
                    font-family: $f-perpetua-titling;
                    font-weight: $f-weight-light;
                    letter-spacing: 0;
                }
            }

            p {
                font-family: $f-lafayette148;
                font-size: 15px;
                font-weight: $f-weight-light;
                letter-spacing: 0;
                line-height: 27px;
                max-width: 421px;
                padding-bottom: 27px;

                @include tablet() {
                    max-width: 510px;
                }

                a {
                    font-size: 15px;
                }
            }

            .first-p {
                padding: 0 24px 25px 0;

                @include tablet() {
                    max-width: 560px;
                    padding-right: 0;
                }

                @include desktop() {
                    max-width: 475px;
                }
            }

            .p1 {
                padding-bottom: 35px;

                @include tablet() {
                    padding-bottom: 23px;
                }


                &.smallersize {
                    @include tablet() {
                        max-width: 600px;
                    }
                }
            }

            .email-us {
                width: 70%;

                @include tablet() {
                    width: 100%;
                }

                @include desktop() {
                    width: 65%;
                }
            }

            .p2 {
                padding-bottom: 0;
            }

            h3 {
                font-family: $f-lafayette148;
                font-size: 24px;
                font-weight: $f-weight-light;
                letter-spacing: 0;
                line-height: 27px;
                margin-bottom: 5px;
                text-transform: uppercase;
            }

            a {
                font-weight: 300;
            }

            .col-2 {
                width: 100% !important; // scss-lint:disable ImportantRule

                &:last-child {
                    margin-left: 0;
                }
            }

            [data-content-type="column-group"] {
                flex-direction: column;
            }
        }

        .col-2 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include tablet() {
                width: 50% !important; // scss-lint:disable ImportantRule
            }

            &:last-child {
                @include tablet() {
                    margin-left: 40px;
                }
            }
        }
    }
}
