// Variables
$sb-max-width: 1680px;
$lw-wrapper-coeff: .06;
$lw-wrapper-coeff-mobile: .11;
$lw-wrapper-s-coeff: .08;
$lw-wrapper-s-coeff-mobile: .15;

// Will be removed after global styling redesign
.page-main {
    max-width: $sb-max-width;
}

@mixin shape-90($calc-width, $width-coeff, $radius-coeff) {
    @include shape($calc-width, $width-coeff, $radius-coeff);

    .vertical {
        border-radius: calc(#{$calc-width} * #{$radius-coeff}) calc(#{$calc-width} * #{$radius-coeff}) 0;
        top: calc(-1 * #{$calc-width} * #{$width-coeff} * (3 / 4));
    }

    .horizontal {
        border-radius: 0 0 0 calc(#{$calc-width} * #{$radius-coeff});
        left: auto;
    }

    .corners {
        height: calc(#{$calc-width * $width-coeff} + (#{$calc-width * $radius-coeff} * 2));
        left: $calc-width * $width-coeff;
        top: -$calc-width * $radius-coeff;
        width: $calc-width * $radius-coeff;

        &::before,
        &::after {
            border-radius: $calc-width * $radius-coeff;
            box-shadow: 0 0 0 ($calc-width * $radius-coeff * 4);
            content: '';
            margin: -$calc-width * $radius-coeff;
            margin-left: 0;
            padding: $calc-width * $radius-coeff;
            position: absolute;
        }
    }
}

@mixin shape($calc-width, $width-coeff, $radius-coeff) {
    @include size(100%);
    position: absolute;
    pointer-events: none;
    z-index: 50;

    >div {
        position: absolute;
    }

    .vertical {
        border-radius: 0 0 0 calc(#{$calc-width} * #{$radius-coeff});
        position: absolute;
        top: auto;
        width: calc(#{$calc-width} * #{$width-coeff});
    }

    .horizontal {
        border-radius: calc(#{$calc-width} * #{$radius-coeff}) calc(#{$calc-width} * #{$radius-coeff}) 0;
        height: calc(#{$calc-width} * #{$width-coeff});
        left: calc(-1 * #{$calc-width} * #{$width-coeff} * (3 / 4));
        position: absolute;
    }

    .corners {
        height: $calc-width * $radius-coeff;
        left: -$calc-width * $radius-coeff;
        overflow: hidden;
        position: absolute;
        top: $calc-width * $width-coeff;
        width: calc(#{$calc-width * $width-coeff} + (#{$calc-width * $radius-coeff} * 2));

        &::before,
        &::after {
            border-radius: $calc-width * $radius-coeff;
            box-shadow: 0 0 0 ($calc-width * $radius-coeff * 4);
            content: '';
            margin: -$calc-width * $radius-coeff;
            margin-top: 0;
            padding: $calc-width * $radius-coeff;
            position: absolute;
        }

        &::after {
            bottom: 0;
            right: 0;
        }
    }
}

@mixin shape-wrapper-mobile($calc-width, $width-coeff) {
    @include shape-90($calc-width, $width-coeff, $width-coeff / 10);
    left: -$calc-width * $width-coeff;
    top: -$calc-width * $width-coeff;

    .horizontal {
        border-radius: 0 ($calc-width * $width-coeff / 10) 0 0;
        width: calc(50% + 100vw * #{$width-coeff});
    }

    .vertical {
        border-radius: 0 ($calc-width * $width-coeff / 10) 0 0;
        height: 45vw;
    }

    &.reverse {
        bottom: -$calc-width * $width-coeff;
        left: auto;
        right: -$calc-width * $width-coeff;
        top: auto;
        transform: scale(-1);
    }
}

@mixin shape-wrapper($calc-width, $width-coeff) {
    @include shape($calc-width, $width-coeff, $width-coeff / 10);
    left: -$calc-width * $width-coeff;
    top: -$calc-width * $width-coeff;

    .horizontal {
        border-radius: ($calc-width * $width-coeff / 10) ($calc-width * $width-coeff / 10) 0;
        width: $calc-width * .25;
    }

    .vertical {
        border-radius: 0 0 0 ($calc-width * $width-coeff / 10);
        height: calc(50% + 100vw * #{$width-coeff});
    }

    &.reverse {
        bottom: -$calc-width * $width-coeff;
        left: auto;
        right: -$calc-width * $width-coeff;
        top: auto;
        transform: scale(-1);
    }
}

@mixin shape-wrapper-small($calc-width, $width-coeff) {
    @include shape-90($calc-width, $width-coeff, $width-coeff / 10);
    left: -$calc-width * $width-coeff;
    top: -$calc-width * $width-coeff;

    .horizontal {
        border-radius: 0 ($calc-width * $width-coeff / 10) 0 0;
        width: calc(100% + (#{$calc-width * $width-coeff} * (3 / 4)));
    }

    .vertical {
        height: calc(100% + (#{$calc-width * $width-coeff} * (7 / 4)));
    }

    &.reverse {
        bottom: -$calc-width * $width-coeff;
        left: auto;
        right: -$calc-width * $width-coeff;
        top: auto;
        transform: scale(-1);
    }
}

@mixin video($calc-width, $lane-coeff, $ratio) {
    position: relative;
    width: calc(#{$calc-width} - (#{$calc-width} * 2 * #{$lane-coeff}));
    height: calc((#{$calc-width} - (#{$calc-width} * 2 * #{$lane-coeff})) / #{$ratio});
}

.video-storyblock {
    overflow: auto;
    position: relative;
    width: 100%;

    @include tablet {
        .video-mobile {
            display: none;
        }
    }

    iframe {
        @include size(100%);
    }

    .video-wrapper {
        @include video(100vw, 0, 1);
        width: 100%;

        @include tablet {
            @include video(100vw, 0, (16 / 9));
            max-width: 100%;
            width: 100%;
        }

        @include media($sb-max-width) {
            @include video($sb-max-width, 0, (16 / 9));
        }

        .video-thumbnail {
            @include size(100%);
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 0;

            img {
                @include size(100%);
            }
        }

        .video-button {
            @include size(60px);
            background: none;
            left: 50%;
            padding: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;

            @include tablet {
                @include size(6vw);
                max-height: $sb-max-width * .06;
                max-width: $sb-max-width * .06;
            }

            svg {
                height: 100%;
                width: 100%;

                .ring {
                    fill: none;
                    stroke: $c-white;
                    stroke-width: 2;
                }

                .triangle {
                    fill: $c-white;
                }
            }

            &:hover .ring {
                fill: $c-white-transparent;
                stroke-width: 3;
            }
        }

        &.video-played {
            .video-thumbnail,
            .video-button {
                display: none;
            }
        }
    }

    .video-desktop {
        display: none;

        @include tablet {
            display: block;
        }
    }

    &[data-appearance="wrapped"] {
        .video-wrapper {
            @include video(100vw, $lw-wrapper-coeff-mobile, (9 / 16));
            margin: calc(200vw * #{$lw-wrapper-coeff-mobile}) calc(100vw * #{$lw-wrapper-coeff-mobile});

            @include tablet {
                @include video(100vw, ($lw-wrapper-coeff * 2), (16 / 9));
                margin: calc(100vw * #{$lw-wrapper-coeff}) calc(200vw * #{$lw-wrapper-coeff});
            }

            @include media ($sb-max-width) {
                @include video($sb-max-width, ($lw-wrapper-coeff * 2), (16 / 9));
                margin: calc(#{$sb-max-width} * #{$lw-wrapper-coeff}) calc(#{$sb-max-width} * 2 * #{$lw-wrapper-coeff});
            }

            .l-shape {
                @include shape-wrapper-mobile(100vw, $lw-wrapper-coeff-mobile);

                @include tablet {
                    @include shape-wrapper(100vw, $lw-wrapper-coeff)
                }

                @include media($sb-max-width) {
                    @include shape-wrapper($sb-max-width, $lw-wrapper-coeff);
                }
            }
        }

        &.video-small {
            .video-wrapper {
                @include media(max-width $bp-tablet - 1px) {
                    @include video(100vw, $lw-wrapper-coeff-mobile, (1 / 1.26));
                }
            }
        }
    }

    &[data-appearance="wrapped-small"] {
        .video-mobile {
            display: none !important; // scss-lint:disable ImportantRule
        }

        .video-desktop {
            display: block !important; // scss-lint:disable ImportantRule
        }

        .video-wrapper {
            @include video(100vw, $lw-wrapper-s-coeff-mobile * 1.1, (16 / 9));
            margin: (200vw * $lw-wrapper-s-coeff-mobile) (100vw * $lw-wrapper-s-coeff-mobile * 1.1);

            @include tablet {
                @include video(100vw, (1 / 3), (16 / 9));
                margin: (200vw * $lw-wrapper-s-coeff) (100vw * (1 / 3));
            }

            @include media ($sb-max-width) {
                @include video($sb-max-width, (1 / 3), (16 / 9));
                margin: ($sb-max-width * 2 * $lw-wrapper-s-coeff) ($sb-max-width * (1 / 3));
            }

            .l-shape {
                @include shape-wrapper-small(100vw, $lw-wrapper-s-coeff-mobile);

                @include tablet {
                    @include shape-wrapper-small(100vw, $lw-wrapper-s-coeff);
                }

                @include media($sb-max-width) {
                    @include shape-wrapper-small($sb-max-width, $lw-wrapper-s-coeff);
                }
            }
        }
    }
}
