.unordinary {
    .style-advisors {
        margin-bottom: 38px;

        @include desktop() {
            margin-bottom: 98px;
            padding: 0 60px;
        }
    }

    .our-consultants-slider {
        .slick-list.draggable {
            background-color: $c-grey-unordinary-slider;

            hr {
                margin: 25px auto 30px;

                @include desktop() {
                    margin: 18px 0 25px;
                }
            }
        }
    }
}
