// Breakpoints
$bp-mobile: 375px;
$bp-tablet: 600px;
$bp-tablet-big: 700px;
$bp-tablet-breakpoint: 768px;
$bp-tablet-landscape: 900px;
$bp-desktop-sm: 1024px;
$bp-desktop: 1200px;
$bp-desktop-extra-big: 1440px;

$content-smaller-max-width: 1080px;
$content-medium-max-width: 1320px;
$content-average-max-width: 1440px;
$content-max-width: 1600px;
$storyblock-max-width: 1680px;
$header-max-width: 1440px;
$header-max-width-big: 1440px;
$product-grid-max-width: 1200px;
$footer-max-width: 1320px;
$sidebar-width: 285px;
$column-width: 990px;

// Colors
$c-white: #fff;
$c-white-transparent: rgba(255, 255, 255, .5);
$c-white-transparent-20: rgba(255, 255, 255, .2);
$c-white-transparent-overlay: rgba(255, 255, 255, .7);
$c-white-transparent-menu: rgba(255, 255, 255, .91);
$c-white-transparent-popup: rgba(255, 255, 255, .96);
$c-grey-transparent: rgba(231, 230, 228, .7);
$c-overlay-start: rgba(255, 255, 255, .0);
$c-overlay-end: rgba(255, 255, 255, 1);
$c-black: #000;
$c-dark: #232321;
$c-darker: #222;
$c-border-black: #343030;
$c-grayish-yellow: #b9b4a0;

$c-grey-background: #e7e6e4;
$c-grey-background-light: #f4f2f2;
$c-grey-background-enhanced: #e6e5e3;
$c-grey-survey-delimiter:#d8d8d7;
$c-grey-survey-question-delimiter:#c5c4c2;
$c-grey-light: #a5a5a5;
$c-grey-dark: #515151;
$c-grey-tapa: #71706f;
$c-grey-kelp: #3b3c38;
$c-grey-survey-text: #3c3c38;
$c-grey-main: #707070;
$c-grey-main-darker: #848484;
$c-grey-silver: #aaa6a5;
$c-grey-light-silver: #adacaa;
$c-grey-concrete: #f3f1f1;
$c-grey-quill: #cfcfcd;
$c-grey-light-border: #ccc;
$c-grey-lighter-border: #c6c6c4;
$c-grey-lightest-border: #c0bfbd;
$c-grey-darker-border: #d0cecd;
$c-grey-steel: #8f8f8d;
$c-grey-bleak: #b7b7b7;
$c-grey-overlay: #f7f7f7;
$c-grey-overlay-transparent: rgba(247, 247, 247, .85);
$c-grey-dark-overlay-transparent: rgba(247, 247, 247, .95);
$c-dusty-grey: #959595;
$c-grey-ash: #9e9e9e;
$c-grey-pearl: #93938c;
$c-grey-new-tooltip: #9c9c9b;
$c-grey-gunpowder: #6e6f70;
$c-grey-cms-background: #3e3e3e;
$c-grey-cms-background-darker: #e5e5e5;
$c-grey-mercury: #e4e4e4;
$c-grey-alto: #dbdbdb;
$c-grey-dove-gray: #6a6a6a;
$c-grey-gallery-light: #eee;
$c-grey-fuscous: #4e4b48;
$c-quickview-btn: #e6e3e2;
$c-checkout-notice: #f5f5f4;
$c-my-account-sidebar-grey: #93938c;
$c-my-account-border-grey: #c4c4c3;
$c-cotton-seed-grey: #b9b8b6;
$c-input-border: #989898;
$c-grey-gallery: #ebebeb;
$c-grey-nickle-approx: #c8c8c7;
$c-grey-gainsboro: #e7e7e7;
$c-gery-silver-border: #c2bebe;
$c-grey-unordinary-slider: #f4f4f4;
$c-grey-plaster: #efece5;
$c-grey-plaster-hover: #f7f5f0;
$c-alabaster: #f8f8f8;
$c-concrete: #f2f2f2;
$c-alabaster-solid: #fafafa;
$c-grey-newsletter: #f0f0f0;
$c-alto: #d0d0d0;
$c-silver-chalice: #adadad;
$c-tapa: #76726e;
$c-gallery: #efefef;

$c-brown: #3c3c38;
$c-brown-darker: #3b3b38;
$c-brown-lighter: #3a3a38;
$c-brown-light: #b5aea5;
$c-brown-transparent: rgba(138, 128, 116, .6);
$c-brown-logo: #231f20;
$c-brown-table: #cfccc8;
$c-tundura: #484848;
$c-tapa-other: #76726e;

$c-violet-light: #e3dfdf;
$c-red: #b8130e;
$c-thunderbird: #d41710;
$c-red-crimson: #ed1c16;
$c-persian-red: #cc3635;
$c-flamingo: #f24343;
$c-golden: #b9b39a;
$c-mexican-red: #9d292a;
$c-milano-red: #c50e0f;
$c-paarl: #a45a2a;
$c-raw-sienna: #c77742;
$c-red-other: #f00;

$c-floral-white: #faf7f3;
$c-gold: #f1c16f;

$c-box-shadow: rgba(4, 6, 18, .16);
$c-dropdown-box-shadow: rgba(38, 38, 36, .4);
$c-dropdown-box-shadow-lighter: rgba(50, 50, 50, .4);
$c-enhanced-hero-block-box-shadow-color: rgba(50, 50, 50, .16);
$c-video-overlay: rgba(0, 0, 0, .4);
$c-overlay-box-shadow: rgba(0, 0, 0, .1);
$c-size-submit-bg-color: #3c3d37;
$c-store-slide-shadow-black: rgba(0, 0, 0, .75);
$c-store-slide-white-full-transparent: rgba(255, 255, 255, 0);
$c-store-slide-white-transparent: rgba(255, 255, 255, .5);
$c-popup-white-transparent: rgba(255, 255, 255, .9);
$c-store-slide-gradient: linear-gradient(to right, $c-store-slide-white-full-transparent 0%, $c-store-slide-white-transparent 100%);

$c-success: #008000;
$c-notice: #ffa500;
$c-unordinary-title: #fbbf5f;
$c-unordinary-background: #fbf8f3;
$c-unordinary-conversation-background: #f5f5f5;
$c-notice-background: rgba(255, 166, 0, .1);

$c-grey-overlay-start: rgba(231, 230, 228, 0);
$c-grey-overlay-end: rgba(231, 230, 228, .8);

$c-swatch-inactive: #bbbbba;

// Fonts
$f-fontawesome: 'FontAwesome';

$f-weight-light: 100;
$f-weight-semi-base: 200;
$f-weight-base: 400;
$f-weight-light: 300;
$f-weight-semi-bold: 500;
$f-weight-semi-bolder: 600;
$f-weight-bold: 700;
$f-weight-bolder: 900;

$f-size-xxxs: 10px;
$f-size-xxs: 11px;
$f-size-base: 12px;
$f-size-xs: 13px;
$f-size-s: 14px;
$f-size-m: 15px;
$f-size-m-large: 16px;
$f-size-m-larger: 18px;
$f-size-l-small: 24px;
$f-size-l: 24px;
$f-size-l-larger: 26px;
$f-size-ml: 30px;
$f-size-xl: 36px;
$f-size-xl-mobile: 34px;
$f-size-xxl-mobile: 30px;
$f-size-xxml-mobile: 42px;
$f-size-xxl: 60px;
$f-size-xxxl-mobile: 50px;
$f-size-xxxml-mobile: 55px;
$f-size-xxxl: 75px;
$f-size-xl-tablet: 62px;
$f-size-xxl-tablet: 72px;
$f-size-xxl-small-desktops: 45px;
$f-size-xxxl-desktops: 48px;

$letter-spacing-base: normal;
$letter-spacing-xxs: .4px;
$letter-spacing-xs: .5px;
$letter-spacing-s: .6px;
$letter-spacing-sm: .7px;
$letter-spacing-m: 1px;
$letter-spacing-ml: 1.4px;
$letter-spacing-l: 1.8px;
$letter-spacing-xl: 2.3px;
$letter-spacing-xxl: 2.4px;

$line-height-base: normal;
$line-height-xxxs: .5;
$line-height-xxs: 1.1;
$line-height-xs: 1.25;
$line-height-s: 1.5;
$line-height-sm: 1.8;
$line-height-m: 2.08;
$line-height-ml: 2.17;
$line-height-l: 2.92;
$line-height-xl: 3.33;
$line-height-xxl: 3.44;
$line-height-xxxl: 4.17;
$line-height-xxxxl: 4.58;

// Spacing
$content-spacing-base: 10px;
$content-spacing-xs: 5px;
$content-spacing-m: 15px;
$content-spacing-l: 20px;
$content-spacing-xl: 25px;
$content-spacing-xxl: 30px;

// Font Family - Freight Big Pro
@include font-face('FreightBigPro', '../fonts/FreightBigPro/FreightBigProBlack-Regular', normal, normal, $file-formats: eot woff woff2 ttf);
@include font-face('FreightBigPro', '../fonts/FreightBigPro/FreightBigProBlack-Italic', normal, italic, $file-formats: eot woff woff2 ttf);
@include font-face('FreightBigPro', '../fonts/FreightBigPro/FreightBigProBold-Regular', 700, normal, $file-formats: eot woff woff2 ttf);
@include font-face('FreightBigPro', '../fonts/FreightBigPro/FreightBigProBold-Italic', 700, italic, $file-formats: eot woff woff2 ttf);
@include font-face('FreightBigPro', '../fonts/FreightBigPro/FreightBigProBook-Regular', normal, normal, $file-formats: eot woff woff2 ttf);
@include font-face('FreightBigPro', '../fonts/FreightBigPro/FreightBigProBook-Italic', normal, italic, $file-formats: eot woff woff2 ttf);
@include font-face('FreightBigPro', '../fonts/FreightBigPro/FreightBigProLight-Regular', 300, normal, $file-formats: eot woff woff2 ttf);
@include font-face('FreightBigPro', '../fonts/FreightBigPro/FreightBigProLight-Italic', 300, italic, $file-formats: eot woff woff2 ttf);
@include font-face('FreightBigPro', '../fonts/FreightBigPro/FreightBigProMedium-Regular', 500, normal, $file-formats: eot woff woff2 ttf);
@include font-face('FreightBigPro', '../fonts/FreightBigPro/FreightBigProMedium-Italic', 500, italic, $file-formats: eot woff woff2 ttf);
@include font-face('FreightBigPro', '../fonts/FreightBigPro/FreightBigProSemibold-Regular', 600, normal, $file-formats: eot woff woff2 ttf);
@include font-face('FreightBigPro', '../fonts/FreightBigPro/FreightBigProSemibold-Italic', 600, italic, $file-formats: eot woff woff2 ttf);

$f-freight: 'FreightBigPro', 'Arial', sans-serif;

// Font Family - Lineto Akkurat
@include font-face('Lineto Akkurat', '../fonts/LinetoAkkurat/LinetoAkkurat-Bold', 700, normal, $file-formats: eot woff woff2);
@include font-face('Lineto Akkurat', '../fonts/LinetoAkkurat/LinetoAkkurat-BoldItalic', 700, italic, $file-formats: eot woff woff2);
@include font-face('Lineto Akkurat', '../fonts/LinetoAkkurat/LinetoAkkurat-Italic', normal, italic, $file-formats: eot woff woff2);
@include font-face('Lineto Akkurat', '../fonts/LinetoAkkurat/LinetoAkkurat-Light', 300, normal, $file-formats: eot woff woff2);
@include font-face('Lineto Akkurat', '../fonts/LinetoAkkurat/LinetoAkkurat-LightItalic', 300, italic, $file-formats: eot woff woff2);
@include font-face('Lineto Akkurat', '../fonts/LinetoAkkurat/LinetoAkkurat-Regular', normal, normal, $file-formats: eot woff woff2);

$f-lineto-akkurat: 'Lineto Akkurat', 'Arial', sans-serif;

// Font Family - Lafayette148
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-Bold', 700, normal, $file-formats: eot otf ttf woff woff2);
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-BoldItalic', 700, italic, $file-formats: eot otf ttf woff woff2);
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-Light', 300, normal, $file-formats: eot otf ttf woff woff2);
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-LightItalic', 300, italic, $file-formats: eot otf ttf woff woff2);
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-Medium', 500, normal, $file-formats: eot otf ttf woff woff2);
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-MediumItalic', 500, italic, $file-formats: eot otf ttf woff woff2);
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-Regular', normal, normal, $file-formats: eot otf ttf woff woff2);
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-RegularItalic', normal, italic, $file-formats: eot otf ttf woff woff2);

$f-lafayette148: 'Lafayette148', 'Arial', sans-serif;

// Font Family - Perpetua Titling MT
//@include font-face('PerpetuaTitlingMT', '../fonts/PerpetuaTitlingMT/PerpetuaTitlingMT-Light', 300, normal, $file-formats: ttf woff woff2);

@font-face {
    font-family: 'PerpetuaTitlingMT';
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    unicode-range: U+21,U+24-26,U+28-2A,U+2C-3B,U+3F,U+41-5A,U+61-7A;
    src: local('PerpetuaTitlingMT'),
         url('../fonts/PerpetuaTitlingMT/PerpetuaTitlingMT-Light.ttf') format('ttf'),
         url('../fonts/PerpetuaTitlingMT/PerpetuaTitlingMT-Light.woff') format('woff'),
         url('../fonts/PerpetuaTitlingMT/PerpetuaTitlingMT-Light.woff2') format('woff2');
}

$f-perpetua-titling: 'PerpetuaTitlingMT', 'Lafayette148', 'Times', serif;

// Typography
$base-font-family: $f-lafayette148;
$heading-font-family: $f-perpetua-titling;
$base-font-weight: $f-weight-base;
$base-font-size: $f-size-base;
$base-font-color: $c-black;

// z-indexes
$z-main-content: 100;
$z-header: 101;
$z-header-mobile-search: 200;
$z-sticky-header: 300;
$z-modals: 400;
$z-over-modals: 500;

// Temporary fonts

// Font Family - Lafayette148
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-Bold', 700, normal, $file-formats: eot otf ttf woff woff2);
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-BoldItalic', 700, italic, $file-formats: eot otf ttf woff woff2);
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-Light', 300, normal, $file-formats: eot otf ttf woff woff2);
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-LightItalic', 300, italic, $file-formats: eot otf ttf woff woff2);
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-Medium', 500, normal, $file-formats: eot otf ttf woff woff2);
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-MediumItalic', 500, italic, $file-formats: eot otf ttf woff woff2);
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-Regular', normal, normal, $file-formats: eot otf ttf woff woff2);
@include font-face('Lafayette148', '../fonts/Lafayette148/Lafayette148-RegularItalic', normal, italic, $file-formats: eot otf ttf woff woff2);

$f-lafayette148: 'Lafayette148', 'Arial', sans-serif;
