// Promotional Message Popup
.promo-message-wrapper {
    background: $c-grey-overlay;
    max-height: 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    transition: max-height 1s ease-in .5s;
    z-index: 500;

    &.shown {
        max-height: 100px;
    }

    .promo-message-container {
        margin: 0 auto;
        padding: 16px $content-spacing-xxl 9px;
        position: relative;

        @include tablet() {
            max-width: 1250px;
        }

        @include desktop() {
            padding: 17px $content-spacing-xxl;
        }

        .promo-message {
            opacity: .7;

            @include desktop() {
                opacity: 1;
            }

            p,
            a {
                color: $c-black;
                display: inline;
                font-size: $f-size-xs;
                line-height: $line-height-xs;
                padding: 0;

                @include desktop() {
                    color: $c-brown-darker;
                    font-size: $f-size-base;
                }
            }

            .slick-prev:before,
            [dir='rtl'] .slick-prev:before,
            .slick-next:before,
            [dir='rtl'] .slick-next:before {
                content: '';
            }            

            .slick-prev {
                background: url('../images/slider-arrow-prev.png') 0 0 no-repeat;
                background-size: 20px;
            }

            .slick-next {
                background: url('../images/slider-arrow-next.png') 0 0 no-repeat;
                background-size: 20px;
            }
        }

        .promo-close {
            background: url('../images/icon-close.svg') 2px 2px no-repeat;
            background-size: 17px;
            cursor: pointer;
            height: 17px;
            margin-top: -5px;
            position: absolute;
            right: 9px;
            top: 50%;
            width: 17px;

            @include desktop() {
                background-position: 4px 4px;
                background-size: 30px;
                height: 30px;
                margin-top: -$content-spacing-m;
                right: 3px;
                width: 30px;
            }

            .close-button {
                display: none;
            }
        }
    }
}
