.unordinary {
    // scss-lint:disable ImportantRule NestingDepth
    .service-page-help {
        &.uw-story {
            display: block !important;
            justify-content: center;
            margin: 0 !important;
            max-width: 100vw;
            padding: 0 0 43px;

            @include tablet {
                padding-bottom: 38px;
            }

            @include desktop {
                padding-bottom: 5px;
            }

            @include desktop-extra-big {
                margin: 0 auto !important;
                max-width: 1680px;
            }

            .pagebuilder-column-group {
                display: block !important;
                margin: 0;
            }

            h2 {
                font-size: 40px;
                letter-spacing: $letter-spacing-xs;
                margin-bottom: 35px;

                @include mobile() {
                    font-size: 40px;
                }

                @include desktop() {
                    font-size: $f-size-xxl;
                }
            }

            h3 {
                font-family: $heading-font-family;
                font-size: $f-size-l;
                font-weight: $f-weight-light;
            }

            p {
                font-weight: $f-weight-light;
                line-height: 1.8;
                padding-bottom: 0;
            }

            .column-width-4 {
                display: block !important;
                margin: 0 3px;
                max-width: none;
                width: auto !important;

                @include tablet {
                    margin: 0 7px;
                }

                [data-content-type="text"] {
                    margin-top: 20px;

                    &.hide-on-desktop {
                        display: none !important;
                    }
                }

                p {
                    font-size: $f-size-base;
                    line-height: $line-height-s;
                }

                h3 {
                    letter-spacing: $letter-spacing-sm;
                    margin-bottom: 0;
                }
            }

            .top-text {
                margin-bottom: 60px;

                @include desktop() {
                    align-items: center;
                }

                [data-content-type="text"] {
                    margin: 0 auto;
                    max-width: 750px;
                }
            }

            .bottom-text {
                @include desktop() {
                    margin-top: 23px;
                }

                p {
                    font-size: $f-size-base;
                    line-height: normal;
                }
            }

            [data-content-type="buttons"] {
                margin: 0 auto;
                max-width: 240px;

                [data-content-type="button-item"] {
                    margin-bottom: 20px;
                }
            }

            .slick-list {
                @media screen and (max-width: 599px) {
                    padding: 0 16% 0 0 !important;
                }
            }

            .mask {
                &.hide-on-mobile {
                    display: none !important;
                }
            }

            .slick-slider {
                * {
                    min-height: 0;
                    min-width: 0;
                }
            }
        }

        .pagebuilder-column {
            &.top-text {
                margin-bottom: 64px;

                @include tablet {
                    margin-bottom: 73px;
                }
            }
        }

        &.top-color-block {
            margin-top: 0 !important;

            @include desktop-extra-big {
                margin: 0 auto;
                max-width: 1680px !important;
            }

            .top-text {
                padding-top: 109px;

                @include tablet {
                    margin-bottom: 99px;
                    padding-top: 112px;
                }

                @include desktop {
                    margin-bottom: 83px;
                    padding-top: 155px;
                }

                h1 {
                    font-family: $f-freight;
                    margin-bottom: 30px !important;

                    @include tablet {
                        margin: 0 auto 34px !important;
                        max-width: 510px;
                    }

                    @include desktop {
                        margin-bottom: 27px !important;
                        max-width: 595px;
                    }

                    &,
                    * {
                        font-size: 45px !important;

                        @include tablet {
                            font-size: $f-size-xxl-tablet !important;
                        }

                        @include desktop {
                            font-size: 88px !important;
                        }
                    }
                }

                p {
                    &:first-of-type {
                        font-family: $f-perpetua-titling !important;
                        font-size: 17px;
                        line-height: 25px;
                        margin: 0 0 30px !important;
                        overflow: hidden;

                        @include tablet {
                            font-size: $f-size-m-larger;
                            margin: 0 auto 24px !important;
                            max-width: 545px;
                        }

                        @include desktop {
                            font-size: 21px;
                            margin: 0 auto 12px !important;
                            max-width: 870px;
                        }
                    }

                    &:last-of-type {
                        margin: 0 20px 72px !important;

                        @include tablet {
                            margin: 0 auto 104px !important;
                            max-width: 635px;
                        }

                        @include desktop {
                            margin: 0 auto 147px !important;
                            max-width: 872px;
                        }

                        &,
                        * {
                            font-family: $f-lafayette148 !important;
                            font-size: $f-size-m;
                            font-weight: $f-weight-light !important;
                            line-height: 27px;
                        }
                    }
                }

                > div {
                    max-width: none;
                    padding: 0 25px;

                    @include desktop {
                        max-width: 920px;
                    }
                }
            }

            > .pagebuilder-column-group {
                @include desktop {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            &:not(.uw-story) {
                @include tablet {
                    max-width: none;
                    padding: 0;
                }
            }
        }
    }

    .uw-slider-title-block {
        @include tablet {
            display: flex !important;
        }

        @include desktop-extra-big {
            margin: 0 auto;
            max-width: 1680px;
        }

        h1 {
            font-size: 37px !important;
            line-height: 44px;
            margin: 0 40px 9px;
            max-width: 635px;

            @include tablet {
                margin: 0 auto 12px;
                max-width: none;
            }

            @include desktop {
                font-size: 55px !important;
                margin: 53px auto 12px;
            }
        }

        p {
            font-size: 15px !important;
            line-height: 27px;
            margin: 0 auto;
            max-width: 555px;
            padding: 0 30px 39px;

            @include tablet {
                padding: 0 48px 12px;
            }

            @include desktop {
                max-width: 980px;
                padding: 0 0 39px;
            }
        }
    }
    // scss-lint:enable ImportantRule NestingDepth
}
