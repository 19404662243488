.home-page {
    @import 'heading-mixin';
    @import 'home-hero';
    @import 'home-img-overlap';
    @import 'home-product';
    @import 'home-fw-image';
    @import 'home-product';
    @import 'home-video';
    @import 'home-banner';

    margin: 0 auto;

    h2 {
        font-size: $f-size-xxxl-mobile;
    }

    .before-promo [data-content-type="row"] {
        margin-bottom: 0;
    }

    &.hide-on-desktop {
        display: block !important; // scss-lint:disable ImportantRule

        @include desktop() {
            display: none !important; // scss-lint:disable ImportantRule
        }
    }

    &.hide-on-mobile {
        display: none !important; // scss-lint:disable ImportantRule

        @include desktop() {
            display: block !important; // scss-lint:disable ImportantRule
        }
    }
}
