.cms-our-path,
.cms-our-process,
.cms-deirdre-quinn,
.cms-ida-siu,
.cms-services,
.cms-social-responsibility,
.cms-emily-smith {

    [data-content-type="row"] {
        max-width: $storyblock-max-width !important; // scss-lint:disable ImportantRule
    }

    .l-shape-block {
        margin-bottom: 18vw;

        @include tablet() {
            margin-bottom: 0;
        }
    }

    .text-storyblock {
        max-width: 100%;

        .text-title {
            margin: 0 0 11px;

            @include tablet() {
                margin: 0 0 40px;
            }
        }
    }

    .quote-storyblock,
    .video-storyblock {
        margin-bottom: 18vw;

        @include tablet() {
            margin-bottom: 14vw;
        }

        @include desktop() {
            margin-bottom: 0;
        }
    }

    .tile-storyblock.shifted {
        .pagebuilder-column-group:nth-of-type(2) {
            .pagebuilder-column:first-of-type {
                @include desktop() {
                    margin-bottom: 0;
                }
            }
        }
    }


    .caption {
        &-desktop {
            display: none !important; // scss-lint:disable ImportantRule

            @include desktop() {
                display: inline-block !important; // scss-lint:disable ImportantRule
            }
        }

        &-tablet {
            display: none !important; // scss-lint:disable ImportantRule

            @include tablet() {
                display: inline-block !important; // scss-lint:disable ImportantRule
            }
        }
    }
}

.cms-deirdre-quinn,
.cms-ida-siu,
.cms-emily-smith {
    .tile-storyblock {
        padding-top: 40px;
    }

    .text-storyblock {
        @include desktop() {
            padding: 0 10vw !important; // scss-lint:disable ImportantRule
        }

        @include media($storyblock-max-width) {
            padding: 0 168px;
        }
    }
}
