// Bottom newsletter modal
.bottom-popup {
    .promo-modal {
        >p {
            padding: 0;
        }
    }

    .modal-header {
        display: none;
    }
}

.bottom-popup .newsletter {
    margin: 0 auto;
    max-width: 1050px;

    &.step-by-step {
        @include desktop() {
            position: relative;
        }
    }

    .actionClose {
        background: url(../images/icon-close-bold.svg) 0 0 no-repeat;
        background-size: 21px;
        cursor: pointer;
        height: 21px;
        position: absolute;
        right: 28px;
        top: 20px;
        width: 21px;

        @include desktop() {
            right: 0;
            top: 0;
        }
    }
    
    .cms-block {        
        p {
            br {
                @include desktop() {
                    display: initial;
                }
            }
        }
    }
    
    .step-1.block {
        display: flex;
        flex-direction: column;

        p, h2 {
            @include desktop() {
                text-align: left;
            }
        }

        .newsletter {
            margin: 0;
        }

        @include desktop() {
            flex-direction: row;
        }

        .form {
            display: flex;
            flex-wrap: wrap;
        }

        .content,
        .cms-block {
            @include desktop() {
                margin: 0 35px;
                max-width: 505px;
                width: 50%;
            }
        }

        .cms-block {
            @include desktop() {
                display: flex;
                justify-content: flex-end;
                align-self: center;
            }
        }

        .email, .firstname, .lastname {
            display: none;
        }

        .street,
        .apartment {
            width: 100%;

            @include desktop() {
                width: calc(50% - 5px);
            }
        }

        .street {
            @include desktop() {
                margin-right: 10px;
            }
        }

        .city {
            width: calc(70% - 10px);
            margin-right: 10px;

            @include desktop() {
                width: calc(50% - 5px);
                margin-right: 10px;
            }
        }

        .region {
            width: 30%;

            @include desktop() {
                width: 20%;
                margin-right: 10px;
            }

            select[name=state] {
                border-color: #adadad;
                padding-right: 20px;
                height: 45px;
            }

            .chosen-container {
                .chosen-single {
                    >span {
                        margin-right: 0;
                        width: calc(100% - 30px);
                    }

                    div {
                        width: 10px;
                        right: 10px;
                    }
                }
            }
        }

        .zip {
            width: 100%;

            @include desktop() {
                width: calc(30% - 15px);
            }
        }

        .field,
        select,
        .chosen-container {
            font-weight: $f-weight-bold;
            max-width: none;
            text-align: left;

            .chosen-drop {
                bottom: 100%;
                top: auto;
                width: auto;
            }
        }

        .actions {
            width: 70%;
            margin: 20px auto 10px;

            @include desktop() {
                width: calc(50% - 5px);
                margin: 0;
            }
        }

        .skip {
            background: none;
            color: #3c3c39;
            font-weight: $f-weight-bold;
            text-decoration: underline;
            text-transform: capitalize;
            width: auto;
            padding: 0;
            margin-bottom: 10px;
            height: auto;
            align-self: flex-end;
            line-height: 1;

            @include desktop() {
                margin-left: 20px;
                margin-bottom: 0;               
            }
        }
    }

    .step-2.block {
        @include desktop() {
            padding: 34px 0 5px 0;
        }

        h2 {
            width: 200px;
            margin-left: auto;
            margin-right: auto;

            @include desktop() {
                width: auto;
            }
        }
    }

    .step-3 {
        &.block {
            display: flex;
            flex-direction: column;

            @include desktop() {
                flex-direction: row;
            }

            p, h2 {
                @include desktop() {
                    text-align: left;
                }
            }

            .form {
                display: flex;
                flex-wrap: wrap;
            }

            .content,
            .cms-block {
                @include desktop() {
                    margin: 0 35px;
                    max-width: 505px;
                    width: 50%;
                }
            }

            .cms-block {
                @include desktop() {
                    display: flex;
                    justify-content: flex-end;
                    align-self: center;
                }

                p:first-child {
                    font-style: italic;
                    padding-bottom: 0;
                    width: 200px;
                    word-break: break-word;
                    margin: 0 auto;

                    @include desktop() {
                        width: auto;
                    }
                }
            }

            .firstname,
            .lastname,
            .birthday-month,
            .email {
                width: 100%;

                @include desktop() {
                    width: calc(50% - 5px);
                }
            }

            .firstname,
            .email {
                @include desktop() {
                    margin-right: 10px;
                }
            }

            .field,
            select,
            .chosen-container {
                font-weight: $f-weight-bold;
                max-width: none;
                text-align: left;

                .chosen-drop {
                    bottom: 100%;
                    top: auto;
                }
            }

            .actions {
                width: 70%;
                margin: 20px auto 10px;

                @include desktop() {
                    width: calc(50% - 5px);
                    margin: 0;
                }
            }
        }
    }

    .require_tips {
        width: 100%;
        margin-top: 12px;
        color: #3c3c39;
        font-style: italic;

        @include desktop() {
            text-align: left;
        }
    }

    select {
        color: #3c3c38;
        &.mage-error {
            border: 1px solid #b8130e;
            color: #b8130e;

            +.chosen-container {
                .chosen-single {
                    border: 1px solid #b8130e;

                    >span {
                        color: #b8130e;
                    }
                }
            }
        }
    }

    .chosen-container+.mage-error {
        color: #b8130e;
        margin-top: 10px;
    }

    br {
        display: none;
    }

    h2 {
        font-size: $f-size-xl;
        margin-top: 16px;
        padding: 0 20px;

        @include desktop() {
            font-size: $f-size-ml;
            padding: 0;
        }
    }

    p {
        line-height: $line-height-s;
        color: #000;
    }

    input {
        width: 100%;
        background-color: $c-white;
    }

    .chosen-container {
        .chosen-single {
            background-color: $c-white;
        }
        .chosen-drop {
            background: $c-white;
        }
    }
}
