// General Typography elements
body {
    color: $base-font-color;
    font-family: $base-font-family;
    font-size: $base-font-size;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: $f-weight-base;
    line-height: $line-height-base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $base-font-color;
    display: block;
    font-family: $heading-font-family;
    font-weight: $f-weight-base;
    margin: 0 0 $content-spacing-base;
}

h1 {
    font-size: $f-size-xxxl-mobile;
    letter-spacing: $letter-spacing-s;
    line-height: $line-height-xxs;
    margin: 0 0 30px;

    @include tablet() {
        font-size: $f-size-xxxl;
        margin-bottom: 60px;
    }
}

h2 {
    font-size: $f-size-xxl-mobile;
    letter-spacing: $letter-spacing-ml;
    line-height: $line-height-xxs;

    @include tablet() {
        font-size: $f-size-xxl;
    }
}

h3 {
    color: $c-black;
    font-size: $f-size-l;
    letter-spacing: $letter-spacing-xxs;
    line-height: $line-height-s;
}

h4 {
    font-family: $f-lafayette148;
    font-size: $f-size-m;
    font-weight: $f-weight-bold;
    line-height: $line-height-sm;
    margin: 0;
}

p {
    font-size: $f-size-m;
    line-height: $line-height-sm;
    margin: 0;
    padding: 0 0 $content-spacing-m;

    a {
        font-size: $f-size-s;
    }
}

strong {
    font-weight: $f-weight-bold;
}

a,
%hyper-link {
    color: $base-font-color;
    font-size: $f-size-base;
    font-weight: $f-weight-bold;
    line-height: $line-height-m;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
