.unordinary {
    // scss-lint:disable ImportantRule NestingDepth SelectorDepth
    .uw-story-row {
        .block-static-block {
            p:empty {
                padding: 0;
            }
        }

        .slick-arrow {
            &.slick-prev,
            &.slick-next {
                display: none !important;
            }
        }

        &.slick-slider {
            margin-bottom: 100px;

            @include mobile {
                margin-bottom: 63px;
            }
        }

        .slick-slide {
            transition: height 50ms;

            > div {
                > .pagebuilder-column-group {
                    > .pagebuilder-column {
                        display: block !important;
                        padding-top: 12px;

                        > div {
                            &:nth-last-child(2) {
                                .product-quote {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .expand-uw,
        .collapse-uw {
            font-size: $f-size-m;
            font-weight: 300;
            text-decoration: underline;
        }

        .white-shadow {
            position: relative;
        }

        .white-shadow:after {
            background: linear-gradient(to bottom, $c-store-slide-white-full-transparent, $c-overlay-end 100%);
            bottom: 100%;
            content: '';
            display: inline-block;
            height: 90px;
            position: absolute;
            right: 0;
            width: 100%;
        }

        .color-block {
            margin-bottom: 6px;
            margin-top: 70px;

            @include tablet {
                margin-top: 28px;
                width: calc(100% + 60px);
            }

            @include desktop {
                width: 100%;
            }

            @include desktop {
                margin-top: 43px;
            }

            .quote {
                &.hide-on-mobile {
                    p {
                        @include tablet {
                            line-height: 1.5 !important;
                        }

                        @include desktop {
                            line-height: 1.3 !important;
                        }

                        &:last-of-type {
                            @include desktop {
                                line-height: .5 !important;
                            }
                        }

                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }

        .product-quote {
            &.mb-m-0 {
                @include desktop {
                    margin-bottom: 51px !important;
                }

                .bf-product {
                    @include tablet {
                        flex-direction: row !important;
                        padding-bottom: 47px;
                    }

                    @include desktop {
                        flex-direction: column !important;
                    }

                    p {
                        span {
                            color: $c-black;
                            font-family: $f-lafayette148;
                            font-size: $f-size-m-larger;
                            font-weight: $f-weight-light;
                        }
                    }

                    [data-content-type="image"] {
                        padding-bottom: 63px;

                        @include tablet {
                            padding-bottom: 0;
                            width: 50%;
                        }

                        @include desktop {
                            padding-bottom: 48px;
                            width: 100%;
                        }
                    }

                    .hide-on-desktop {
                        @include tablet {
                            align-self: flex-end;
                            display: flex !important;
                            flex-direction: column;
                            margin-top: 0;
                            padding-left: 20px;
                            width: 50%;
                        }

                        @include desktop {
                            display: none !important;
                        }

                        p {
                            letter-spacing: -.5px !important;
                            line-height: 1 !important;
                            margin-bottom: 13px;
                            margin-top: 0 !important;

                            @include tablet {
                                letter-spacing: .5px !important;
                                margin-bottom: -10px;
                                margin-top: 2px !important;
                            }

                            span {
                                line-height: 24px;

                                @include tablet {
                                    line-height: 24px;
                                }

                                @include desktop {
                                    line-height: 27px;
                                }
                            }
                        }

                        h3 {
                            margin-bottom: 6px;

                            @include tablet {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .hide-on-mobile {
                        p {
                            @include desktop {
                                letter-spacing: 0 !important;
                                margin-top: 0 !important;
                            }
                        }

                        h3 {
                            @include desktop {
                                margin-bottom: 5px;
                            }
                        }
                    }
                }

                .pagebuilder-column.heading {
                    @include tablet {
                        column-count: 2;
                        column-gap: 40px;
                        display: inline-block !important;
                    }

                    .hide-on-mobile {
                        @include tablet {
                            break-inside: avoid-column;
                            display: block !important;
                            overflow: hidden;
                            width: 100%;
                        }

                        p {
                            color: $c-black;

                            @include tablet {
                                max-width: 100% !important;
                            }

                            a {
                                display: block;
                                font-weight: $f-weight-light;
                                text-decoration: underline;
                            }
                        }
                    }

                    .hide-on-desktop {
                        @include tablet {
                            display: none !important;
                        }

                        p {
                            color: $c-black;

                            a {
                                display: block;
                                font-size: $f-size-m;
                                font-weight: $f-weight-light;
                                text-decoration: underline;
                            }
                        }
                    }
                }

                > .pagebuilder-column-group {
                    @include mobile {
                        max-width: 100%;
                    }

                    @include tablet {
                        max-width: 100%;
                    }

                    &:first-of-type {
                        @include desktop {
                            align-self: flex-start;
                            margin-top: 29px;
                            max-width: 37%;
                        }
                    }

                    &:nth-of-type(2) {
                        @include desktop {
                            margin-left: 0;
                            max-width: 63%;
                        }
                    }
                }
            }
        }

        .color-block {
            background-color: $c-unordinary-title !important;
            padding: 20px 33px 25px;

            .quote {
                span {
                    font-family: $f-lafayette148 !important;
                }

                h5 {
                    font-weight: $f-weight-light !important;
                    text-align: left !important;
                }
            }
        }

        .next-bio-button-container {
            text-align: right;

            @include tablet {
                width: calc(100% + 50px);
            }

            @include desktop {
                width: 100%;
            }

            .next-bio-button {
                background: none;
                color: $c-black;
                font-weight: $f-weight-light;
                height: auto;
                line-height: 1;
                margin-bottom: 14px;
                margin-top: 8px;
                padding: 0;
                text-align: right;
                text-decoration: underline;
                width: auto;

                @include desktop {
                    margin-top: 10px;
                }
            }
        }

        .bf-video.wrapped {
            @include tablet {
                padding: 60px 0 43px;
            }

            @include desktop {
                margin: 0 auto;
                max-width: 1440px;
                padding: 0 120px 106px;
            }

            .video-wrapper {
                @media screen and (max-width: 599px) {
                    height: 122.66vw !important;
                    width: 100% !important;
                }

                @include desktop {
                    height: calc((100vw - 240px) / 1.77778) !important;
                }

                @include desktop-extra-big {
                    height: calc((1440px - 240px) / 1.77778) !important;
                }
            }
        }
    }

    .bf-product {
        > div {
            min-height: 1px;
        }

        h3 {
            span {
                color: $c-black;
                display: block;
                font-family: $f-lafayette148;
                font-size: 37px !important;
                letter-spacing: 0;
                line-height: 30px;
                margin-bottom: 9px;

                @include tablet {
                    letter-spacing: 0;
                    margin-bottom: 15px;
                }

                @include desktop {
                    font-size: 55px !important;
                    letter-spacing: 0;
                    line-height: 44px;
                    margin-bottom: 12px;
                }

                &::first-line {
                    font-family: $f-perpetua-titling;
                    letter-spacing: 0;
                }
            }
        }
    }
    // scss-lint:enable ImportantRule NestingDepth SelectorDepth
}
