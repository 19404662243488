.bf-product-container {
    margin: 0 30px;

    @include media(1155px) {
        justify-content: space-between;
    }

    @include desktop() {
        margin: 0 auto 75px;
        max-width: 1200px;
    }

    [data-content-type="column-group"]:nth-child(2n) {
        @include heading-right() {
            &.vertical-center {
                @include tablet() {
                    top: 55%;
                }
            }

            &.bottom {
                @include tablet() {
                    bottom: 8%;
                }
            }
        }
    }

    [data-content-type="column-group"]:only-child {
        @include media(1155px) {
            margin: 0 auto;
        }
    }

    [data-content-type="column-group"] {
        margin-bottom: 75px;
        padding: 0;
        position: relative;
        width: auto;

        @include desktop() {
            margin-bottom: 0;
        }

        @include heading() {
            padding: 23px 30px 0 2px;

            &.bottom {
                @include tablet() {
                    bottom: 7%;
                }
            }
        }

        img {
            max-width: 540px;
            width: 100%;
        }
    }
}
