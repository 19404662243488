.cms-services {
    .zero-tile-storyblock {
        .pagebuilder-column {
            .image-storyblock[data-spacing~='top'][data-small-block='true'] {
                @include desktop() {
                    margin-top: 11vw;
                }

                @include media($storyblock-max-width) {
                    margin-top: 174px;
                }
            }
        }

        .quote-storyblock[data-content-type='quote_storyblock'] {
            @include desktop() {
                margin-top: 0;
            }

            .quote-text {
                margin: 16% 12% 0 6%;

                @include tablet() {
                    margin: 19% 12% 11.5% 9.8%;
                }

                @include desktop() {
                    margin: 26.5% 14.5% 26.5% 12.1%;
                }

                @include media($storyblock-max-width) {
                    margin: 144px 72px 144px 60px;
                }
            }
        }
    }

    .pagebuilder-column {
        .text-storyblock.text-1[data-content-type='text_storyblock']{
            margin-bottom: 0;

            @include desktop() {
                padding: 20px 10vw 0;
            }

            @include media($storyblock-max-width) {
                padding: 20px 168px 0;
            }
        }

        .text-storyblock.text-2[data-content-type='text_storyblock']{
            margin-bottom: 0;

            &:last-of-type {
                margin-bottom: 18vw;

                @include tablet() {
                    margin-bottom: 14vw;
                }

                @include desktop() {
                    margin-bottom: 0;
                }
            }

            @include desktop() {
                padding: 0 10vw;
            }

            @include media($storyblock-max-width) {
                padding: 0 168px;
            }
        }
    }

    .text-secondary {
        @include tablet() {
            max-width: 70%;
        }

        @include desktop() {
            max-width: 90%;
        }
    }
}
