.unordinary {
    .unordinary-hero {
        .mobile-hero {
            background-color: $c-unordinary-title;
            max-width: 100%;
            padding: 48px 0 40px;
        }

        .desktop-hero {
            padding-top: 18px;

            @include desktop() {
                padding-top: 30px;
            }
        }

        &.hero-block {
            margin: 0;
            margin-top: -119px;

            @include desktop() {
                margin-top: -113px;
            }

            &.under-nav {
                margin-top: 0;
            }
        }

        h2 {
            color: $c-white;
            font-size: 42px;
            letter-spacing: .4px;
            margin-bottom: 4px;
            padding-right: 6px;

            @include desktop() {
                margin-bottom: 31px;
                padding: 0;
            }

            @include desktop-extra-big() {
                font-size: 69px;
                letter-spacing: .3px;
                margin-bottom: 20px;
            }

            em {
                font-size: 42px;
                font-weight: 100;
                letter-spacing: -.5px;

                @include desktop-extra-big() {
                    font-size: 65px;
                    font-weight: 100;
                    letter-spacing: .2px;
                }
            }
        }

        p {
            color: $c-white;
            letter-spacing: normal;
            margin: 0 auto;
            max-width: 420px;
            padding: 12px 40px 0;

            @include desktop() {
                color: $c-white;
                letter-spacing: .2px;
            }
        }

        img {
            width: 100%;

            @include desktop() {
                max-width: 100%;
            }
        }
    }
}
