.cms-no-route,
.cms-outlet-no-route {
    .page-main {
        padding: 8px 20px 50px;

        @include tablet() {
            padding: 0 73px 60px;
        }

        @include desktop() {
            margin: 110px auto 0;
            max-width: 1600px;
            padding: 0 11.5% 205px 92px;
        }
    }

    .page-title {
        font-family: $f-lafayette148;
        font-size: 25px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 30px;
        margin: 0 0 24px;
        text-transform: uppercase;

        @include tablet() {
            font-size: 50px;
            line-height: 67px;
            max-width: 600px;
        }

        @include desktop() {
            font-size: 44px;
            margin-top: 40px;
            max-width: 100%;

        }
    }

    .small-description {
        font-size: 12px;
        font-weight: 700;
        line-height: 27px;
        padding-bottom: 27px;
        text-transform: uppercase;

        @include tablet() {
            padding-bottom: 47px;
        }

        @include desktop() {
            padding-bottom: 64px;
        }
    }

    .not-found-search {
        .label {
            display: none;
        }

        .field.search {
            position: relative;
        }

        .control {
            height: 45px;
            margin-bottom: $content-spacing-xl;
            position: relative;

            &::before {
                @include size(25px);
                background: url('../images/icon-search-big.svg') no-repeat center;
                background-size: cover;
                content: '';
                display: block;
                left: -2px;
                position: absolute;
                top: 12px;
            }
        }

        .input-text {
            background: $c-white;
            border: 0;
            border-bottom: 1px solid $c-black;
            color: $c-black;
            font-family: $f-lafayette148;
            font-size: 30px;
            font-weight: 300;
            line-height: 38px;
            padding: 14px 70px 14px 47px;
            width: 100%;

            @include tablet() {
                padding: 10px 70px 14px 42px;
            }

            &::placeholder {
                color: $c-black;
                font-family: $f-lafayette148;
                font-size: 30px;
                font-weight: 300;
                line-height: 38px;
                text-transform: uppercase;
            }
        }

        .action.search {
            @include size(45px);
            background: transparent url('../images/icon-close-black.svg') no-repeat 13px 14px;
            background-size: 29px;
            position: absolute;
            right: -12px;
            top: 5px;

            @include tablet() {
                right: -10px;
                top: 7px;
            }

            @include desktop() {
                background-size: 42px;
                right: 2px;
                top: -4px;
            }
        }
    }

    .link-block {
        padding: 53px 0 20px;

        @include tablet() {
            padding: 58px 0 20px;
        }

        &:nth-of-type(2) {
            padding: 36px 0 20px;

            @include tablet() {
                padding: 71px 0 20px;
            }

            @include desktop() {
                padding: 52px 0 20px;
            }
        }

        li,
        a {
            font-family: $f-lafayette148;
            font-size: 15px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 27px;
            text-decoration: none;
            text-transform: uppercase;
        }

        .title {
            font-family: $f-lafayette148;
            font-size: 28px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 38px;
            margin-bottom: 21px;
            text-transform: uppercase;

            @include tablet() {
                font-size: 33px;
            }
        }

        .tel {
            text-decoration: underline;
        }
    }

    &.cms-noroute-index {
        .link-block {
            &:nth-of-type(3) {
                padding: 36px 0 20px;

                @include tablet() {
                    padding: 71px 0 20px;
                }

                @include desktop() {
                    padding: 52px 0 20px;
                }
            }
        }
    }

    ul {
        list-style-type: none;
    }
}
