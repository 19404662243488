// Global Messages
.message-container {
    &.absolute-position {
        position: relative;

        .message {
            left: 0;
            max-width: 1600px;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 10;
        }
    }

    &.hp-message-has-space {
        .message {
            @include desktop() {
                margin-bottom: -70px;
                margin-top: 92px;
            }
        }
    }

    &.hp-absolute-position {
        margin: 0 auto;
        max-width: 100%;
        position: relative;
        width: calc(90vh * 16 / 9);
        z-index: 101;

        @include desktop() {
            z-index: 295;
        }

        .message {
            max-width: none;

            @include desktop() {
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }

}

.messages {
    &.absolute-position {
        left: 0;
        position: absolute;
        top: 90px;
        width: 100%;
        z-index: 10;
    }

    .message {
        background: $c-white;
        color: $c-brown-darker;
        display: block;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        margin: 0 auto $content-spacing-l;
        max-width: $header-max-width-big;
        overflow: hidden;
        padding: 18px $content-spacing-xxl 18px $content-spacing-l;
        position: relative;
        text-align: center;
        text-transform: uppercase;

        @include desktop() {
            padding: 18px 70px;
        }

        &.error,
        &.warning {
            color: $c-red;
        }

        &.success {
            font-weight: $f-weight-bold;
        }

        &.info,
        &.notice,
        &.alert {
            span {
                color: $c-notice;
            }
        }

        .action-close {
            background: url('../images/icon-close.svg') 3px 3px no-repeat;
            background-size: 21px;
            cursor: pointer;
            height: 21px;
            margin-top: -11px;
            position: absolute;
            right: 5px;
            top: 50%;
            width: 21px;

            @include desktop() {
                right: 44px;
            }
        }
    }
}
