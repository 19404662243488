[data-content-type="editorial_block_large"] {
    // full-width block
    &[data-appearance="full-width"] {
        margin-bottom: 14.667%;
        margin-top: 3.9%;

        @include tablet() {
            margin-bottom: 5.2%;
            margin-top: 3.9%;
        }

        @include desktop() {
            margin-bottom: 4.1667%;
            margin-top: 3.9%;
        }

        &[data-caption-position="none"] {
            .caption {
                @include tablet() {
                    display: none;
                }
            }
        }

        &[data-caption-position^="left-"] {
            .caption {
                @include tablet() {
                    left: 9.1%;
                    width: calc(100% - (9.1% * 2));
                }

                @include desktop() {
                    left: 11.3%;
                    width: calc(100% - (11.3% * 2));
                }
            }
        }

        &[data-caption-position^="center-"] {
            .caption {
                @include tablet() {
                    left: 50%;
                    text-align: center;
                    transform: translateX(-50%);
                    width: calc(100% - (9.1% * 2));
                }
            }
        }

        &[data-caption-position^="right-"] {
            .caption {
                @include tablet() {
                    right: 9.1%;
                    text-align: right;
                    width: calc(100% - (9.1% * 2));
                }

                @include desktop() {
                    right: 11.3%;
                    text-align: right;
                    width: calc(100% - (11.3% * 2));
                }
            }
        }

        &[data-caption-position$="-top"] {
            .caption {
                @include tablet() {
                    top: 7.9%;
                }

                @include desktop() {
                    top: 12.2%;
                }
            }
        }

        &[data-caption-position$="-middle-top"] {
            .caption {
                @include tablet() {
                    top: 30.8%;
                }

                @include desktop() {
                    top: 34%;
                }
            }
        }

        &[data-caption-position$="-bottom"] {
            .caption {
                @include tablet() {
                    top: 78%;
                }

                @include desktop() {
                    top: 76.8%;
                }
            }
        }

        &[data-caption-position$="-middle-bottom"] {
            .caption {
                @include tablet() {
                    top: 54.9%;
                }

                @include desktop() {
                    top: 55.7%;
                }
            }
        }

        &[data-caption-position-mobile="none"] {
            .caption {
                @include mobile-only() {
                    display: none;
                }
            }
        }

        &[data-caption-position-mobile^="left-"] {
            .caption {
                @include mobile-only() {
                    left: 10.8%;
                    width: calc(100% - (10.8% * 2));
                }
            }
        }

        &[data-caption-position-mobile^="center-left-"] {
            .caption {
                @include mobile-only() {
                    left: 28.4%;
                    width: calc(100% - 28.4%);
                }
            }
        }

        &[data-caption-position-mobile^="center-right-"] {
            .caption {
                @include mobile-only() {
                    right: 28.4%;
                    text-align: right;
                    width: calc(100% - 28.4%);
                }
            }
        }

        &[data-caption-position-mobile^="right-"] {
            .caption {
                @include mobile-only() {
                    right: 10.8%;
                    text-align: right;
                    width: calc(100% - (10.8% * 2));
                }
            }
        }

        &[data-caption-position-mobile$="-top"] {
            .caption {
                @include mobile-only() {
                    top: 6.7%;
                }
            }
        }

        &[data-caption-position-mobile$="-middle-top"] {
            .caption {
                @include mobile-only() {
                    top: 24.6%;
                }
            }
        }

        &[data-caption-position-mobile$="-middle"] {
            .caption {
                @include mobile-only() {
                    top: 42.6%;
                }
            }
        }

        &[data-caption-position-mobile$="-bottom"] {
            .caption {
                @include mobile-only() {
                    top: 78.6%;
                }
            }
        }

        &[data-caption-position-mobile$="-middle-bottom"] {
            .caption {
                @include mobile-only() {
                    top: 60.5%;
                }
            }
        }
    }

    // framed-small block
    &[data-appearance="framed-small"] {
        margin: 16% 0 3.2%;
        padding: 0 7.467%;

        @include tablet() {
            margin: 8.8% 0 7.2%;
            padding: 0 9.115%;
        }

        @include desktop() {
            margin: 6.2% 0 11.2%;
            padding: 0 10.893%;
        }

        &[data-smaller-bottom-margin="true"] {
            margin-bottom: 9.4%;

            @include tablet() {
                margin-bottom: 4.3%;
            }
        }

        &[data-caption-position="none"] {
            .caption {
                @include tablet() {
                    display: none;
                }
            }
        }

        &[data-caption-position^="left-"] {
            .caption {
                @include tablet() {
                    left: 11.2%;
                    width: calc(100% - (11.2% * 2));
                }

                @include desktop() {
                    left: 4.5%;
                    width: calc(100% - (4.5% * 2));
                }
            }
        }

        &[data-caption-position^="center-"] {
            .caption {
                @include tablet() {
                    left: 50%;
                    text-align: center;
                    transform: translateX(-50%);
                    width: calc(100% - (11.2% * 2));
                }
            }
        }

        &[data-caption-position^="right-"] {
            .caption {
                @include tablet() {
                    right: 11.2%;
                    text-align: right;
                    width: calc(100% - (11.2% * 2));
                }

                @include desktop() {
                    right: 4.5%;
                    text-align: right;
                    width: calc(100% - (4.5% * 2));
                }
            }
        }

        &[data-caption-position$="-top"] {
            .caption {
                @include tablet() {
                    top: 9.6%;
                }

                @include desktop() {
                    top: 9.8%;
                }
            }
        }

        &[data-caption-position$="-bottom"] {
            .caption {
                @include tablet() {
                    top: 73%;
                }

                @include desktop() {
                    top: 67.5%;
                }
            }
        }

        &[data-caption-position$="-middle-top"],
        &[data-caption-position$="-middle-bottom"] {
            .caption {
                @include tablet() {
                    top: 40.6%;
                }

                @include desktop() {
                    top: 39.1%;
                }
            }
        }

        &[data-caption-position-mobile="none"] {
            .caption {
                @include mobile-only() {
                    display: none;
                }
            }
        }

        &[data-caption-position-mobile^="left-"],
        &[data-caption-position-mobile^="center-left-"] {
            .caption {
                @include mobile-only() {
                    left: 7%;
                    width: calc(100% - (7% * 2));
                }
            }
        }

        &[data-caption-position-mobile^="right-"],
        &[data-caption-position-mobile^="center-right-"] {
            .caption {
                @include mobile-only() {
                    right: 7%;
                    text-align: right;
                    width: calc(100% - (7% * 2));
                }
            }
        }

        &[data-caption-position-mobile$="-top"] {
            .caption {
                @include mobile-only() {
                    top: 5.5%;
                }
            }
        }

        &[data-caption-position-mobile$="-bottom"] {
            .caption {
                @include mobile-only() {
                    top: 67.5%;
                }
            }
        }

        &[data-caption-position-mobile$="-middle-top"],
        &[data-caption-position-mobile$="-middle"],
        &[data-caption-position-mobile$="-middle-bottom"] {
            .caption {
                @include mobile-only() {
                    top: 35.5%;
                }
            }
        }
    }

    // framed-tall block
    &[data-appearance="framed-tall"] {
        margin: 6.2% 0 11.2%;
        padding: 0 7.467%;

        @include tablet() {
            margin: 6.2% 0 11.2%;
            padding: 0 9.115%;
        }

        @include desktop() {
            margin: 6.2% 0 11.2%;
            padding: 0 10.893%;
        }

        &[data-smaller-bottom-margin="true"] {
            margin-bottom: 9.4%;

            @include tablet() {
                margin-bottom: 4.3%;
            }
        }

        &[data-caption-position="none"] {
            .caption {
                @include tablet() {
                    display: none;
                }
            }
        }

        &[data-caption-position^="left-"] {
            .caption {
                @include tablet() {
                    left: 11.2%;
                    width: calc(100% - (11.2% * 2));
                }

                @include desktop() {
                    left: 4.4%;
                    width: calc(100% - (4.4% * 2));
                }
            }
        }

        &[data-caption-position^="center-"] {
            .caption {
                @include tablet() {
                    left: 50%;
                    text-align: center;
                    transform: translateX(-50%);
                    width: calc(100% - (11.2% * 2));
                }
            }
        }

        &[data-caption-position^="right-"] {
            .caption {
                @include tablet() {
                    right: 11.2%;
                    text-align: right;
                    width: calc(100% - (11.2% * 2));
                }

                @include desktop() {
                    right: 4.4%;
                    text-align: right;
                    width: calc(100% - (4.4% * 2));
                }
            }
        }

        &[data-caption-position$="-top"] {
            .caption {
                @include tablet() {
                    top: 13.9%;
                }

                @include desktop() {
                    top: 14.7%;
                }
            }
        }

        &[data-caption-position$="-bottom"] {
            .caption {
                @include tablet() {
                    top: 73%;
                }

                @include desktop() {
                    top: 71.6%;
                }
            }
        }

        &[data-caption-position$="-middle-top"],
        &[data-caption-position$="-middle-bottom"] {
            .caption {
                @include tablet() {
                    top: 43.4%;
                }

                @include desktop() {
                    top: 43.1%;
                }
            }
        }

        &[data-caption-position-mobile="none"] {
            .caption {
                @include mobile-only() {
                    display: none;
                }
            }
        }

        &[data-caption-position-mobile^="left-"],
        &[data-caption-position-mobile^="center-left-"] {
            .caption {
                @include mobile-only() {
                    left: 7%;
                    width: calc(100% - (7% * 2));
                }
            }
        }

        &[data-caption-position-mobile^="right-"],
        &[data-caption-position-mobile^="center-right-"] {
            .caption {
                @include mobile-only() {
                    right: 7%;
                    text-align: right;
                    width: calc(100% - (7% * 2));
                }
            }
        }

        &[data-caption-position-mobile$="-top"] {
            .caption {
                @include mobile-only() {
                    top: 13.2%;
                }
            }
        }

        &[data-caption-position-mobile$="-bottom"] {
            .caption {
                @include mobile-only() {
                    top: 68.6%;
                }
            }
        }

        &[data-caption-position-mobile$="-middle-top"],
        &[data-caption-position-mobile$="-middle"],
        &[data-caption-position-mobile$="-middle-bottom"] {
            .caption {
                @include mobile-only() {
                    top: 41%;
                }
            }
        }
    }

    [data-element="wrapper"] {
        margin: 0 auto;
    }
}
