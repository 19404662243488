.main-menu {
    .level0 {
        &.submenu {
            @include desktop() {
                background-color: $c-white-transparent-menu;
                display: flex !important; // scss-lint:disable ImportantRule
                justify-content: center;
                left: 0 !important; // scss-lint:disable ImportantRule
                max-height: 0;
                opacity: .3;
                overflow: hidden;
                padding: 0;
                position: absolute !important; // scss-lint:disable ImportantRule
                right: 0;
                top: 100% !important; // scss-lint:disable ImportantRule
                transition: max-height .3s ease, opacity .3s ease;
                width: 100%;
                z-index: 490;
            }

            &[aria-expanded="true"] {
                @include desktop() {
                    max-height: 900px;
                    opacity: 1;
                }
            }
        }

        &:hover {
            > .submenu {
                @include desktop() {
                    z-index: 500;
                }
            }
        }

        li {
            @include desktop() {
                white-space: normal;
            }

            &.is-email-landing {
                display: none;
            }

            &.show-email-landing {
                display: block;
            }
        }
    }

    .submenu {
        @include desktop() {
            position: static !important; // scss-lint:disable ImportantRule
        }

        .submenu {
            @include desktop() {
                display: block !important; // scss-lint:disable ImportantRule
            }
        }
    }
}

.main-menu.initial-main-menu { // scss-lint:disable MergeableSelector
    .level0.category-item:hover {
        &::before {
            @include desktop() {
                content: '';
                display: block;
                height: 30px;
                left: 0;
                position: absolute;
                top: calc(100% - 30px);
                width: 100%;
                z-index: 1001;
            }
        }

        > .submenu {
            @include desktop() {
                max-height: 900px;
                opacity: 1;
            }
        }
    }
}
