.unordinary {
    // scss-lint:disable NestingDepth
    .uw-block-9,
    .uw-block-10 {
        margin-top: 5px;

        @include desktop {
            margin: 0 205px;
        }

        .product-quote {
            padding: 0;
        }

        .pagebuilder-column-group {
            max-width: none;

            &:first-of-type {
                margin-top: 14px;
                min-height: 416px;
                order: 1;
                padding: 0 25px;

                @include tablet {
                    margin-top: 45px;
                    min-height: 454px;
                    padding: 0 105px;
                }

                @include desktop {
                    margin-right: 20%;
                    margin-top: 0;
                    min-height: 576px;
                    padding: 0;
                    width: 37%;
                }

                .text {
                    margin-top: 0;

                    h1 {
                        font-size: 37px;
                        font-weight: $f-weight-light;
                        line-height: 30px;
                        margin-bottom: 0;

                        @include desktop {
                            font-size: $f-size-xxxml-mobile;
                            line-height: 44px;
                        }

                        &:first-of-type {
                            font-family: $f-perpetua-titling;
                            margin-bottom: 0;
                        }

                        &:last-of-type {
                            font-family: $f-lafayette148;
                            margin-bottom: 13px;

                            @include tablet {
                                margin-bottom: 21px;
                            }

                            @include desktop {
                                margin-bottom: 31px;
                            }
                        }
                    }

                    div {
                        font-size: $f-size-m;
                        font-weight: $f-weight-light;
                        line-height: 27px;
                    }

                    a {
                        font-size: $f-size-m;
                        font-weight: $f-weight-light;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    p {
                        margin-top: 5px;

                        @include tablet {
                            margin-top: 40px;
                        }

                        @include desktop {
                            margin-top: 23px;
                        }
                    }
                }
            }

            &:last-of-type {
                padding-left: 68px;

                @include tablet {
                    padding-left: 105px;
                }

                @include desktop {
                    min-height: 576px;
                    order: 2;
                    padding-left: 0;
                    width: 38%;
                }

                .pagebuilder-column {
                    @include desktop {
                        justify-content: center !important; // scss-lint:disable ImportantRule
                    }
                }
            }
        }
    }

    // scss-lint:disable ImportantRule NestingDepth SelectorDepth
    .uw-block-10 {
        @include tablet {
            margin-top: 7px;
        }

        @include desktop {
            margin: 3px 120px 0;
        }

        .product-quote {
            .pagebuilder-column-group {
                min-height: auto;

                .image-storyblock {
                    @include desktop {
                        margin-bottom: 0;
                    }
                }

                .text {
                    h1 {
                        &:last-of-type {
                            margin-bottom: 21px;

                            @include tablet {
                                margin-bottom: 19px;
                            }

                            @include desktop {
                                margin-bottom: 30px;
                            }
                        }
                    }

                    div {
                        padding-right: 28px;
                    }
                }

                &:first-of-type {
                    margin-top: 79px;

                    @include tablet {
                        margin-bottom: 102px;
                        margin-top: 128px;
                    }

                    @include desktop {
                        margin-bottom: 0;
                        margin-top: 0;
                    }
                }
            }

            &:first-of-type {
                @include tablet {
                    margin-bottom: 0;
                }

                @include desktop {
                    margin-top: 0;
                }

                .pagebuilder-column-group {
                    &:first-of-type {
                        @include desktop {
                            margin-bottom: 0;
                        }

                        p {
                            @include desktop {
                                margin-top: 21px;
                            }
                        }
                    }

                    &:last-of-type {
                        padding-left: 70px;

                        @include tablet {
                            padding-left: 105px;
                        }

                        @include desktop {
                            margin-left: auto;
                            padding-left: 0;
                        }

                        .image-storyblock {
                            @include desktop {
                                margin-bottom: 0;
                                margin-top: 0;
                            }
                        }
                    }
                }

                img {
                    width: 100%;
                }
            }

            &:last-of-type {
                justify-content: flex-start !important; // scss-lint:disable ImportantRule
                margin-bottom: 0;

                @include desktop {
                    margin-top: 144px;
                }

                .pagebuilder-column-group {
                    &:first-of-type {
                        margin-top: 74px;

                        @include tablet {
                            margin-bottom: 0;
                            margin-top: 124px;
                        }

                        @include desktop {
                            margin-left: 7%;
                            margin-right: auto;
                            margin-top: 0;
                            order: 2;
                            width: 43%;
                        }

                        h1 {
                            &:last-of-type {
                                margin-bottom: 24px;

                                @include tablet {
                                    margin-bottom: 19px;
                                }

                                @include desktop {
                                    margin-bottom: 27px;
                                }
                            }
                        }

                        p {
                            &:first-of-type {
                                margin-top: 32px;

                                @include tablet {
                                    margin-top: 50px;
                                }

                                @include desktop {
                                    margin-top: 15px;
                                }
                            }

                            &:last-of-type {
                                line-height: 26px;
                                margin-top: 0;

                                @include tablet {
                                    margin-top: 10px;
                                }

                                @include desktop {
                                    margin-top: 9px;
                                }

                                a {
                                    line-height: 0;
                                }
                            }
                        }
                    }

                    &:last-of-type {
                        padding-left: 0;
                        padding-right: 70px;

                        @include tablet {
                            padding-right: 105px;
                        }

                        @include desktop {
                            margin-left: 0;
                            margin-right: 0;
                            order: 1;
                            padding-right: 0;
                            width: 38%;
                        }

                        a {
                            font-size: $f-size-m;
                            font-weight: $f-weight-light;

                            &:hover {
                                text-decoration: none;
                            }
                        }

                        p {
                            display: none;
                            line-height: 40px;

                            @include desktop {
                                display: block;
                            }
                        }

                        .image-storyblock {
                            @include desktop {
                                margin-bottom: 0;
                                margin-top: 0;
                            }
                        }
                    }
                }

                img {
                    width: 100%;
                }
            }
        }

        > .block {
            @include desktop {
                margin: 0 auto;
                max-width: 1200px;
            }
        }
    }
    // scss-lint:enable ImportantRule NestingDepth SelectorDepth

    .uw-event-block-2021 {
        margin-bottom: 42px;
        margin-top: 73px;

        @include tablet {
            margin-bottom: 111px;
            margin-top: 108px;
        }

        @include desktop {
            margin-bottom: 157px;
            margin-top: 140px;
        }

        @include desktop-extra-big {
            margin-left: auto;
            margin-right: auto;
            max-width: 1680px;
        }

        .image-wrapper {
            > div {
                height: 478px !important; // scss-lint:disable ImportantRule
            }
        }

        .information-wrapper {
            padding-bottom: 0;
            padding-top: 0;

            @include tablet {
                padding-bottom: 0;
                padding-top: 0;
            }

            .title {
                padding-top: 18px;
                width: 100%;

                @include desktop {
                    padding-top: 16px;
                }

                p {
                    font-family: $f-perpetua-titling;
                    font-size: 37px;
                    font-weight: $f-weight-light;
                    line-height: 30px;
                    margin-bottom: 0;

                    @include desktop {
                        font-size: 55px;
                        line-height: 44px;
                    }

                    &:last-of-type {
                        font-family: $f-lafayette148;
                        margin-bottom: 18px;

                        @include tablet {
                            margin-bottom: 45px;
                        }

                        @include desktop {
                            margin-bottom: 21px;
                        }
                    }
                }
            }

            .subtitle {
                font-size: $f-size-m;
                font-weight: $f-weight-light;
                line-height: 27px;
                margin-top: 0;
                max-width: 300px;

                @include tablet {
                    margin-bottom: 29px;
                    max-width: 405px;
                }

                @include desktop {
                    margin-bottom: 0;
                    max-width: 468px;
                }

                p {
                    margin-right: 0 !important; // scss-lint:disable ImportantRule
                }
            }

            .link {
                padding: 0;

                @include desktop {
                    padding-top: 6px;
                }
            }

            a {
                font-size: $f-size-m;
                line-height: 54px;
                text-decoration: none;
            }
        }
    }

    .uw-bio-video-block {
        @media screen and (min-width: 1680px) {
            margin: 0 auto !important; // scss-lint:disable ImportantRule
            max-width: 1680px;
        }

        .bf-video.wrapped {
            padding: 0;

            // scss-lint:disable ImportantRule
            .video-wrapper {
                @media screen and (max-width: 599px) {
                    height: 125vw;
                    width: 100% !important;
                }
            }
            // scss-lint:enable ImportantRule
        }
    }
    // scss-lint:enable NestingDepth
}
