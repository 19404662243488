.bf-banner {
    margin: 0;

    @include desktop() {
        margin-bottom: 0;
    }

    @include heading() {
        padding-top: 0;

        @include desktop() {
            left: 335px;

            p {
                padding-top: 20px;
            }

            &.right {
                right: 252px;
            }
        }
    }
}
