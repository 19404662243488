.cms-page-view {
    [data-content-type="sticky_menu_block"] {
        background-color: $c-grey-gallery;
        margin-bottom: 60px;

        @include desktop() {
            margin-bottom: 120px;
        }

        &.sticky-toggle-on {
            left: 0;
            position: fixed;
            top: 97px;
            width: 100vw;
            z-index: $z-header;
        }

        .sticky-menu-item-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto;
            max-width: $content-max-width;
            text-align: center;

            @include tablet() {
                flex-wrap: nowrap;
            }
        }

        [data-content-type="button-item"] {
            flex: 0 300px;
            margin: $content-spacing-m 0;

            @include tablet() {
                border-right: 1px solid $c-grey-nickle-approx;
                margin: $content-spacing-xxl 0;
            }

            &:last-child {
                border-right: 0;
            }
        }

        a {
            color: $c-brown-darker;
            font-weight: $f-weight-semi-bolder;
            letter-spacing: $letter-spacing-xxs;
            line-height: 1.8;
            padding: 0 5%;
        }
    }
}
