.quote-storyblock {
    .quote-text {
        box-sizing: border-box;
        font-family: $f-lafayette148;
        font-size: 31px;
        font-weight: 300;
        line-height: 1;
        margin: 16% 6%;
        text-transform: uppercase;

        @include tablet {
            font-size: 44px;
            margin: 10% 20% 0 8%;
        }
    }

    .quote-wrapper {
        overflow: hidden;
        position: relative;
    }

    .quote-author {
        margin: 18% 0 12% 34%;

        @include tablet {
            margin: 8% 0 8% 45%;
        }

        p {
            font-size: 15px;
            line-height: 12px;
            padding: 0;
            text-transform: uppercase;

            &.quote-author-name {
                font-family: $f-perpetua-titling;
            }

            &.quote-author-role {
                font-family: $f-lafayette148;
            }
        }
    }
}

[data-content-type='row'] {
    > .quote-storyblock {
        .quote-wrapper {
            padding: 75px 20px 80px;

            @include tablet() {
                padding: 17.5% 10%;
            }

            @include desktop() {
                padding: 10% 12.2%;
            }

            .quote-text {
                margin: 0;
                text-align: center;
            }

            .quote-author {
                margin: 30px 0 0 50%;

                @include tablet() {
                    margin-left: 60%;
                    margin-top: 15px;
                }

                @include tablet() {
                    margin-top: 50px;
                }
            }
        }
    }
}

@include desktop {
    .pagebuilder-column .quote-storyblock[data-content-type='quote_storyblock'] {
        margin: 100vw * $sb-lane-desktop;

        @include media($sb-max-width) {
            margin: $sb-max-width * $sb-lane-desktop;
        }

        .quote-text {
            margin: 26% 10% 0;
        }

        .quote-author {
            margin: 13% 0 18% 50%;
        }
    }
}
