// Page Header
.page-header {
    background-color: $c-white;
    position: relative;
    width: 100%;

    .header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 25px 28px 10px 22px;
        position: relative;

        @include tablet-big() {
            padding: 37px 32px 5px 18px;
        }

        @include desktop() {
            justify-content: flex-end;
            margin: 0 auto;
            max-width: 100%;
            padding: 24px 0 0;
            position: static;
        }
    }

    &.sticky {
        .main-menu {
            right: 0;
            top: 0;
        }

        .complimentary_shipping {
            top: 25px;
        }
    }

    .main-menu {
        background: $c-white;
        font-family: $f-lafayette148;
        height: 100vh;
        left: 0;
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        padding: 3px 0;
        position: absolute;
        top: 0;
        touch-action: pan-y;
        transition: .3s width ease-in;
        width: 0;
        z-index: 99;

        @include desktop() {
            background: transparent;
            border: 0;
            box-shadow: none;
            display: flex;
            flex-basis: 100%;
            height: auto;
            margin-top: 0;
            overflow: visible;
            padding: 8px 0;
            position: static;
            transition: none;
            visibility: visible;
            width: auto;
            z-index: 495;
        }

        &.mobile {
            box-shadow: 5px 0 30px 1px $c-dropdown-box-shadow-lighter;
            width: 315px;

            @include tablet-big() {
                width: 395px;
            }

            @include desktop() {
                box-shadow: none;
            }
        }

        &.visible {
            ul,
            .greet.welcome {
                opacity: 1;
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            list-style: none outside none;
            opacity: 0;
            padding: 146px 34px 30px 32px;
            transition: .5s opacity ease;

            @include tablet() {
                padding: 148px 64px 30px 72px;
            }

            @include desktop() {
                flex-direction: row;
                opacity: 1;
                padding: 0;
            }

            > .level0 {
                border-bottom: 1px solid $c-grey-darker-border;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                padding: 0 16px;
                white-space: nowrap;

                @include desktop() {
                    border: 0;
                    padding: 0 18px;
                }

                > a {
                    color: $c-black;
                }
            }

            // scss-lint:disable MergeableSelector
            > .level0.parent {
                background: url('../images/icon-arrow-right-small.svg') no-repeat center right;
                background-size: 9px;

                @include desktop() {
                    background: none;
                }
            }
            // scss-lint:enable MergeableSelector

            a,
            .level-top-span {
                cursor: pointer;
                display: block;
                font-size: $f-size-m-large;
                font-weight: $f-weight-bold;
                letter-spacing: .3px;
                line-height: $line-height-xs;
                padding: 27px 0;

                @include desktop() {
                    display: inline;
                    font-size: $f-size-base;
                    font-weight: $f-weight-semi-bold;
                    letter-spacing: inherit;
                    line-height: $line-height-m;
                    padding: 0;
                }

                // scss-lint:disable SelectorDepth, NestingDepth
                &.ui-state-focus {
                    text-decoration: underline;
                }

                // scss-lint:enable SelectorDepth, NestingDepth
            }

            > a:hover,
            .level-top-span:hover {
                @include desktop() {
                    text-decoration: underline;
                }
            }

            > .first {
                border-top: 1px solid $c-grey-darker-border;

                @include desktop() {
                    border: 0;
                    padding-left: 0;
                }
            }

            > .last {
                @include desktop() {
                    padding-right: 0;
                }
            }

            .level-top {
                // scss-lint:disable SelectorDepth, NestingDepth
                > a {
                    font-size: $f-size-s;
                    font-weight: $f-weight-bold;
                    text-transform: uppercase;

                    @include desktop() {
                        font-size: $f-size-xxs;
                        letter-spacing: 1.6px;
                    }
                }

                &.right-full-width {
                    .submenu-block {
                        @include desktop() {
                            flex-basis: 100%;
                            max-width: 1000px;
                            padding: 60px 0 54px;
                        }
                    }
                }

                // scss-lint:enable SelectorDepth, NestingDepth
            }
        }

        .greet.welcome {
            font-size: $f-size-m-large;
            margin: 73px 0 100px;
            opacity: 0;
            padding: 0 30px 0 34px;
            text-align: center;
            transition: .2s opacity ease;
            white-space: nowrap;

            @include tablet() {
                padding: 0 64px;
            }

            a {
                font-size: 17px;
            }
        }
    }

    .logo-wrapper {
        left: 50%;
        position: absolute;
        top: 48px;
        transform: translate(-50%);
        width: 203px;
        z-index: 1;

        @include tablet() {
            width: 214px;
        }

        @include tablet-big() {
            top: 40px;
        }

        @include desktop() {
            margin-bottom: 17px;
            margin-top: 37px;
            position: static;
            transform: translateX(50%);
        }

        .logo {
            display: inline-block;
            width: 100%;

            @include desktop() {
                max-width: 100%;
            }

            img,
            svg {
                max-width: 214px;

                @include tablet-big() {
                    max-width: none;
                }
            }

            svg {
                box-sizing: content-box;
                display: block;
                max-height: 36px;
                padding-bottom: 8px;
                width: 100%;

                @include tablet-big() {
                    padding-bottom: 5px;
                }
            }

            .logo-text {
                clip: rect(1px, 1px, 1px, 1px);
                height: 1px;
                overflow: hidden;
                position: absolute;
                width: 1px;
            }
        }
    }

    .header-right-wrapper {
        display: flex;
        flex-basis: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        max-width: none;

        @include desktop() {
            justify-content: flex-end;
            margin-left: 0;
            margin-right: auto;
            max-width: calc(1441px / 2);
            padding: 0 69px 0 0;
            position: relative;
            right: 0;
            top: 0;
            width: auto;
        }

        .block-search {
            flex-basis: 100%;
            margin: 39px 0 0;
            order: 4;

            @include tablet-big() {
                flex-basis: auto;
                margin: 6px 0 26px auto;
            }

            @include desktop() {
                margin: 8px 0 20px;
                order: 1;
            }
        }

        .header-international {
            display: none;

            @include desktop() {
                display: block;
                margin-right: 17px;
                margin-top: 14px;
                order: 2;

                img {
                    height: 20px;
                }
            }

            #flag-indicator-block {
                border: 1px solid rgba(0, 0, 0, .1);
                border-radius: 100%;
                cursor: pointer;
                height: 21px;
                overflow: hidden;
                width: 21px;
            }
        }

        .mobile-header-international {
            @include desktop() {
                display: none;
            }
        }

        .greet {
            display: none;

            @include desktop() {
                display: block;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                order: 3;
                padding: 11px 36px 0 0;
            }
        }
    }

    .mobile-menu-wrapper {
        order: 2;
        padding-top: $content-spacing-xs;
        text-align: right;

        @include tablet-big() {
            order: unset;
            padding-top: 0;
        }

        @include desktop() {
            display: none;
        }
    }

    .menu-trigger {
        cursor: pointer;
        display: inline-block;
        height: 20px;
        width: 30px;
        z-index: 100;

        @include tablet-big() {
            height: 30px;
            width: 25px;
        }

        &.active {
            left: 255px;
            position: absolute;
            top: 29px;

            @include tablet-big() {
                left: 335px;
                top: 25px;
            }

            @include desktop() {
                position: static;
            }

            > .open {
                display: none;
            }

            > .close {
                display: inline-block;
            }
        }

        &:hover {
            opacity: .7;
        }

        span,
        > .close {
            display: none;
        }

        > svg {
            cursor: pointer;
            display: inline-block;
            height: 18px;
            position: relative;
            right: 4px;
            top: 0;
            width: 23.5px;
            z-index: 100;

            @include tablet-big() {
                right: 0;
                top: 10px;
                width: 25px;
            }

            &.close {
                height: 20px;
                right: 0;
                width: 20px;

                @include tablet-big() {
                    right: 0;
                    top: 5px;
                }
            }
        }
    }

    .greet.welcome {
        color: $c-black;
        font-family: $f-lafayette148;
        font-size: $f-size-base;
        font-weight: $f-weight-semi-bold;
        letter-spacing: .5px;

        a {
            color: $c-black;
            font-family: $f-lafayette148;
            font-size: $f-size-base;
            font-weight: $f-weight-semi-bold;
            letter-spacing: .5px;
        }
    }

    .complimentary_shipping {
        margin: 15px 0 0;
        text-align: center;
        width: 100%;

        @include tablet() {
            margin-bottom: 10px;
        }

        @include desktop() {
            display: flex;
            justify-content: flex-end;
            left: 0;
            margin: 0 50% 0 0;
            max-width: 50%;
            position: absolute;
            text-align: left;
            top: 50px;
        }

        div {
            width: 100%;

            @include desktop() {
                max-width: calc(1441px/2);
                padding: 0 0 0 69px;
            }
        }
    }
}

.navigation.main-menu {
    @include desktop() {
        display: flex;
        justify-content: center;
    }
}

.header-right-wrapper-container {
    width: 100%;

    @include desktop() {
        max-width: 50%;
    }
}

// Fallback for custom header color svg
.page-header:not(.sticky) {
    .logo > svg {
        fill: $c-black;
    }

    .minicart-wrapper .showcart .crop-svg > svg, // scss-lint:disable SelectorDepth
    .mobile-menu-wrapper .menu-trigger > svg {
        fill: $c-darker;
    }
}
