.unordinary {
    [data-content-type="sticky_menu_block"] {
        margin-bottom: 30px;

        [data-content-type="button-item"] {
            margin-bottom: 25px;
            margin-top: 35px;
        }
    }

    .unordinary-third {
        margin-bottom: 0;
        padding: 48px 40px 60px;

        @include desktop() {
            margin-bottom: 73px;
            padding: 30px;
            padding-top: 58px;
        }

        .description {
            max-width: 1200px;

            @include desktop() {
                p {
                    margin: 28px auto 0;
                    max-width: 590px;
                }
            }

            h2 {
                font-size: 38px;
                letter-spacing: .4px;
                line-height: 43px;
                margin-bottom: 28px;

                @include desktop() {
                    color: $c-brown-darker;
                    display: initial;
                    font-size: $f-size-xxl;
                    font-weight: 300;
                    letter-spacing: .9px;
                    line-height: 65px;
                }
            }

            p {
                line-height: 28.3px;
            }
        }
    }
}
