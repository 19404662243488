.unordinary {
    .she-the-first-block {
        margin-bottom: 22px;

        @include desktop() {
            margin-bottom: 135px;
        }

        .hero-block {
            margin-bottom: 0;
            margin-top: 0;

            img {
                width: 100%;

                @include desktop() {
                    max-width: 100%;
                }
            }
        }
    }

    .she-the-first {
        padding: 38px 0 22px;

        @include desktop() {
            padding-bottom: 39px;
        }

        &.desktop-hero {
            @include desktop() {
                padding-top: 20px;
            }
        }

        h2 {
            color: $c-brown-darker;
            margin-bottom: 21px;

            @include desktop() {
                color: $c-brown-darker;
                margin-bottom: 26px;
            }
        }

        p {
            font-size: 15px;
            letter-spacing: .2px;
            margin: 0 auto;
            max-width: 700px;
            padding: 0 48px 12px;

            @include desktop() {
                font-size: 15.5px;
                max-width: 750px;
                padding: 0 0 14px;
            }
        }
    }
}
