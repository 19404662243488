// Icons
.icon {
    &::before {
        content: '';
        display: inline-block;
        height: 30px;
        margin: -4px -1px 0 3px;
        vertical-align: middle;
        width: 30px;

        @include tablet() {
            margin: -2px -1px 0 -2px;
        }

        @include tablet-big() {
            margin: -2px -1px 0 10px;
        }
    }

    &-heart {
        &::before {
            background: url('../images/icon-heart.svg') center no-repeat;
        }
    }

    &-phone {
        &::before {
            background: url('../images/icon-phone.svg') center no-repeat;
        }
    }

    &-chat {
        &::before {
            background: url('../images/icon-chat.svg') center no-repeat;
        }
    }

    &-pin {
        &::before {
            background: url('../images/icon-pin.svg') center no-repeat;
        }
    }

    &-share {
        &::before {
            background: url('../images/icon-share.svg') center no-repeat;
        }
    }
}
