@import 'components/swiper/_swiper';

@mixin size700() {
    @media screen and (min-width: 700px) {
        @content;
    }
}

@mixin size1560() {
    @media screen and (min-width: 1560px) {
        @content;
    }
}

@mixin size1025() {
    @media screen and (min-width: 1025px) {
        @content;
    }
}

@mixin sizeless600() {
    @media screen and (max-width: 599px) {
        @content;
    }
}

@mixin sizeless1200() {
    @media screen and (max-width: 1199px) {
        @content;
    }
}

.page-header {
    &.sticky {
        .header-right-wrapper {
            @include desktop() {
                padding-bottom: 0;
            }
        }
    }

    .header-right-wrapper {
        padding-bottom: 40px;

        @include desktop() {
            padding-bottom: 0;
        }

        .stick {
            padding: 0 3px;
        }

        .language-switch {
            display: none;

            @include desktop() {
                display: flex;
                align-items: center;
            }

            span {
                font-size: 13px;
                font-weight: 500;
            }
        }

        .language-switch-mobile {
            flex-grow: 1;
            text-align: right;
            order: 4;
            height: 30px;

            @include size700() {
                height: auto;
            }

            a {
                line-height: 1;
            }

            span {
                font-weight: 500;
            }

            @include desktop() {
                display: none;
            }
        }
    }

    &.clearBackground {
        background: transparent !important;
    }

    @include desktop() {
        transition: none;
    }

    .main-menu {
        ul > {
            li:last-child {
                @include desktop() {
                    padding-right: 0;
                }
            }
        }
    }
}

.page-footer {
    .footer-wrapper-top {
        /* .footer-wrapper-middle {
            @include desktop() {
                flex-grow: 0;
            }
        }

        .footer-wrapper-left {
            @include desktop() {
                margin-left: auto;
            }
        } */
    }

    .footer-wrapper-bottom {
        .navigation {
            .has-1-column {
                .level0:last-child {
                    margin-right: 0;
                }

                .level0 a {
                    img {
                        width: 20px;
                        margin-right: 4px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }

	.block.contactus {
		margin-top: 15px;
		@include tablet() {
			margin-top: 0;
		}
		.title span {
			font-family: "Lafayette148", "Times", serif;
			text-transform: unset;
		}
		.title .contact_us_footer span {
			@include desktop() {
				font-size: 17px;
			}
			font-size: 13px;
		}
	}
	
    .social-link-wrapper {
        width: 100%;
        @include tablet() {
            width: auto;
        }
        a {
            margin-left: auto;

            @include desktop() {
                margin-left: 20px;
            }

            &.wechatQr {
                position: relative;
                background-image: none;
                height: 43px;
                width: 43px;
                opacity: 1;

                .wechat-qr {
                    display: none;
                    line-height: 1.2;
                    position: absolute;
                    top: 100%;
                    padding: 5px 0 0 0;

                    .wechat-qr-wrapper {
                        background: #fff;
                        padding: 5px 5px 8px 5px;
                        box-sizing: content-box;
                        width: 100px;
                        height: auto;
                        @include tablet() {
                            width: 200px;
                        }
                    }

                    .wechat_qr_copy {
                        text-align: center;
                        font-size: 10px;
                    }
                }

                .wechatQr-icon {
                    display: block;
                    background-image: url('../images/social/icon-wechat.svg');
                    width: 100%;
                    height: 100%;
                    background-size: 100% 100%;
                    opacity: 0.5;
                    &.dy-icon {
                        background-image: url('../images/social/icon-dy.png');
                    }
                    &.icon-xhs {
                        background-image: url('../images/social/icon-xhs.png');
                    }
                    &.icon-wxsph {
                        background-image: url('../images/social/icon-wxsph.png');
                    }
                }

                &:hover {
                    .wechatQr-icon {
                        opacity: 1;
                    }
                }

                @include tablet() {
                    .wechat-qr {
                        top: auto;
                        bottom: 100%;
                        right: 100%;
                        padding: 0;
                    }
                }
            }

            &.weibo {
                background-image: url('../images/social/icon-weibo.svg');
                height: 43px;
                width: 43px;
                background-size: 43px 43px;
            }

            &.tmall {
                background-image: url('../images/social/icon-tmall.svg');
                height: 43px;
                width: 52px;
                background-size: 43px 43px;
            }
        }
    }
}

.storefinder-list-page {
    .store-list-wrapper,
    .event-list-wrapper {
        display: block;
    }

    .store-list-wrapper {
        .detail-row {
            margin-top: 20px;
            line-height: 1.5;
        }

        .storefinder-store {
            .phone-icon {
                background: url('../images/icon-phone.svg') center no-repeat;
                background-size: 13px;
            }
        }
    }

    h1 {
        font-family: $f-lafayette148;
        font-size: 63px;
        font-weight: $f-weight-light;
        line-height: 53px;
        text-transform: uppercase;
        max-width: 380px;

        &::first-line {
            font-family: $f-perpetua-titling;
            font-weight: $f-weight-light;
        }
    }
}

.back-to-top {
    display: block;
    opacity: 0;
    bottom: 135px;
    position: fixed;
    right: 30px;
    z-index: 150;
    transition: opacity .3s;

    .action {
        position: static;
    }

    &.backToTopAppear {
        opacity: 1;
    }

    @include tablet() {
        right: 60px;
    }
}

.catalog-slider {
    position: relative;
    margin-bottom: 30px;

    .swiper-arrow {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 50%;
        display: block;
        padding: 0;
        margin-top: -10px\9;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        border: none;
        outline: none;
        z-index: 50;
        width: 44px;
        height: 43px;
        background-color: #707070;
        background-size: 8px 10px;
        z-index: 50;

        @include tablet() {
            width: 60px;
            height: 58px;
            min-width: 60px;
        }

        &.swiper-next,
        &.swiper-prev {
            &:before {
                font-family: inherit;
                font-size: 20px;
                line-height: 1;
                color: #fff;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &.swiper-prev {
            left: 0;
            top: 100%;

            @include desktop() {
                left: -30px;
                top: 50%;
            }

            &:before {
                content: '←';
                font-size: 20px;
                line-height: 1;
                opacity: .75;
                color: #fff;
                -webkit-font-smoothing: antialiased;
            }
        }

        &.swiper-next {
            right: 0;
            top: 100%;

            @include desktop() {
                right: -30px;
                top: 50%;
            }

            &:before {
                content: '→';
                font-size: 20px;
                line-height: 1;
                opacity: .75;
                color: #fff;
                -webkit-font-smoothing: antialiased;
            }
        }
    }
}

.page-main {
    .catalog-wrapper {
        .swiper-lazy {
            opacity: 0;
            transition: opacity 0.3s;
        }

        .swiper-lazy-loaded {
            opacity: 1;
        }

        .slider-page-numbers {
            display: flex;
            margin: 0 0 20px 30px;

            @include desktop {
                margin-left: 0;
            }

            .title {
                margin-right: 5px;
            }

            .page-number-item {
                display: none;

                &.active {
                    display: inline-block;
                }
            }

            .slider-pager-desktop {
                display: none;

                @include size1025() {
                    display: block;
                }
            }

            .slider-pager-mobile {
                display: block;

                @include size1025() {
                    display: none;
                }
            }
        }

        @include desktop() {
            padding: 0 80px 60px;
        }

        .page-title-wrapper {
            margin: 30px 0 30px;

            @include desktop() {
                margin: 47px 0 47px;
            }

            .page-title {
                display: block;
                line-height: .9;
                margin-left: 30px;
                text-transform: capitalize;
                width: 280px;

                &.page-title-zh {
                    font-size: 50px;
                }

                @include tablet() {
                    display: inline;
                }

                @include desktop() {
                    margin-left: 0;
                }
            }
        }
    }
}

.main-menu {
    .level0 {
        li {
            .placeholder {
                background-color: rgb(180, 174, 164) !important;
            }
        }
    }

    .gallerymenu-block {
        .top-level-gallerymenu {
            @include desktop() {
                justify-content: center;
            }
        }
    }
}

.h5-video {
    max-width: 100%;
}

.captcha {
    background: #fff;
    cursor: pointer;
}

.video-storyblock {
    .video-wrapper {
        .video-thumbnail {
            z-index: 3;
        }
    }

    .video-desktop {
        height: 100%;
    }
}

.i18n-zh-hans {
	.tile-storyblock h1, .tile-storyblock h2 {
		line-height: 1;
	}

	[data-element="caption_wrapper"] .caption-text {
		line-height: 1;
	}

  .cms-denim .l-shape-block-1 .l-shape-block .caption-text span,
  .i18n-zh-hans .page-header .main-menu ul .level-top > a,
  .CmsOurPhilosophy-HeroText h1 {

    @include desktop() {
      line-height: 1 !important;
    }
  }

  .quote-storyblock {
    .quote-text {

      @include tablet {
        font-size: 40px;
        line-height: normal;
      }
    }
  }
}

.video-storyblock .video-wrapper {
	overflow: hidden;
}

.bf-video {
    .pagebuilder-video-container  {
        .video-wrapper {
            width: 100%;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
        }
    }
}

.pagebuilder-column-mobie-show {
    @include desktop() {
        display: none !important;
    }
}

.pagebuilder-column-mobie-hidden {
    @include sizeless1200() {
        display: none !important;
    }
}

@media screen and (min-width: 600px) and (max-width: 1199px) {
    .tile-storyblock [data-margin-tablet="right"] {
        margin-right: 14vw !important;
        margin-left: 0 !important;
    }

    .tile-storyblock .image-storyblock[data-margin-tablet="right"][data-small-block="true"] .image-wrapper {
        float: left !important;
    }

    .tile-storyblock [data-margin-tablet="left"] {
        margin-right: 0 !important;
        margin-left: 14vw !important;
    }

    .tile-storyblock .image-storyblock[data-margin-tablet="left"][data-small-block="true"] .image-wrapper {
        float: right !important;
    }
}

@media screen and (max-width: 599px) {
    .tile-storyblock [data-margin-mobile="right"] {
        margin-right: 18vw !important;
        margin-left: 0 !important;
    }

    .tile-storyblock .image-storyblock[data-margin-mobile="right"][data-small-block="true"] .image-wrapper {
        float: left !important;
    }

    .tile-storyblock [data-margin-mobile="left"] {
        margin-left: 18vw !important;
        margin-right: 0 !important;
    }

    .tile-storyblock .image-storyblock[data-margin-mobile="left"][data-small-block="true"] .image-wrapper {
        float: right !important;
    }
}

.l-shape-block { 
    .video-wrapper {
        @include tablet() {
            div {
                height: calc(#{$storyblock-max-width} * 9/16);
                max-height: calc(#{$storyblock-max-width} * 9/16);
    
                &.video-mobile {
                    display: none;
                }
    
                &.video-desktop {
                    display: block;
                }
            }
        }
    
        div {
            height: calc(#{$storyblock-max-width} * 16/9);
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% + 4px);
        }
    }
}

.cms-recuit {
    .recuit_main {
        .recuit_row {
            padding: 0 4%;
            margin-bottom: 40px;
            &:last-child {
                margin-bottom: 0;
            }
            p {
                font-weight: 400;
                text-align: center;
                padding-bottom: 0;
                @include tablet() {
                    font-size: 18px;
                }
            }
            h1,h2 {
                text-align: center;
                font-weight: 700;
                font-family: inherit;
            }
            h1 {
                font-size: 30px;
                @include tablet() {
                    font-size: 32px;
                }
            }
            h2 {
                font-size: 15px;
                @include tablet() {
                    font-size: 20px;
                }
            }
            &.recuit_row_top {
                padding-top: 40px;
                p {
                    @include tablet() {
                        font-size: 20px;
                        font-weight: 700;
                    }
                }
            }
            &.recuit_row_imgshow {
                padding: 0;
                .recuit_column_imgshow {
                    text-align: center;
                }
            }
            &.recuit_row_mission {
                p {
                    margin-bottom: 10px;
                    &:nth-of-type(1) {
                        margin-bottom: 30px;
                    }
                    &:last-child {
                        margin-top: 30px;
                    }
                }
            }
            &.recuit_row_bottom {
                p {
                    text-align: left;
                    margin-bottom: 20px;
                }
            }
            dl.joblist {
                .job_title, .job_detail_title {
                    font-size: 20px;
                    font-weight: 700;
                    @include tablet() {
                        font-size: 20px;
                    }
                }
                .job_title {
                    margin-bottom: 24px;
                }
                .job_detail {
                    @include desktop() {
                        padding-left: 150px;
                    }
                }
                .job_detail_title {
                    text-align: left;
                }
                ul {
                    padding-left: 0;
                    list-style: none;
                    @include tablet() {
                        li {
                            font-size: 16px;
                        }
                    }
                }
                .job_detail_info {
                    margin-bottom: 24px;
                }
            }
            .hr_qrcode {
                width: 85px;
                @include desktop() {
                    width: 200px;
                }
            }
        }
    }
}