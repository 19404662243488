@mixin new-cms-button-large() {
    .cms-button {
        font-size: 16px;
        margin: 33px 5px 0;
        max-width: 100%;
        min-width: 177px;
        padding: 14px 20px;
        width: auto;
    }
}

@mixin new-cms-button() {
    .cms-button {
        font-size: 12px;
        margin: 14px 0 0;
        min-width: 0;
        padding: 5px 15px 6px;
    }

    @include desktop() {
        @include new-cms-button-large();
    }
}
