.category-storyblock-initialize {
    left: -10px;
    max-width: none !important;
    position: relative;
    right: -10px;
    width: calc(100% + 20px);

    @include tablet() {
        left: -10px;
        right: -10px;
        width: calc(100% + 20px);
    }

    @include desktop() {
        left: 0;
        right: 0;
        width: 100%;
    }
}

[data-content-type="category_slider_storyblock"] {
    margin-bottom: 39px !important;
    overflow: hidden;

    @include desktop() {
        margin-bottom: 72px !important;
    }

    .category-storyblock {
        opacity: 0;
    }

    &[data-background="true"] {
        padding: 34px 0 49px;

        @include desktop() {
            padding: 55px 0 80px;
        }

        .category-storyblock-initialize {
            @include desktop() {
                left: -10px;
                right: -10px;
                width: calc(100% + 20px);
            }
        }
    }

    .category-storyblock-slider-text {
        margin: 0 0 41px;

        @include desktop() {
            margin: 0 0 65px;
        }

        h2 {
            font-family: $f-lafayette148;
            font-size: 23px;
            font-weight: 300;
            letter-spacing: .1px;
            line-height: 19px;
            margin: 0;
            padding: 0 10px;

            @include desktop() {
                font-size: 32px;
                line-height: 27px;
            }

            &:first-line {
                font-family: $f-perpetua-titling;
            }
        }
    }

    .slick-slide {
        &:not(.slick-cloned) {
            opacity: 0;
            transition-property: opacity;
            transition-duration: .5s;
        }

        .category-storyblock {
            opacity: 1;
        }
    }

    [data-slick-index="0"] {
        transition-delay: .1s;
    }

    [data-slick-index="1"] {
        transition-delay: .2s;
    }

    [data-slick-index="2"] {
        transition-delay: .3s;
    }

    [data-slick-index="3"] {
        transition-delay: .4s;
    }

    [data-slick-index="4"] {
        transition-delay: .5s;
    }

    &.animated {
        .slick-slide:not(.slick-cloned) {
            opacity: 1;
        }
    }

    &:not([data-background="true"]) {
        @include desktop() {
            padding: 0 25px !important;
        }
    }
}

.category-storyblock {
    display: inline-block;
    padding: 0 10px;

    a:hover {
        text-decoration: none;
    }

    [data-element="category_url"] {
        background: $c-black;
        display: block;

        &:focus {
            .category-storyblock-main {
                &::after {
                    opacity: 1;
                }

                .category-storyblock-main-text {
                    opacity: 1;
                }
            }
        }
    }

    &-main {
        position: relative;

        &:hover {
            &::after {
                opacity: 1;
            }

            .category-storyblock-main-text {
                opacity: 1;
            }
        }

        &::after {
            background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 85%);
            bottom: 0;
            content: '';
            display: block;
            height: 66%;
            left: 0;
            opacity: 1;
            position: absolute;
            transition: opacity .5s;
            width: 100%;

            @include desktop() {
                opacity: 0;
            }
        }

        &-text {
            bottom: 28px;
            display: block;
            left: 0;
            margin: 0;
            padding: 0;
            position: absolute;
            text-align: center;
            text-transform: uppercase;
            width: 100%;
            z-index: 20;

            @include desktop() {
                bottom: 35px;
                opacity: 0;
                transition: opacity .5s;
            }

            @include new-cms-button-large();

            .cms-button {
                transition: none;

                &:hover {
                    box-shadow: none;
                    color: $c-white;
                    opacity: 1;
                }
            }
        }
    }

    &-slider-text {
        margin-top: 3px;
        text-align: center;

        @include tablet() {
            letter-spacing: -.6px;
            margin-left: -7px;
            margin-top: -5px;
        }
        p {
            font-size: 27px;
            font-weight: 300;
            padding-bottom: 10px;
            text-transform: uppercase;

            @include tablet() {
                font-size: 49px;
                padding-bottom: 15px;
            }
        }
    }
}
