[data-content-type="editorial_block_copy"] {
    display: flex;
    margin: 0 auto 1.488%;
    max-width: 1680px;
    padding: 0 7.467%;
    width: 100%;

    @include tablet() {
        padding: 0 9.115%;
    }

    @include desktop() {
        padding: 0 10.893%;
    }

    &[data-caption-position="left"] {
        justify-content: left;
        text-align: left;
    }

    &[data-caption-position="center"] {
        justify-content: center;
        text-align: center;
    }

    &[data-caption-position="right"] {
        justify-content: right;
        text-align: right;
    }

    [data-element="title"] + [data-element="subtitle"] {
        @include tablet() {
            margin-top: -10px;
        }

        @include desktop() {
            margin-top: -20px;
        }
    }

    [data-element="title"] + [data-element="content"] {
        padding-top: 7px;
    }
}
