// Info Blocks
.info-block {
    font-size: $f-size-m;

    &.col-2 {
        width: 100% !important; // scss-lint:disable ImportantRule

        @include tablet-landscape() {
            width: calc(50% - 40px) !important; // scss-lint:disable ImportantRule
        }

        @include desktop-extra-big() {
            width: calc(50% - 20px) !important; // scss-lint:disable ImportantRule
        }

        [data-content-type="button-item"],
        .button,
        button {
            margin-bottom: 30px;
            max-width: 240px;
        }
    }
}

// Small copy
.small-copy {
    h4 {
        font-size: $f-size-base;
        letter-spacing: $letter-spacing-xxs;
    }

    p {
        font-size: $f-size-base;
    }

    span {
        display: block;
        line-height: $line-height-xxs;
    }
}

// Delimiter
.delimiter,
hr {
    background: $c-brown;
    border: 0;
    display: block;
    height: 3px;
    margin: 0 0 50px;
    text-align: left;
    width: 60px;

    &.long {
        @include tablet() {
            width: 360px;
        }
    }
}

// BlueFoot
.bluefoot-align-center {
    text-align: center;
}

[data-content-type="column-group"] {
    &[data-grid-size="12"] {
        width: 100%;
    }
}

.row-full-width-inner {
    width: 100%;
}

[data-content-type="row"] {
    flex: 0 100%;
    max-width: 100vw;
}

[data-content-type="row"],
[data-content-type="block"] {
    h1 {
        font-size: 44px;

        @include desktop() {
            font-size: $f-size-xxxl;
        }
    }

    h2 {
        font-size: 40px;

        @include desktop() {
            font-size: $f-size-xxl;
        }
    }

    h3 {
        font-size: $f-size-xl;

        @include desktop() {
            font-size: $f-size-xxxl-desktops;
        }
    }

    h4 {
        font-size: 35px;
    }

    h5 {
        font-size: $f-size-ml;
    }

    h6 {
        font-size: $f-size-l-small;
    }

    p {
        font-size: $f-size-m;
    }
}

[data-content-type="form_hero_block"] {
    height: 538px;
    margin-bottom: 37px !important; // scss-lint:disable ImportantRule
    max-width: 100%;
    width: 100%;

    @include tablet() {
        height: 600px;
        margin-bottom: 44px !important; // scss-lint:disable ImportantRule
    }

    @include desktop() {
        height: 69.3vw;
        margin-bottom: 0 !important; // scss-lint:disable ImportantRule
        max-height: 870px;
        max-width: 607px;
        width: 48.3vw;
    }

    .form-hero-header-block {
        @include size(100%);
    }

    .form-hero-header {
        @include size(100%);

        > div {
            position: relative;
        }

        > div::before {
            @include size(108px);
            background: $c-white;
            display: none;
            position: absolute;
            z-index: 100;

            @include tablet() {
                @include size(148px);
            }

            @include desktop() {
                @include size(175px);
            }
        }

        &[data-square-position="square-top-left"] {
            > div {
                &::before {
                    border-bottom-right-radius: 12px;
                    content: '';
                    display: block;
                    left: -1px;
                    top: -1px;
                }
            }
        }

        &[data-square-position="square-top-right"] {
            > div {
                &::before {
                    border-bottom-left-radius: 12px;
                    content: '';
                    display: block;
                    right: -1px;
                    top: -1px;
                }
            }
        }

        &[data-square-position="square-bottom-left"] {
            > div {
                &::before {
                    border-top-right-radius: 12px;
                    bottom: -1px;
                    content: '';
                    display: block;
                    left: -1px;
                }
            }
        }

        &[data-square-position="square-bottom-right"] {
            > div {
                &::before {
                    border-top-left-radius: 12px;
                    bottom: -1px;
                    content: '';
                    display: block;
                    right: -1px;
                }
            }
        }
    }

    .hero-background {
        @include desktop() {
            display: none;
        }

        + .hero-background-desktop {
            display: none;

            @include desktop() {
                display: block;
            }
        }
    }

    .hero-background,
    .hero-background-desktop {
        @include size(100%);
        background-position: center 0;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.form-block-page {
    .page-main {
        max-width: 1280px;
    }

    .column {
        padding: 0;

        @include desktop() {
            display: flex;
            justify-content: space-between;
            padding: 90px 12px;
        }

        > [data-content-type="row"] {
            @include desktop() {
                flex: 0 1 607px;
                margin-right: 5.7%;
            }
        }
    }

    &-inverted {
        .form-block-page-form-wrapper {
            order: -1;
        }

        .column {
            > [data-content-type="row"] {
                @include desktop() {
                    margin-left: 5.7%;
                    margin-right: 0;
                }
            }
        }
    }

    .form-block-page-form-wrapper {
        flex: 1 1 768px;
        margin: 0 auto;
        max-width: 768px;
        padding: 0 30px;

        @include tablet() {
            padding: 0 49px;
        }

        @include desktop() {
            flex: 1 1 576px;
            max-width: 576px;
            padding: 0;
        }

        .required-note {
            display: none;
        }

        input {
            &::placeholder {
                text-transform: uppercase;
            }
        }

        .action.primary {
            background-color: $c-paarl;

            &:hover {
                background-color: $c-raw-sienna;
            }
        }

        button {
            font-family: $f-lafayette148;
            font-size: $f-size-m;
            font-weight: $f-weight-base;
            height: 49px;
        }
    }

    .custom-page-title {
        margin-bottom: 9px;

        @include tablet() {
            margin-bottom: 5px;
        }

        @include desktop() {
            margin-bottom: 0;
            margin-top: -5px;
        }

        h1 {
            color: $c-black;
            font-family: $f-lafayette148;
            font-size: 42px;
            font-weight: $f-weight-light;
            line-height: 34px;
            margin-bottom: 23px;

            @include tablet() {
                font-size: 55px;
                line-height: 44px;
                margin-bottom: 33px;
            }

            @include desktop() {
                margin-bottom: 30px;
            }

            &::first-line {
                font-family: $f-perpetua-titling;
                font-weight: $f-weight-light;
                letter-spacing: 0;
            }
        }

        p {
            color: $c-black;
            font-family: $f-lafayette148;
            font-size: 15px;
            font-weight: $f-weight-light;
            letter-spacing: .3px;
            line-height: 27px;
            max-width: 421px;
            padding-bottom: 27px;

            @include tablet() {
                letter-spacing: -.15px;
            }
        }
    }
}

// Color Block module
.color-block {
    display: block !important; // scss-lint:disable ImportantRule
    margin-bottom: 60px;
    padding: 30px;
    text-align: center;

    @include desktop() {
        margin-bottom: 120px;
    }

    .quote {
        p {
            font-family: $heading-font-family;
            font-size: $f-size-ml;
            letter-spacing: $letter-spacing-ml;
            line-height: $line-height-s;
        }
    }

    .author {
        margin-bottom: 30px;

        p {
            font-size: $f-size-base;
            font-weight: lighter;
            line-height: 1;
        }

        strong {
            font-weight: $f-weight-base;
        }
    }

    .quote,
    .author,
    .description {
        margin-left: auto;
        margin-right: auto;
        max-width: 625px;

        @include desktop() {
            max-width: 980px;
        }

        p {
            padding-bottom: 0;
        }
    }

    .description {
        @include desktop() {
            max-width: $content-medium-max-width;
            padding: 0 60px;
        }
    }

    .quote,
    .description {
        @include desktop() {
            margin-bottom: 30px;
        }
    }

    [data-content-type="text"] {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// Hero block module
.hero-block {
    margin: -139px 0 60px;
    padding-bottom: 0;
    position: relative;

    @include media(max-width $bp-desktop) {
        background-color: transparent !important; // scss-lint:disable ImportantRule
        margin: -139px 0 60px;
    }

    @include desktop() {
        margin: -144px 0 120px;
        min-height: 400px;
    }

    .mobile-hero {
        @include desktop() {
            display: none !important; // scss-lint:disable ImportantRule
        }

        &.has-buttons {
            left: 50%;
            padding: 0;
            position: absolute;
            text-align: left;
            top: calc(50% - 85px);
            transform: translateX(-50%);

            [data-content-type="buttons"] [data-content-type="button-item"] {
                margin: 0 20px;
            }
        }
    }

    .desktop-hero {
        display: none !important; // scss-lint:disable ImportantRule
        pointer-events: none;

        @include desktop() {
            display: block !important; // scss-lint:disable ImportantRule
        }

        a,
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            pointer-events: auto;
        }
    }

    img {
        display: block;
        margin: 0 auto;
    }

    .heading {
        color: $c-brown;
        margin: 0 auto;
        max-width: 625px;
        padding: 30px;
        text-align: center;

        @include desktop() {
            left: 8%;
            max-width: 400px;
            padding: 0;
            position: absolute;
            text-align: left;
            top: 20%;
        }

        h1 {
            line-height: $line-height-xxs;
            margin-bottom: $content-spacing-base;

            @include desktop() {
                margin-bottom: 0;
            }
        }

        p {
            letter-spacing: $letter-spacing-sm;
            padding-bottom: 0;
        }

        &.bottom {
            @include desktop() {
                bottom: 5%;
                top: auto;
            }
        }

        &.right {
            @include desktop() {
                left: auto;
                right: 8%;
            }
        }

        &.vertical-center {
            @include desktop() {
                top: 50%;
                transform: translateY(-50%);
            }

            &.horizontal-center {
                @include desktop() {
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &.horizontal-center {
            @include desktop() {
                left: 50%;
                max-width: 980px;
                padding: 0 30px;
                transform: translateX(-50%);
            }
        }
    }

    &.centered {
        .desktop-hero {
            display: block !important; // scss-lint:disable ImportantRule
            height: 100%;
            left: 0;
            max-width: none;
            padding: 0 30px;
            top: 0;

            [data-content-type="text"] {
                position: absolute;

                // scss-lint:disable NestingDepth
                &:first-child {
                    left: 50%;
                    max-width: 625px;
                    top: 20%;
                    transform: translateX(-50%);

                    @include desktop() {
                        top: 30%;
                    }
                }

                &:last-child {
                    display: none;

                    @include desktop() {
                        bottom: 5%;
                        display: block;
                        left: 8%;
                        max-width: 350px;
                    }
                }
                // scss-lint:enable NestingDepth
            }
        }
    }

    [data-content-type="buttons"] {
        align-items: center;
        display: flex;
        height: 125px;
        justify-content: center;
        left: 50%;
        pointer-events: auto;
        position: absolute;
        transform: translateX(-50%);
        width: 100%;

        [data-content-type="button-item"] {
            background-color: $c-white;
            color: $c-black;
            margin: 0 25px;
            padding: 0;
            width: auto;

            a,
            div {
                background-color: inherit;
                color: inherit;
                height: auto;
                line-height: 25px;
                min-width: 140px;
                padding: 10px 30px;
                width: 100%;
            }
        }
    }
}

// Our methods
.col-block-3 {
    background-color: $c-grey-cms-background;
    margin-bottom: 60px;
    padding: 75px $content-spacing-m 35px;

    @include tablet() {
        padding: 142px 60px 74px;
    }

    @include desktop() {
        background-color: transparent;
        display: flex;
        margin-bottom: 120px;
        padding: 0;
        position: relative;
    }

    h2 {
        color: $c-white;
        font-weight: lighter;
        margin: 0;
        text-align: center;

        @include desktop() {
            margin: 70px 0 30px;
            position: absolute;
            width: 100%;
        }
    }

    .col-3 {
        margin: 0 auto;
        padding: 35px;
        position: relative;
        text-align: center;

        @include tablet() {
            max-width: 640px;
            padding: 68px 35px;
        }

        @include desktop() {
            height: 365px;
            max-width: none;
            overflow: hidden;
            padding-top: 147px;
            width: 100%;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $c-black;

            @include desktop() {
                border-bottom: 0;
            }
        }

        strong {
            font-weight: $f-weight-base;
        }

        p {
            color: $c-white;
            font-size: $f-size-base;
            font-weight: lighter;
            line-height: 18px;
            padding: 0;

            @include tablet() {
                font-size: $f-size-l;
                line-height: 1.4;
            }

            @include desktop() {
                font-size: $f-size-base;
                line-height: $line-height-s;
                margin: 0 auto;
                max-width: 315px;
            }
        }

        picture {
            display: none;

            @include desktop() {
                display: block;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: -5;
            }

            img {
                @include desktop() {
                    height: 365px;
                    object-fit: cover;
                    width: 100%;
                }
            }
        }
    }
}

// Products
.bf-product-container {
    display: flex;
    flex-direction: row !important; // scss-lint:disable ImportantRule
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
    padding-bottom: 0;

    @include desktop() {
        flex-wrap: nowrap;
        margin-bottom: 90px;
    }

    [data-content-type="column-group"] {
        padding: 0 $content-spacing-xxl;
        width: auto;

        @include tablet() {
            padding: 0 60px;
        }

        img {
            max-width: 540px;
            width: 100%;
        }
    }

    .title {
        p {
            font-family: $heading-font-family;
            font-size: $f-size-ml;
            font-weight: $f-weight-light;
            letter-spacing: $letter-spacing-xxs;
            line-height: 1;
            margin-top: 3px;
            padding-bottom: 0;
            text-align: center;

            @include desktop() {
                font-size: $f-size-xl;
                margin-top: 23px;
            }
        }
    }

    .subtitle {
        p {
            font-weight: $f-weight-bold;
            text-align: center;
        }
    }
}

// Banner
.bf-banner {
    align-items: center;
    color: $c-white;
    display: flex;
    flex-direction: row !important; // scss-lint:disable ImportantRule
    height: 400px;
    justify-content: center;
    margin-bottom: 60px;
    padding: 0 $content-spacing-xxl;
    position: relative;

    @include tablet() {
        height: 736px;
    }

    @include desktop() {
        height: 400px;
        justify-content: flex-start;
        margin-bottom: 120px;
        padding-left: 13%;
    }

    [data-content-type="text"] {
        pointer-events: none;
        z-index: 2;

        p {
            font-size: $f-size-base;
            font-weight: $f-weight-light;
            letter-spacing: $letter-spacing-xxs;
            line-height: $line-height-xxs;
            padding-bottom: 0;
            text-align: center;
            width: 100%;

            @include tablet() {
                font-size: $f-size-l;
            }

            @include desktop() {
                font-size: $f-size-base;
            }

            strong {
                font-family: $heading-font-family;
                font-size: $f-size-xl;
                font-weight: $f-weight-light;
                letter-spacing: $letter-spacing-ml;

                @include tablet() {
                    font-size: $f-size-xxl-tablet;
                }

                @include desktop() {
                    font-size: $f-size-xxl;
                }
            }
        }

        a,
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            pointer-events: auto;
        }
    }

    picture {
        display: block;
        left: 0;
        line-height: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;

        img {
            @include size(100%, 400px);
            object-fit: cover;

            @include tablet() {
                height: 736px;
            }

            @include desktop() {
                height: 400px;
            }
        }
    }

    .image-link {
        @include size(100%);
        left: 0;
        position: absolute;
        top: 0;
    }
}

// Overlapping images
.bf-overlap {
    display: flex;
    flex: 0 100%;
    flex-direction: row !important; // scss-lint:disable ImportantRule
    margin-left: 30px;
    max-width: $bp-desktop-extra-big;
    padding-bottom: 0;
    position: relative;
    width: auto;

    @include tablet() {
        margin-left: 60px;
    }

    @include desktop() {
        margin: 0 120px 120px;
    }

    @include desktop-extra-big() {
        justify-content: center;
    }

    [data-content-type="column-group"] {
        display: block !important; // scss-lint:disable ImportantRule

        > .column-width-12:not(.no-display):not(.no-display-forced):not(.hide-on-mobile):not(.hide-above-tablet):not(.show-on-desktop):not(.hide-on-desktop) {
            display: block !important; // scss-lint:disable ImportantRule
        }
    }

    &.right {
        justify-content: flex-end;
        margin: 0 30px 60px 0;

        @include tablet() {
            margin: 0 60px 60px 0;
        }

        @include desktop() {
            justify-content: center;
            margin: 0 120px 120px;
        }

        [data-content-type="column-group"]:nth-of-type(1) {
            @include desktop() {
                order: 2;
            }
        }

        [data-content-type="column-group"]:nth-of-type(2) {
            left: auto;
            right: 60px;

            @include tablet() {
                right: 120px;
            }

            @include desktop() {
                margin: 0 -235px 0 0;
                right: 0;
            }

            [data-content-type="text"] {
                @include desktop() {
                    margin: 50px 300px 0 0;
                }
            }
        }
    }

    [data-content-type="text"] {
        p {
            padding-bottom: 0;
        }
    }

    &:nth-child(2) {
        margin-bottom: 60px;

        @include desktop() {
            display: none !important; // scss-lint:disable ImportantRule
        }

        [data-content-type="text"] {
            padding-right: 40px;

            @include tablet() {
                padding-right: 80px;
            }

            p {
                padding-bottom: 0;
            }
        }
    }

    .column-width-12 {
        width: auto !important; // scss-lint:disable ImportantRule
    }

    [data-content-type="column-group"]:nth-of-type(1) {
        margin-top: 95px;
        max-width: 68%;
        width: auto !important; // scss-lint:disable ImportantRule
        z-index: 10;

        @include tablet() {
            margin-top: 190px;
        }

        @include desktop() {
            margin-top: 90px;
        }

        [data-content-type="text"] {
            padding-top: 12px;
        }
    }

    [data-content-type="column-group"]:nth-of-type(2) {
        left: 60px;
        position: absolute;
        top: 0;
        width: auto !important; // scss-lint:disable ImportantRule
        z-index: 5;

        @include tablet() {
            left: 120px;
        }

        @include desktop() {
            left: 0;
            margin-left: -240px;
            max-width: 903px;
            position: relative;
            top: 0;
        }

        [data-content-type="text"] {
            display: none !important; // scss-lint:disable ImportantRule

            @include desktop() {
                display: block !important; // scss-lint:disable ImportantRule
                margin: 50px 0 0 300px;
            }
        }
    }

    picture,
    img {
        display: block;
    }

    &.bg-top {
        [data-content-type="column-group"]:nth-of-type(2) {
            z-index: 20;
        }
    }

    a {
        font-weight: $f-weight-base;
    }

    strong {
        a {
            font-weight: $f-weight-bold;
        }
    }
}

.bf-overlap,
.bf-overlap-small {
    h6 {
        font-family: $base-font-family;
        font-size: $f-size-m-large;
        font-weight: $f-weight-bold;
        line-height: $line-height-s;
        margin-bottom: 0;
    }
}

// Image with quote module
.image-quote {
    margin: 0 auto 60px;
    padding: 0 $content-spacing-xxl;
    position: relative;

    @include tablet() {
        padding: 0 40px;
    }

    @include desktop() {
        margin: 0 auto 120px;
        padding: 0 120px;
    }

    a {
        font-weight: $f-weight-base;
    }

    .heading {
        color: $c-brown;
        margin: 0 auto;
        max-width: 360px;
        padding-top: $content-spacing-xxl;

        @include tablet() {
            max-width: 540px;
        }

        @include desktop() {
            left: 0;
            margin-left: 140px;
            max-width: 593px;
            padding-top: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: calc(50% - 95px);
        }
    }

    &.right {
        .heading {
            @include desktop() {
                left: auto;
                margin: 0 140px 0 0;
                right: 0;
            }
        }
    }

    &.bottom {
        padding: 0;
        text-align: center;

        @include tablet() {
            padding: 0;
        }

        @include desktop() {
            padding: 0 120px;
        }

        .heading {
            max-width: none;
            padding: 0 $content-spacing-xxl;

            @include tablet() {
                max-width: none;
            }

            @include desktop() {
                left: auto;
                margin-left: 0;
                max-width: none;
                position: relative;
                top: auto;
                transform: none;
                width: 100%;
            }
        }

        .quote {
            margin-top: 12px;

            @include desktop() {
                margin-top: 24px;
            }

            p {
                font-size: $f-size-ml;
                letter-spacing: $letter-spacing-xxs;
                padding-left: 0;

                @include desktop() {
                    font-size: $f-size-xl;
                }

                // scss-lint:disable NestingDepth
                &::before {
                    content: none;
                }
                // scss-lint:enable NestingDepth
            }
        }

        .author {
            margin-top: $content-spacing-xs;

            p {
                padding-left: 0;
            }

            strong {
                font-weight: $f-weight-bold;
            }
        }
    }
}

// Button for story telling pages
.shop-this-btn {
    margin: 0 auto;
    margin-bottom: 60px;
    max-width: 240px;

    @include desktop() {
        margin-bottom: 120px;
    }
}

// Product with quote
.product-quote {
    display: flex;
    flex-direction: row !important; // scss-lint:disable ImportantRule
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 60px;
    max-width: $bp-desktop-extra-big;
    padding: 0 $content-spacing-xxl;

    @include tablet() {
        padding: 0 60px;
    }

    @include desktop() {
        align-items: center;
        flex-wrap: inherit;
        justify-content: normal;
        margin: 0 auto 120px;
        padding: 0 120px;
    }

    &.right {
        @include desktop() {
            justify-content: flex-end;
        }

        [data-content-type="column-group"]:nth-of-type(1) {
            @include desktop() {
                order: 2;
            }
        }

        [data-content-type="column-group"]:nth-of-type(2) {
            @include desktop() {
                margin: 0 40px 0 0;
            }
        }
    }

    [data-content-type="text"] {
        p {
            padding-bottom: 0;
        }
    }

    [data-content-type="column-group"]:nth-of-type(1) {
        [data-content-type="text"] {
            margin-top: 12px;
        }
    }

    [data-content-type="column-group"]:nth-of-type(2) {
        @include desktop() {
            margin-left: 40px;
        }
    }

    [data-content-type="column-group"]:nth-of-type(1),
    [data-content-type="column-group"]:nth-of-type(2) {
        max-width: 360px;

        @include tablet() {
            max-width: 540px;
        }
    }

    [data-content-type="buttons"] {
        display: flex;
        margin-top: 30px;
        padding-left: 15px;

        @include desktop() {
            justify-content: flex-start;
            padding-left: 25px;
        }

        [data-content-type="button-item"] {
            background-color: $c-grey-gallery;
            height: auto;
            margin-right: 33px;
            padding: 0;
            width: auto;

            a,
            div {
                color: $c-black;
                line-height: 24px;
                padding: 10px 25px;
            }
        }

        [data-content-type="button-item"]:last-of-type {
            margin-right: 0;
        }
    }
}

.image-link:focus {
    outline: none;

    img {
        outline: thin dotted $c-grey-main-darker;
    }
}

.product-quote,
.image-quote {
    img,
    picture {
        display: block;
        margin: 0 auto;
    }

    .quote {
        margin-top: $content-spacing-xxl;

        @include desktop() {
            margin-top: 0;
        }

        p {
            font-family: $heading-font-family;
            font-size: $f-size-xl;
            letter-spacing: $letter-spacing-ml;
            line-height: $line-height-xxs;
            padding-left: $content-spacing-m;
            position: relative;

            @include desktop() {
                font-size: $f-size-xxl;
                padding-left: $content-spacing-xl;
            }

            &::before {
                content: '“';
                display: block;
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        &.no-quote {
            p {
                // scss-lint:disable NestingDepth
                &::before {
                    content: none;
                }
                // scss-lint:enable NestingDepth
            }
        }

        h2 {
            padding-left: $content-spacing-m;

            @include desktop() {
                padding-left: $content-spacing-xl;
            }
        }
    }

    .author {
        margin-top: $content-spacing-l;

        p {
            line-height: $line-height-sm;
            padding-left: $content-spacing-m;

            @include desktop() {
                padding-left: $content-spacing-xl;
            }
        }

        strong {
            font-weight: $f-weight-base;
        }
    }

    .quote,
    .author {
        p {
            padding-bottom: 0;
        }
    }
}

// Video
.video-js.yt-video,
.video-js.pagebuilder-video-container {
    background-color: transparent;
    height: auto;
    width: auto;

    .vjs-big-play-button {
        background-color: transparent !important; // scss-lint:disable ImportantRule
        border-radius: 50%;
        font-size: $f-size-xl;
        height: 60px;
        left: 50%;
        line-height: $line-height-xs;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 60px;
        z-index: 5;

        @include desktop() {
            bottom: 55px;
            left: 55px;
            top: auto;
            transform: none;
        }

        &:hover,
        &:focus {
            font-size: $f-size-xxl-small-desktops;

            .vjs-icon-placeholder {
                // scss-lint:disable NestingDepth
                &::before {
                    content: '\25B8';
                }
                // scss-lint:enable NestingDepth
            }
        }

        &:focus {
            outline: thin dotted $c-white;
        }

        .vjs-icon-placeholder {
            font-size: $f-size-xl;

            &::before {
                content: '\25B8';

                @include desktop() {
                    content: '\25B9';
                }
            }
        }

        &::after {
            content: 'PLAY';
            display: block;
            font-size: $f-size-base;
            margin-top: 65px;

            @include desktop() {
                margin-left: 68px;
                margin-top: 5px;
            }
        }
    }

    &.vjs-paused {
        &::after {
            @include size(100%);
            background-color: $c-video-overlay;
            content: '';
            display: block;
            left: 0;
            position: absolute;
            top: 0;
            z-index: 3;
        }

        .vjs-control-bar {
            display: none;
        }

        .vjs-big-play-button {
            display: block;
            z-index: 4;
        }
    }
}

.bf-video,
.large-video {
    .pagebuilder-video-container {
        height: 0;
        max-width: 100%;
        padding-top: 56.25%;
        position: relative;
        width: 100%;

        &::after {
            @include size(100%);
            background-color: $c-video-overlay;
            content: '';
            display: block;
            left: 0;
            position: absolute;
            top: 0;
            z-index: 4;
        }

        object,
        embed,
        iframe {
            @include size(100%);
            left: 0;
            position: absolute;
            top: 0;
        }

        &.video-played {
            .video-thumbnail,
            .vjs-big-play-button,
            &::after {
                display: none;
            }
        }
    }

    .video-thumbnail {
        @include size(100%);
        left: 0;
        position: absolute;
        top: 0;
        z-index: 3;

        img {
            width: 100%;
        }
    }

    [data-content-type="text"] {
        p {
            font-family: $heading-font-family;
            font-size: $f-size-l;
            letter-spacing: $letter-spacing-xxs;
            line-height: $line-height-xxs;
            margin-bottom: $content-spacing-m;
            padding: 0;
            text-align: center;

            @include tablet() {
                font-size: $f-size-xxxl-desktops;
                margin-bottom: $content-spacing-xxl;
            }

            @include desktop() {
                margin-bottom: 80px;
            }
        }
    }
}

.bf-video {
    &.wrapped {
        margin-bottom: 60px;
        padding: 55px $content-spacing-xxl;

        @include tablet() {
            padding: 110px 60px;
        }

        @include desktop() {
            margin-bottom: 120px;
            padding: 80px 180px 120px;
        }
    }
}

// Overlapping small image with product
.bf-overlap-small {
    display: flex;
    flex-direction: row !important; // scss-lint:disable ImportantRule
    margin: 0 30px;
    max-width: $bp-desktop-extra-big;
    padding-bottom: 0;
    position: relative;

    @include tablet() {
        margin: 60px 60px 0;
    }

    @include desktop() {
        margin: 0 auto 120px;
        padding: 0 120px;
    }

    [data-content-type="column-group"] {
        display: block !important; // scss-lint:disable ImportantRule

        > .column-width-12:not(.no-display):not(.no-display-forced):not(.hide-on-mobile):not(.hide-above-tablet):not(.show-on-desktop):not(.hide-on-desktop) {
            display: block !important; // scss-lint:disable ImportantRule
        }
    }

    &.right {
        justify-content: flex-end;

        @include desktop() {
            justify-content: space-between;
        }

        &.center {
            margin: 60px 0 0 $content-spacing-xxl;

            @include desktop() {
                margin: 120px 0 0;
                padding: 0 0 0 120px;
            }

            [data-content-type="column-group"]:nth-of-type(1) {
                @include desktop() {
                    max-width: 50%;
                }

                // scss-lint:disable NestingDepth
                [data-content-type="text"] {
                    text-align: left;
                }
                // scss-lint:enable NestingDepth
            }
        }

        [data-content-type="column-group"]:nth-of-type(1) {
            @include desktop() {
                margin: 0 0 0 10%;
                max-width: 540px;
                order: 2;
            }
        }

        [data-content-type="column-group"]:nth-of-type(1) {
            @include desktop() {
                order: 2;
            }
        }

        [data-content-type="column-group"]:nth-of-type(2) {
            left: auto;
            right: 65%;

            @include desktop() {
                padding: 0 0 0 60px;
                right: auto;
            }
        }
    }

    &.center {
        margin-left: 0;
        padding: 0;

        @include desktop-extra-big() {
            margin: 120px auto 0;
        }

        [data-content-type="column-group"]:nth-of-type(1) {
            [data-content-type="text"] {
                text-align: right;
            }
        }

        [data-content-type="column-group"]:nth-of-type(2) {
            @include desktop() {
                align-self: center;
            }
        }
    }

    &:nth-child(2) {
        margin-bottom: 60px;

        @include desktop() {
            display: none !important; // scss-lint:disable ImportantRule
        }

        [data-content-type="text"] {
            p {
                padding-bottom: 0;
            }
        }
    }

    [data-content-type="column-group"]:nth-of-type(1),
    [data-content-type="column-group"]:nth-of-type(2),
    .column-width-12 {
        width: auto !important; // scss-lint:disable ImportantRule
    }

    [data-content-type="column-group"]:nth-of-type(1) {
        margin-top: 60px;
        max-width: 82%;
        z-index: 10;

        @include tablet() {
            margin-top: 120px;
        }

        @include desktop() {
            margin: 0 8% 0 0;
            max-width: 50%;
        }

        a,
        p {
            font-size: $f-size-base;
        }

        [data-content-type="text"] {
            padding-top: 12px;

            p {
                @include desktop() {
                    padding-bottom: 0;
                }
            }
        }
    }

    [data-content-type="column-group"]:nth-of-type(2) {
        left: 65%;
        position: absolute;
        top: 0;
        z-index: 5;

        @include desktop() {
            left: auto;
            max-width: 45%;
            padding-right: 60px;
            position: relative;
        }

        picture {
            @include desktop() {
                width: 100%;
            }
        }

        [data-content-type="text"] {
            display: none;

            @include desktop() {
                display: block;
                margin-top: 60px;
                max-width: 440px;
                padding: 0;
            }
        }
    }

    picture,
    img {
        display: block;
    }
}

.bf-product,
.bf-product-container {
    a,
    p {
        font-size: $f-size-base;
        font-weight: $f-weight-base;
        padding: 0;
    }
}

.catalog-product-view,
.cms-page-view {
    .image-with-text-customize {
        position: relative;

        * {
            position: relative;
            z-index: 10;
        }

        p {
            line-height: $line-height-xxs;
            padding-bottom: $content-spacing-xs;
        }

        .hero-background-mobile,
        .hero-background-desktop {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 2;
        }

        .hero-background-mobile {
            display: block;

            @include tablet() {
                display: none;
            }
        }

        .hero-background-desktop {
            display: none;

            @include tablet() {
                display: block;
            }
        }
    }
}

.stories-block {
    h2 {
        font-size: 15px;
        font-family: $f-lafayette148;
        font-weight: $f-weight-bold;
        text-transform: uppercase;
    }

    &.tile-storyblock {
        .pagebuilder-column {
            &:nth-child(2) {
                .image-storyblock[data-caption-position='below'] {
                    .caption {
                        @include desktop() {
                            text-align: left;
                        }
                    }
                }
            }

            .image-storyblock[data-caption-position='below'] {
                .caption {
                    left: 0;
                    width: 100%;
                    margin-top: 30px;

                    .caption-title {
                        font-size: $f-size-m;
                        font-weight: $f-weight-bold;
                    }

                    .caption-link {
                        border: 0;
                        font-size: $f-size-m;
                        padding: 0;
                        text-decoration: underline;

                        &:hover {
                            background: none;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    &.image-quote {
        .quote {
            h2 {
                font-weight: $f-weight-bold;
            }

            a {
                text-decoration: underline;
                text-transform: uppercase;
            }

            h2,
            a,
            p {
                font-family: $f-lafayette148;
                font-size: $f-size-m;
                padding-left: 0;
            }

            p {
                padding-bottom: 0;
            }

            a {
                font-size: 15px;
                text-decoration: underline;

                &:hover {
                    background: none;
                    text-decoration: none;
                }
            }
        }
    }
}

.cms-button {
    border: 1px solid $c-white;
    color: $c-white;
    display: inline-block;
    font-family: $f-lafayette148;
    font-size: $f-size-m;
    font-weight: 400;
    line-height: 1;
    min-width: 174px;
    padding: 11px 20px 12px;
    text-align: center;
    text-transform: uppercase;

    @include tablet() {
        border-color: $c-white;
    }

    &-outline-light {
        background-color: $c-white-transparent-20;
    }

    &-outline-dark {
        border-color: $c-grey-fuscous;
        color: $c-grey-fuscous;
    }

    &-white {
        background-color: $c-white;
        border-color: $c-white;
        color: $c-black;
    }

    &-black {
        background-color: $c-black;
        border-color: $c-black;
        color: $c-white;
    }

    &:hover {
        opacity: .7;
        text-decoration: none;
    }
}

.mt-0 {
    margin-top: 0 !important; // scss-lint:disable ImportantRule
}

.mt-0--desktop {
    @include desktop() {
        margin-top: 0 !important; // scss-lint:disable ImportantRule
    }
}

.mt-0--mobile {
    @media only screen and (max-width: $bp-desktop) {
        margin-top: 0 !important; // scss-lint:disable ImportantRule
    }
}

.mb-0 {
    margin-bottom: 0 !important; // scss-lint:disable ImportantRule
}

.mb-0--tablet {
    @include tablet() {
        margin-bottom: 0 !important; // scss-lint:disable ImportantRule
    }
}

.mb-base {
    margin-bottom: 60px !important; // scss-lint:disable ImportantRule

    @include desktop() {
        margin-bottom: 120px !important; // scss-lint:disable ImportantRule
    }
}

.mb-large {
    margin-bottom: 90px !important; // scss-lint:disable ImportantRule

    @include desktop() {
        margin-bottom: 150px !important; // scss-lint:disable ImportantRule
    }
}

.mt-large {
    margin-top: 90px !important; // scss-lint:disable ImportantRule

    @include desktop() {
        margin-top: 150px !important; // scss-lint:disable ImportantRule
    }
}

.mt-base {
    margin-top: 60px !important; // scss-lint:disable ImportantRule

    @include desktop() {
        margin-top: 120px !important; // scss-lint:disable ImportantRule
    }
}

mb-t-0 {
    margin-bottom: 60px !important; // scss-lint:disable ImportantRule

    @include tablet() {
        margin-bottom: 0 !important; // scss-lint:disable ImportantRule
    }
}

.mb-d-0 {
    margin-bottom: 60px !important; // scss-lint:disable ImportantRule

    @include desktop() {
        margin-bottom: 0 !important; // scss-lint:disable ImportantRule
    }
}

.mb-m-0 {
    margin-bottom: 0 !important; // scss-lint:disable ImportantRule

    @include desktop() {
        margin-bottom: 120px !important; // scss-lint:disable ImportantRule
    }
}

.mb-m-base {
    margin-bottom: 60px !important; // scss-lint:disable ImportantRule

    @include tablet() {
        margin-bottom: 0 !important; // scss-lint:disable ImportantRule
    }
}

.mt-d-negative {
    margin-top: 0 !important; // scss-lint:disable ImportantRule

    @include desktop() {
        margin-top: -99px !important; // scss-lint:disable ImportantRule
    }
}

.mt-m-negative {
    margin-top: -99px !important; // scss-lint:disable ImportantRule

    @include desktop() {
        margin-top: 0 !important; // scss-lint:disable ImportantRule
    }
}

.mt-d-0 {
    @include desktop() {
        margin-top: 0 !important; // scss-lint:disable ImportantRule
    }
}

.pb-t-75 {
    @include tablet() {
        padding-bottom: 75px !important;
    }
}

.no-flex {
    display: inline-block !important; // scss-lint:disable ImportantRule
}

.story-block-six-items {
    flex-wrap: wrap;
    flex-direction: row !important;

    > [data-content-type="image"], .image-storyblock {
        img {
            vertical-align: top;
            width: 100%;
        }

        margin-left: 1% !important;
        margin-bottom: 1% !important;
        width: calc((100% - 1%) / 2) !important;

        &:nth-of-type(2n+1) {
            margin-left: 0 !important;
        }

        @include tablet() {
            margin-left: 4% !important;
            margin-bottom: 4% !important;
            width: calc((100% - 4%) / 2) !important;
        }

        @include desktop() {
            margin-left: 2.5% !important;
            margin-bottom: 2.5% !important;
            width: calc((100% - 5%) / 3) !important;

            &:nth-of-type(2n+1) {
                margin-left: 2.5% !important;
            }

            &:nth-of-type(3n+1) {
                margin-left: 0 !important;
            }
        }
    }
}