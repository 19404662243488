.preference-center-page {
    .page-title {
        margin-bottom: 40px;
        margin-top: 60px;
        max-width: 530px;
    }

    .column.main {
        flex-direction: row-reverse;
        margin-bottom: 85px;

        @include desktop() {
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            max-width: 1287px;
            padding: 95px 0 112px;
        }

        .top-form,
        .bottom-form {
            @include desktop() {
                max-width: 605px;
            }
        }
    }

    [data-content-type="row"] {

        @include tablet() {
            margin-bottom: 47px;
        }

        @include desktop() {
            margin: 5px 17px 0;
            max-height: 870px;
            max-width: 607px;
        }
    }

    .form-wrapper {
        padding: 0 15px 0 30px;

        @include tablet() {
            padding: 0 49px;
        }

        @include desktop() {
            margin-right: 20px;
            padding: 0 0 0 14px;
        }
    }

    [data-content-type="form_hero_block"] {
        margin-bottom: 27px !important; // scss-lint:disable ImportantRule
    }

    h1 {
        font-family: $f-lafayette148;
        font-size: 42px;
        font-weight: $f-weight-light;
        letter-spacing: .1px;
        line-height: 35px;
        margin-bottom: 23px;

        @include tablet() {
            font-size: 55px;
            letter-spacing: .5px;
            line-height: 44px;
            margin-bottom: 33px;
        }

        @include desktop() {
            margin-bottom: 36px;
        }

        &::first-line {
            font-family: $f-perpetua-titling;
            font-weight: $f-weight-light;
            letter-spacing: 0;
        }
    }

    p {
        font-family: $f-lafayette148;
        font-size: 15px;
        font-weight: $f-weight-light;
        letter-spacing: 0;
        line-height: 27px;
        max-width: 421px;
        padding-bottom: 27px;

        @include tablet() {
            letter-spacing: 0;
            max-width: 100%;
        }
    }

    h4 {
        font-family: $f-lafayette148;
        font-size: 24px;
        font-weight: $f-weight-light;
        line-height: 27px;
        margin-bottom: 4px;
        text-transform: uppercase;
    }

    .top-form {
        padding-bottom: 27px;

        @include tablet() {
            padding-bottom: 34px;
        }

        @include desktop() {
            padding-bottom: 27px;
        }

        h4 {
            @include tablet() {
                margin-bottom: 7px;
            }

            @include desktop() {
                margin-bottom: 5px;
            }
        }

        p {
            padding: 0 5px 18px 1px;

            @include tablet() {
                max-width: 640px;
                padding: 0 10px 21px 2px;
            }

            @include desktop() {
                max-width: 576px;
                padding: 0 0 21px;
            }
        }

        form {
            padding-right: 14px;

            @include tablet() {
                padding-right: 0;
            }

            button {
                background-color: $c-grey-fuscous;
                height: 49px;
                padding: 5px;

                @include tablet() {
                    height: 42px;
                }

                @include desktop() {
                    height: 50px;
                }

                &:hover {
                    background-color: #76726E;
                }
            }
        }

        .password {
            .control {
                margin-bottom: 30px;

                @include desktop() {
                    margin-bottom: 29px;
                }
            }
        }
    }

    .bottom-form {
        padding-right: 14px;

        @include tablet() {
            padding-right: 0;
        }

        h4 {
            margin-bottom: 6px;

            @include tablet() {
                margin-bottom: 6px;
            }
        }

        p {
            padding-bottom: 10px;

            @include tablet() {
                padding: 0 0 21px 2px;
            }

            @include desktop() {
                padding: 0 0 18px 2px;
            }
        }

        .control {
            margin-bottom: 32px;

            @include tablet() {
                margin-bottom: 30px;
            }

            @include desktop() {
                margin-bottom: 29px;
            }
        }

        button {
            background-color: $c-grey-fuscous;
            padding: 5px;

            @include tablet() {
                height: 42px;
                width: 148px;
            }

            @include desktop() {
                height: 47px;
            }

            &:hover {
                background-color: #76726E;
            }
        }
    }

    fieldset {
        margin-top: $content-spacing-base;
        width: 100%;

        @include desktop() {
            max-width: 576px;
        }

        .email {
            input {
                letter-spacing: 0;

                @include tablet() {
                    letter-spacing: .1px;
                }
            }
        }
    }

    select {
        background: $c-white url('../images/icon-pointer.svg') 93.7% 55% no-repeat;
        background-size: 8px 6px;
        letter-spacing: .2px;
        padding-left: 14px;

        @include tablet() {
            background: $c-white url('../images/icon-pointer.svg') 97.1% 55% no-repeat;
            background-size: 7px 6px;
        }

        @include desktop() {
            background: $c-white url('../images/icon-pointer.svg') 96.4% 55% no-repeat;
            background-size: 7px 6px;
        }
    }

    select,
    input {
        text-transform: uppercase;
    }

    button {
        background-color: $c-paarl;
        font-size: 15px;
        font-weight: 300;
        height: 50px;
        padding-top: 0;
        text-transform: uppercase;

        @include tablet() {
            height: 43px;
            padding: 5px 5px 7px 9px;
            width: 134px;
        }

        @include desktop() {
            height: 50px;
            padding: 0px 10px 7px 9px;
        }
    }

    .select,
    .input-text {
        max-width: 100%;
        width: 100%;
    }

    .select {
        color: $c-grey-fuscous;
        font-weight: 300;
        height: 50px;

        @include tablet() {
            padding: 3px 43px 0 16px;
        }
    }

    .chosen-container {
        max-width: 100%;

        .chosen-single {
            background-color: $c-white;
            height: 50px;
            line-height: 44px;
            text-transform: uppercase;

            > span {
                line-height: 48px;
            }
        }

        .chosen-drop {
            background-color: $c-white;
        }
    }

    .actions-toolbar {
        margin-top: 12px;
    }
}
