.cms-page-view {
    background-color: $c-white;

    h1 {
        font-family: $f-perpetua-titling;
        font-size: $f-size-xxml-mobile;

        @include tablet() {
            font-size: $f-size-xxl-tablet;
            margin-bottom: 35px;
        }

        @include tablet-landscape() {
            margin-bottom: 55px;
        }
    }

    h3 {
        font-family: $base-font-family;
    }

    p {
        font-weight: $f-weight-light;
        padding-bottom: $content-spacing-l;
    }

    .column {
        &.main {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;

            ul,
            ol {
                list-style: url('../images/icon-ul-li.svg') inside none;
                padding-left: 30px;

                @include desktop() {
                    padding-left: 80px;
                }
            }
        }
    }

    [data-content-type="row"] {
        h4 {
            font-size: $f-size-ml;
            text-transform: uppercase;
            line-height: $line-height-s;
            font-weight: $f-weight-light;
            margin-bottom: $content-spacing-m;
        }

        &.cms-sidebar {
            display: none;

            @include desktop() {
                border-left: .5px solid $c-grey-light-border;
                display: block;
                flex: 0 0 255px;
                margin-bottom: 60px;
                order: 3;
                padding: $content-spacing-xxl $content-spacing-xxl 60px 42px;
            }

            button {
                background: 0;
                color: $c-brown;
                display: block;
                font-weight: $f-weight-bold;
                height: auto;
                letter-spacing: .4px;
                line-height: 1;
                margin-bottom: 22px;
                padding: 0 0 0 18px;
                position: relative;
                text-align: left;
                text-transform: none;
                width: auto;

                // scss-lint:disable NestingDepth, SelectorDepth
                &:hover,
                &.active {
                    &::before {
                        content: url('../images/icon-pointer-right.svg');
                        left: 0;
                        position: absolute;
                        top: -1px;
                        transform: scale(.5);
                    }
                }
                // scss-lint:enable NestingDepth, SelectorDepth
            }
        }

        &.main-content {
            flex: 1 0 100%;
            order: 2;
            padding: 0 $content-spacing-l $content-spacing-l;

            @include tablet() {
                display: flex;
                flex: 1;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 0 60px 60px;
            }

            @include desktop() {
                padding: 0 120px 60px;
            }

            [data-content-type="column-group"] {
                display: block !important; // scss-lint:disable ImportantRule

                @include tablet() {
                    display: flex !important; // scss-lint:disable ImportantRule
                    flex: 1 auto;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
            }
        }

        &.large-video {
            flex: 0 0 100%;
            margin-bottom: 60px;
            order: 1;
        }
    }

    table {
        border: 0;
        margin-bottom: 30px;

        td,
        th {
            border-left: 0;
            border-right: 0;
        }

        &[border="0"] {
            td,
            th {
                border: 0;
            }
        }
    }

    .breadcrumbs {
        display: none;
    }

    .page-title-wrapper {
        margin: 30px auto 0;
        padding: 0 20px;

        @include tablet() {
            padding: 60px 60px 0;
        }

        @include desktop() {
            margin-top: 90px;
            padding: 0 120px;
        }
    }
}

.block-has-cta {
    cursor: pointer;
}

.image-wrapper {
    .quickview-button {
        display: none;

        @include desktop() {
            background-color: $c-white;
            bottom: 0;
            height: 50px;
            left: 0;
            letter-spacing: 0;
            margin: 0;
            opacity: .9;
            padding: 7px 0;
            position: absolute;
            text-align: center;
            text-decoration: none;
            transform: none;
            width: 100%;
            z-index: 15;
        }

        &:hover {
            background-color: $c-white;
            opacity: 1;
        }

        span {
            color: $c-black;
            font-size: 15px;
            font-weight: 400;
            line-height: 35px;
            text-transform: uppercase;
        }
    }

    &:hover {
        .quickview-button {
            @include desktop() {
                display: block;
            }
        }
    }
}

.cms-privacy {
    h1 {
        font-family: $f-lafayette148;
        font-size: 63px;
        font-weight: $f-weight-light;
        line-height: 53px;
        text-transform: uppercase;
        max-width: 500px;

        &::first-line {
            font-family: $f-perpetua-titling;
            font-weight: $f-weight-light;
        }
    }
}

// Adding styles that makes category slider in homepage
[data-content-type="block"].category-slider-storyblock-centered {
    margin: 0 auto;
}
