// Sticky Header - Global
.page-header {
    transform: translate3d(0, 0, 0);
    z-index: $z-header;

    &.sticky {
        background: $c-white;
        left: 0;
        max-width: 100%;
        padding: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: $z-sticky-header;

        &.has-additional-header .header.content {
            @include desktop() {
                padding: 0;
            }
        }

        .header.content {
            align-items: center;
            margin: 0 auto;

            @include tablet-big() {
                padding: 15px 32px 10px 18px;
            }

            @include desktop() {
                padding: 0;
            }

            .header-right-wrapper {
                @include tablet() {
                    right: $content-spacing-xxl;
                    top: 12px;
                }

                @include desktop() {
                    right: 0;
                    top: -2px;
                }
            }

            .block-search {
                margin-bottom: 0;
                margin-top: 20px;

                @include tablet-big() {
                    margin-top: 0;
                }

                @include desktop() {
                    margin: 5px 0 2px;
                }
            }

            .logo-wrapper {
                @include tablet-big() {
                    top: 18px;
                }
            }
        }

        .logo-wrapper {
            width: 154px;

            @include desktop() {
                margin-bottom: 0;
                margin-top: 3px;
            }

            img,
            svg {
                max-width: 100%;

                @include desktop() {
                    padding: 17px 0 12px;
                }
            }
        }
    }

    .header-additional {
        display: none;
        height: 0;
        overflow: hidden;

        @include desktop() {
            display: block;
        }

        &.is-open {
            overflow: visible;

            .content-wrapper {
                visibility: visible;
            }

            > .header.content {
                padding-bottom: 0;
            }
        }

        .content-wrapper {
            margin: 0 auto;
            max-width: calc(#{$header-max-width-big} + 120px);
            opacity: 0;
            padding: 10px 60px;
            visibility: hidden;
        }
    }
}
