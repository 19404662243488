.CmsOurPhilosophy {

    &-LShape {
        display: none;
        position: absolute;
        width: 60%;
        height: 100%;

        @include tablet {
            display: block;
        }

        @include desktop {
            width: 40%;
        }

        &_Right {
            right: 0;

            @include desktop {
                right: 230px;
            }

            .CmsOurPhilosophy-LShapeVertical {
                right: 15%;

                @include desktop {
                    right: 20%;
                }
            }
        }

        &_Left {
            left: 0;

            @include desktop {
                left: 230px;
            }

            .CmsOurPhilosophy-LShapeVertical {
                left: 15%;

                @include desktop {
                    left: 20%;
                }
            }
        }

        &Horizontal {
            position: absolute;
            background: white;
            width: 100%;
            height: 100px;
            bottom: 0;
            border-radius: 10px 10px 0 0;

            @include desktop {
                height: 160px;
            }
        }

        &Vertical {
            position: absolute;
            background: white;
            width: 100px;
            height: 100%;

            @include desktop {
                width: 160px;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 100px;
                left: 100%;
                width: 20px;
                height: 20px;
                border-bottom-left-radius: 10px;
                box-shadow: -10px 0 0 0 white;

                @include desktop {
                    bottom: 160px;
                }
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 100px;
                right: 100%;
                width: 20px;
                height: 20px;
                border-bottom-right-radius: 10px;
                box-shadow: 10px 0 0 0 white;

                @include desktop {
                    bottom: 160px;
                }
            }
        }
    }

    &-Hero {
        margin: 0;
        position: relative;

        &Image {

            img {
                margin: 0;
                width: 100%;
            }
        }

        &Text {
            display: flex;
            align-items: center;
            padding-left: 20px;
            position: absolute;
            height: 100%;

            @include tablet {
                padding-left: 130px;
            }

            @include desktop {
                padding-left: 210px;
            }

            h1 {
                font-weight: 300;

                @include desktop {
                    font-size: 63px;
                    line-height: .8;
                    margin-bottom: 0;
                }
            }
        }
    }

    &-Second {
        margin-bottom: 50px;

        @include desktop {
            flex-direction: row-reverse !important; // scss-lint:disable ImportantRule
            margin-bottom: 160px;
        }

        &Image {
            padding: 70px 0 70px 70px;

            @include tablet {
                padding: 0 0 100px 110px;
            }

            @include desktop {
                max-height: 750px;
                width: auto;
                margin-right: 230px;
                padding-bottom: 0;
            }

            img {
                @include desktop {
                    width: auto !important;
                    height: auto;
                    max-width: 30vw;
                    max-height: 723px;
                }
            }
        }

        &TextWrapper {
            @include desktop {
                align-items: center;
                justify-content: center !important;
            }
        }

        &Text {
            margin: 0 20px 0;

            @include tablet {
                margin-left: 110px;
                max-width: 440px;
            }

            &_Strong {
                @include tablet {
                    max-width: 400px;
                }
            }
        }
    }

    &-Third {
        position: relative;
    }

    &-Fourth {
        @include tablet {
            margin-bottom: 70px;
        }

        @include desktop {
            flex-direction: row !important; // scss-lint:disable ImportantRule
            margin-bottom: 160px;
        }

        &Image {
            padding: 70px 70px 50px 0;

            @include tablet {
                padding: 0 110px 100px 0;
            }

            @include desktop {
                max-height: 750px;
                width: auto;
                margin-left: 230px;
                padding-bottom: 0;
            }

            img {
                @include desktop {
                    width: auto !important;
                    height: auto;
                    max-width: 30vw;
                    max-height: 723px;
                }
            }
        }

        &TextWrapper {
            @include desktop {
                align-items: flex-start;
                justify-content: center !important;
                max-width: calc(60vw - 230px);
                margin-left: auto;
            }

            @media screen and (min-width: $storyblock-max-width) {
                max-width: 780px;
            }
        }

        &Text {
            margin: 0 70px 0 20px;

            @include tablet {
                margin: 0 110px;
                max-width: 360px;
            }

            @include desktop {
                margin: 0;
            }
        }
    }

    &-Fifth {
        position: relative;
    }

    &-Sixth {
        margin-bottom: 50px;

        @include tablet {
            margin-bottom: 100px;
        }

        @include desktop {
            flex-direction: row-reverse !important; // scss-lint:disable ImportantRule
            margin-bottom: 160px;
        }

        &Image {
            padding: 70px 0 50px 70px;

            @include tablet {
                padding: 0 0 100px 110px;
            }

            @include desktop {
                max-height: 750px;
                width: auto;
                margin-right: 230px;
                padding-bottom: 0;
            }

            img {
                @include desktop {
                    width: auto !important;
                    height: auto;
                    max-width: 30vw;
                    max-height: 723px;
                }
            }
        }

        &TextWrapper {
            @include desktop {
                align-items: flex-start;
                justify-content: center !important;
            }
        }

        &Text {
            margin: 0 70px 0 20px;

            @include tablet {
                margin: 0 110px;
                max-width: 380px;
            }

            @include desktop {
                margin: 0 0 0 230px;
            }

            &_Strong {
                text-transform: uppercase;
            }
        }
    }

    &-BottomBanner {
        position: relative;

        &Text {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 100;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            display: flex;

            @include desktop {
                flex-direction: row;
            }

            &_Mobile {
                display: none;
            }

            p {
                padding: 0;
                max-width: 330px;
                font-family: $f-lafayette148;

                @include tablet {
                    max-width: none;
                }

                &:first-child {
                    @include desktop {
                        padding-right: 5px;
                    }
                }
            }

            strong {
                font-family: $heading-font-family;
                font-size: $f-size-xl;
                font-weight: $f-weight-light;
                letter-spacing: $letter-spacing-ml;

                @include tablet {
                    font-size: 32px;
                }

                @include desktop {
                    font-size: 44px;
                }
            }
        }
    }
}
