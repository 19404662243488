@import 'unordinary-hero';
@import 'unordinary-menu';
@import 'unordinary-our';
@import 'unordinary-how-works';
@import 'unordinary-she-the-first';
@import 'unordinary-conversation';
@import 'unordinary-women-slider';
@import 'unordinary-women-bio-slider';
@import 'unordinary-women-email';
@import 'unordinary-women-video';
@import 'unordinary-cms';

.unordinary {
    h2 {
        color: $c-brown-darker;
        font-size: $f-size-xxxl-desktops;
        font-weight: $f-weight-semi-base;
        letter-spacing: .9px;
        line-height: 46px;

        @include tablet-landscape() {
            font-size: $f-size-xxl;
            letter-spacing: $letter-spacing-m;
        }
    }

    h3 {
        color: $c-brown-darker;
        font-family: $f-freight;
        font-weight: $f-weight-light;
        letter-spacing: .9px;

        @include desktop() {
            font-size: $f-size-xxl;
        }
    }

    h5 {
        font-weight: $f-weight-semi-bolder;
        letter-spacing: 0;

        @include desktop() {
            font-size: 35px;
        }
    }

    p {
        color: $c-brown-darker;
        font-size: 15.5px;
        font-weight: $f-weight-light;
        letter-spacing: 0;
        line-height: 31px;

        @include desktop() {
            line-height: 28.3px;

        }
    }
}
