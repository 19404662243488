.bf-video {
    margin-bottom: 0;

    @include heading() {
        display: block;
        margin: 0 30px;
        padding-top: 36px;

        @include desktop() {
            display: none;
        }

        p {
            font-size: 12px;
            margin-bottom: 0;

            a {
                font-family: "Lineto Akkurat", "Arial", sans-serif;
                font-size: 16px;
                font-weight: 800;
            }
        }
    }

    &.wrapped {
        padding: 0 0 55px;

        @include desktop() {
            $side-padding: calc((100% - 1242.5px) / 2);
            margin-bottom: 0;
            padding: 0 $side-padding 124px;
        }
    }

    .hide-on-mobile {
        display: none !important; // scss-lint:disable ImportantRule

        @include desktop() {
            display: block !important; // scss-lint:disable ImportantRule
        }
    }

    [data-content-type="text"] {
        p {
            @include desktop() {
                font-size: $f-size-xxxl-mobile;
                margin-bottom: 0;
                padding: 80px 0;
            }
        }
    }
}
