$sb-max-width: 1680px;

.cms-our-path {
    .first-tile-storyblock {
        .text-1[data-content-type="text_storyblock"] {
            @include desktop() {
                padding-bottom: 0;
            }

            .text-primary {
                margin-right: 10vw;
            }

            .text-secondary {
                margin-right: 10vw;

                @include tablet() {
                    margin-right: 27vw;
                }

                @include desktop() {
                    margin-right: 5vw;
                }

                @include media($storyblock-max-width) {
                    margin-right: 84px;
                }
            }
        }

        .text-2[data-content-type="text_storyblock"] {
            @include desktop() {
                padding-top: 0;
            }

            .text-primary {
                @include tablet() {
                    margin-right: 27vw;
                }
            }
        }
    }

    .tile-storyblock-4 {
        .text-1 {
            .text-primary {
                @include tablet() {
                    margin-right: 16vw;
                }
            }
        }
    }

    .fifth-storyblock.quote-storyblock {
        .quote-wrapper {
            padding: 110px 38px;

            @include tablet() {
                padding: 163px 80px;
            }

            @include desktop() {
                padding: 220px 280px;
            }

            .quote-text {
                font-size: 31px;

                @include desktop() {
                    font-size: 44px;
                }
            }
        }
    }

    .second-tile-storyblock-8 {
        .text-1[data-content-type="text_storyblock"] {
            @include desktop() {
                padding-bottom: 0;
            }
        }
    }
}
