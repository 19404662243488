// Style for Lookbook / LF148 Stories menu dropdown
.main-menu {
    .gallerymenu-block {
        .submenu {
            @include desktop() {
                display: none !important; // scss-lint:disable ImportantRule
            }
        }

        a.ui-state-focus { // scss-lint:disable QualifyingElement
            text-decoration: none !important; // scss-lint:disable ImportantRule
        }
    }

    [aria-expanded="true"] {
        .gallerymenu-block {
            .submenu {
                @include desktop() {
                    display: flex !important; // scss-lint:disable ImportantRule
                }
            }
        }
    }
}

.main-menu.initial-main-menu {
    .level0.category-item:hover {
        .gallerymenu-block {
            .submenu {
                @include desktop() {
                    display: flex !important; // scss-lint:disable ImportantRule
                }

                .placeholder {
                    @include desktop() {
                        flex: 1;
                    }
                }
            }
        }
    }
}

.gallerymenu-block {
    width: 100%;

    .submenu {
        @include desktop() {
            display: flex !important; // scss-lint:disable ImportantRule
            margin: 0 auto;
            max-width: calc(#{$header-max-width} + 120px);
        }

        @include desktop-extra-big() {
            max-width: calc(#{$header-max-width-big} + 120px);
        }
    }

    ul {
        @include desktop() {
            display: flex !important; // scss-lint:disable ImportantRule
            list-style: none outside none;
        }
    }

    li {
        background-color: $c-grey-kelp;
        position: relative;

        @include desktop() {
            width: 20%;
        }

        &:hover,
        &:focus,
        &.focus-within {
            span,
            .menu-item-overlay {
                opacity: 0;
            }
        }

        .ui-state-focus {
            span,
            + .menu-item-image .menu-item-overlay {
                opacity: 0;
            }
        }

        a {
            align-items: center;
            color: $c-white;
            display: flex !important; // scss-lint:disable ImportantRule
            font-family: $f-lafayette148;
            font-size: 21px !important; // scss-lint:disable ImportantRule
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            font-weight: $f-weight-semi-bold !important; // scss-lint:disable ImportantRule
            height: 100%;
            justify-content: center;
            letter-spacing: 0 !important; // scss-lint:disable ImportantRule
            line-height: 1 !important; // scss-lint:disable ImportantRule
            position: absolute;
            text-align: center;
            text-transform: uppercase;
            width: 100%;
            z-index: 20;

            &:hover {
                text-decoration: none;
            }

            span {
                line-height: 1;
                max-width: 270px;
                opacity: 1;
                padding: 0 $content-spacing-base;
                transition: opacity .3s ease-out;
            }
        }

        .menu-item-overlay {
            display: none;

            @include desktop() {
                background: $c-black;
                display: block;
                height: 101%;
                left: -1px;
                opacity: .45;
                position: absolute;
                top: 0;
                transition: opacity .3s ease-out;
                width: 101%;
            }
        }

        .menu-item-image {
            max-height: 100%;
            overflow: hidden;
            position: relative;
            z-index: 10;

            img {
                max-height: 100%;
                max-width: 100%;
                vertical-align: middle;
                width: 100%;
            }
        }
    }

    .placeholder {
        min-height: 358px;
        transition: background-color .5s ease-out;
        width: 100%;

        @include desktop() {
            height: auto;
            min-height: 0;
        }

        a {
            color: inherit;

            span {
                color: $c-black;
                padding: 0;
                transition: padding-bottom .4s ease-out, font-size .4s ease-out;
            }
        }

        &:hover {
            span {
                border-bottom: 1px solid $c-white;
                color: $c-black;
                opacity: 1;
            }
        }
    }
}
