// Vendor
$fa-font-path: '../vendor/font-awesome/fonts';
@import '../vendor/normalize-css/normalize';
@import '../vendor/bourbon/app/assets/stylesheets/bourbon';
@import '../vendor/neat/app/assets/stylesheets/neat';

// Variables
@import 'vars';

// Mixins
@import 'mixins/breakpoints';
@import 'mixins/common';

// IE specific styles
@import 'ie';

// Base
@import 'base/common';
@import 'base/layout';
@import 'base/typography';
@import 'base/forms';
@import 'base/icons';
@import 'base/select';
@import 'base/messages';
@import 'base/cms';

// Components - Page
@import 'components/page/promo-message';
@import 'components/page/preference-center';

// Components - Modals
@import 'components/modals/modals';
@import 'components/modals/bottom-newsletter';

// Components - Header
@import 'components/page/header/header';
@import 'components/page/header/sticky-header';

// Components - Menu
@import 'components/menu/menu';
@import 'components/menu/gallery';
@import 'components/menu/home-menu';
@import 'components/menu/shop-menu';
@import 'components/menu/main-menu-mobile';

// Components - Footer
@import 'components/page/footer/footer';
@import 'components/page/footer/newsletter';
@import 'components/page/footer/social-links';

// Components - CMS
@import 'components/cms/cms-mixins';
@import 'components/cms/cms';
@import 'components/cms/404';
@import 'components/cms/sticky-menu';
@import 'components/cms/services-page';
@import 'components/cms/unsubscribe';
@import 'components/cms/unordinary-women/unordinary';
@import 'components/cms/home/home';
@import 'components/cms/base/base-styles';
@import 'components/cms/promo-banner';
@import 'components/cms/hero-video';
@import 'components/cms/ourpath';
@import 'components/cms/our-philosophy';
@import 'components/cms/discover-services';
@import 'components/cms/social-responsibility';
@import 'components/cms/denim-story';

// StoreFinder
@import 'components/storefinder/where-to-find/page';
@import 'components/storefinder/lists/page';
@import 'components/storefinder/lists/event-list';
@import 'components/storefinder/lists/event';
@import 'components/storefinder/lists/rsvp-popup';
@import 'components/storefinder/lists/store-list';
@import 'components/storefinder/lists/store';

// Contact
@import 'components/contact/contact-us';

// Third party module CSS that must be included in all/most pages
@import 'components/third-party';

// Storyblock
@import 'components/storyblock/storyblock';

// Editorial Blocks 2022
@import 'components/editorial-blocks/common';
@import 'components/editorial-blocks/editorial-block-large';
@import 'components/editorial-blocks/editorial-slider';
@import 'components/editorial-blocks/editorial-block-large-slider';
@import 'components/editorial-blocks/editorial-block-copy';

@import 'lafayette';
