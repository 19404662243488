.cms-social-responsibility {
    .fifth-tile-storyblock {
        .text-storyblock[data-content-type='text_storyblock'] {
            .text-primary {
                @include desktop() {
                    width: 120%;
                }
            }
        }
    }
}
