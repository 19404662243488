// Main Menu Dropdown styling - mobile
.navigation-overlay {
    background: $c-black;
    height: 130%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: .2s opacity ease-in, .2s visibility ease-in;
    visibility: hidden;
    width: 100%;
    z-index: 80;
}

.nav-open {
    height: 100%;
    overflow: hidden;

    .navigation-overlay {
        opacity: .7;
        visibility: visible;
    }

    .page-wrapper {
        max-height: 99.5vh;

        @include desktop() {
            max-height: none;
        }
    }

    .page-footer {
        display: none;

        @include desktop() {
            display: block;
        }
    }

    .main-menu {
        overflow-y: auto;
    }
}

.main-menu.mobile {
    a:hover,
    a:focus {
        text-decoration: none;

        @include desktop() {
            text-decoration: underline;
        }
    }

    .submenu.level0 {
        background-color: $c-white;
        display: block !important; // scss-lint:disable ImportantRule
        height: 100%;
        left: auto !important; // scss-lint:disable ImportantRule
        opacity: 0;
        overflow: hidden;
        padding: 0 0 75px;
        position: absolute !important; // scss-lint:disable ImportantRule
        right: -312px;
        top: 0 !important; // scss-lint:disable ImportantRule
        transition: .3s right ease-in, .3s opacity ease-in;
        width: 100%;
        z-index: 100;

        @include tablet() {
            right: -384px;
        }

        &[aria-expanded="true"] {
            height: auto;
            opacity: 1;
            right: 0;
        }

        .submenu {
            display: block !important; // scss-lint:disable ImportantRule
            padding: 0;
            position: static !important; // scss-lint:disable ImportantRule

            .first {
                border: 0;
            }

            &.level1:last-child:not(.top-level-gallerymenu) {
                padding-bottom: 30px;
            }
        }

        .ui-menu-item:not(.all-category) {
            a {
                font-size: $f-size-m-large;
                padding: 0 10px;
            }
        }

        a {
            letter-spacing: 0;
            line-height: 40px;
            white-space: normal;
        }

        span {
            &.empty {
                display: none;
            }

            span {
                color: $c-grey-dark;
                display: block;
                font-size: $f-size-m-large;
                font-weight: $f-weight-semi-bold;
                letter-spacing: 0;
                line-height: 40px;
                margin-bottom: 5px;
                padding: 0 10px;
                white-space: normal;
            }
        }
    }

    .topmenu-block {
        padding: 0 30px 15px;

        @include tablet() {
            padding: 0 64px 15px;
        }

        .ui-menu-item {
            padding-bottom: 5px;

            a {
                text-transform: uppercase;
            }
        }
    }

    .submenu-block {
        padding: 0 30px;

        @include tablet() {
            padding: 0 64px;
        }

        .ui-menu-item {
            padding-bottom: 5px;

            a {
                color: $c-black;
                font-size: $f-size-m-large;
                font-weight: $f-weight-bold;
                letter-spacing: .5px;
                padding: 0 10px;
            }
        }
    }

    .ui-menu-item.level2 {
        &.first {
            span span:not(:empty) {
                padding-top: 0;
            }
        }

        span {
            span:not(:empty) {
                padding-top: 39px;
            }
        }
    }

    .all-category {
        margin: 0 30px 13px;
        padding: 0;

        @include tablet() {
            margin: 0 64px 15px;
        }

        a {
            border-bottom: 1px solid $c-grey-darker-border;
            color: $c-grey-dark;
            font-family: $f-lafayette148;
            font-size: $f-size-l-larger;
            font-weight: $f-weight-bold;
            line-height: 35px !important; // scss-lint:disable ImportantRule
            padding: 0 10px 20px;
            text-decoration: none;

            &.ui-state-focus {
                text-decoration: none;
            }
        }

        &.gallerymenu-block {
            a {
                border: 0;
                padding: 0;
            }
        }

        &.back-to-main {
            margin: 29px 23px 25px;
            position: relative;

            @include tablet() {
                margin: 25px 64px;
            }

            &::before {
                background: url('../images/icon-arrow-left-long-grey.svg') no-repeat center center;
                background-size: 10px;
                content: '';
                display: block;
                height: 10px;
                left: 2px;
                position: absolute;
                top: 14px;
                width: 10px;
            }

            span {
                color: $c-grey-dark;
                cursor: pointer;
                font-size: $f-size-base;
                font-weight: bold;
                letter-spacing: $letter-spacing-xs;
                line-height: 40px;
                padding: 0 18px;
                white-space: normal;
            }
        }
    }
}
