$radius-coeff: .01;
$lane-width-coeff: .11;
$lane-width-tablet-coeff: .12;
$lane-width-mobile-coeff: .16;

$font-size-tablet-l: 40px;
$font-size-tablet: 15px;

.page-main {
    max-width: $storyblock-max-width;
}

.l-shape-block {
    [data-element="caption_wrapper"] {
        position: absolute;

        @include media(max-width $bp-tablet - 1px) {
            padding: 0 30px;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }

        @include tablet() {
            bottom: auto;
            left: 7vw;
            padding: 0;
            right: auto;
            top: 7vw;
            transform: none;
            width: auto;
        }

        @include media($storyblock-max-width) {
            left: calc(1680px * .07);
            top: calc(1680px * .07);
        }

        &.center {
            @include tablet() {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &.right {
            @include tablet() {
                left: auto;
                right: 7vw;
                text-align: right;
            }

            @include media ($storyblock-max-width) {
                right: calc(1680px * .07);
            }
        }

        &.middle {
            @include tablet() {
                top: 50%;
                transform: translateY(-50%);
            }

            &.center {
                @include tablet() {
                    transform: translate(-50%, -50%);
                }
            }
        }

        &.bottom {
            top: 75%;

            @include tablet() {
                bottom: 7vw;
                top: auto;
            }

            @include media ($storyblock-max-width) {
                bottom: calc(1680px * .07);
            }
        }

        .caption-text {
            color: $c-white;
            display: block;
            font-family: $f-lafayette148;
            font-size: $f-size-xxml-mobile;
            font-weight: 300;
            line-height: .85;
            letter-spacing: unset;
            margin: unset;
            text-transform: uppercase;

            @include tablet {
                font-size: $f-size-xxml-mobile;
            }

            @include desktop {
                font-size: 63px;
            }

            &::first-line {
                font-family: $f-perpetua-titling;
                font-weight: 300;
            }
        }

        .cms-button {
            margin: 25px 0 0;
            min-width: 0;

            @include tablet() {
                max-width: none;
                min-width: 174px;
                width: auto;
            }

            @include desktop() {
                margin: 45px 0 0;
            }

            &.second-btn {
                margin-left: 30px;
            }
        }

        .button-wrapper {
            display: flex;
        }

        &.right {
            .button-wrapper {
                @include tablet() {
                    justify-content: flex-end;
                }
            }
        }

        &[data-caption-position-mobile="caption-under"] {
            @include media(max-width $bp-tablet - 1px) {
                background-color: $c-white;
                padding: 25px 60px 40px;
                position: static;
                top: auto;
                transform: none;
            }

            .caption-text {
                @include media(max-width $bp-tablet - 1px) {
                    color: $c-black !important; // scss-lint:disable ImportantRule
                }
            }

            .cms-button {
                @include media(max-width $bp-tablet - 1px) {
                    margin-top: 15px;
                }

                &-outline-light {
                    @include media(max-width $bp-tablet - 1px) {
                        border-color: $c-black;
                        color: $c-black;
                    }
                }

                &-white {
                    @include media(max-width $bp-tablet - 1px) {
                        background-color: $c-black;
                        border-color: $c-black;
                        color: $c-white;
                    }
                }
            }
        }
    }
}

[data-reverse-block='false'] {
    @include tablet {
        [data-element='caption'] {
            &.center {
                text-align: right;
            }
        }
    }
}

.l-shape-block {
    height: 600px;
    margin: 0 auto;
    max-width: $storyblock-max-width;
    overflow: hidden;
    position: relative;
    width: 100%;

    &.tablet-enabled {
        @include media(max-width $bp-desktop - 1px) {
            height: auto !important; // scss-lint:disable ImportantRule
            max-height: none !important; // scss-lint:disable ImportantRule
        }

        &[data-image-split-enabled='true'] {
            .image-wrapper {
                .image-secondary {
                    display: none;

                    @include desktop() {
                        display: block;
                    }

                    &.tablet {
                        display: none;

                        @include tablet() {
                            display: block;
                        }

                        @include desktop() {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    @include media(max-width $bp-tablet - 1px) {
        height: auto !important; // scss-lint:disable ImportantRule
        max-height: none !important; // scss-lint:disable ImportantRule
    }

    @include tablet {
        &[data-reverse-block='false'] {

            .image-wrapper {
                flex-direction: row;
            }

            .l-shape-wrapper {
                left: auto;
                transform: scaleX(-1);
            }
        }
    }

    .video-wrapper {
        position: static;

        iframe {
            height: calc(100vw * 5/4);
            max-height: calc(100vw * 5/4);
            vertical-align: middle;
            width: 100vw;

            @include tablet() {
                height: calc(#{$storyblock-max-width} * 16/9);
                left: 50%;
                max-height: calc(#{$storyblock-max-width} * 16/9);
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            @include media($sb-max-width) {
                height: 100%;
                max-height: calc(#{$storyblock-max-width} * 16/9);
                max-width: #{$storyblock-max-width};
            }
        }

        .video-mobile {
            @include tablet() {
                display: none;
            }
        }

        .video-desktop {
            display: none;

            @include tablet() {
                display: block;
            }
        }
    }

    .image-wrapper {
        display: flex;
        flex-direction: row-reverse;
        height: 100%;
        justify-content: space-between;
        position: relative;
        width: 100%;

        @include tablet {
            .image-primary {
                &.desktop {
                    display: block !important; // scss-lint:disable ImportantRule
                }

                &.mobile {
                    display: none;
                }
            }
        }

        &.tablet-enabled {
            .image-primary {
                @include tablet() {
                    &.desktop {
                        display: none !important; // scss-lint:disable ImportantRule
                    }

                    &.tablet {
                        display: block;
                    }

                    &.mobile {
                        display: none;
                    }
                }

                @include desktop() {
                    &.desktop {
                        display: block !important; // scss-lint:disable ImportantRule
                    }

                    &.tablet {
                        display: none;
                    }

                    &.mobile {
                        display: none;
                    }
                }
            }
        }

        picture {
            width: 100%;
        }

        img {
            max-width: 1680px;
            vertical-align: middle;
            width: 100vw;
        }

        .image-primary {
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
            width: 100%;

            &.desktop {
                display: none;
            }

            &.tablet {
                display: none;
            }
        }

        .image-secondary {
            display: none;
        }

    }

    &[data-video-enabled='true'] {
        height: calc(100vw * 16 / 9) !important; // scss-lint:disable ImportantRule
        max-height: calc(#{$storyblock-max-width} * 16 / 9 - 3px) !important; // scss-lint:disable ImportantRule

        @include media(max-width $bp-tablet - 1px) {
            height: auto !important; // scss-lint:disable ImportantRule
            max-height: none !important; // scss-lint:disable ImportantRule
        }

        @include tablet {
            height: calc(100vw * 9 / 16 - 3px) !important; // scss-lint:disable ImportantRule
        }

        @include media ($storyblock-max-width) {
            height: calc(1680px * 9 / 16) !important; // scss-lint:disable ImportantRule
        }
    }

    &[data-overlay-enabled='false'] {
        .l-shape-wrapper {
            display: none;
        }
    }

    &[data-image-split-enabled='true'] {
        .image-wrapper {

            @include tablet {

                .image-primary {
                    width: 70%;
                }

                .image-secondary {
                    background-position: top;
                    background-repeat: no-repeat;
                    background-size: cover;
                    display: block;
                    height: 100%;
                    width: 30%;
                }
            }
        }
    }
}

@mixin overlay($calculation-width, $width-coeff, $radius-coeff) {
    background-color: transparent;
    border-radius: 0;
    height: 100%;
    left: calc(30% - (#{$calculation-width} * #{$width-coeff}));
    position: absolute;
    right: calc(30% - (#{$calculation-width} * #{$width-coeff}));
    top: 0;
    width: 100%;

    .vertical {
        background: $c-white;
        bottom: 1px;
        height: 100%;
        position: absolute;
        width: calc(#{$calculation-width} * (#{$width-coeff} + .001));
    }

    .horizontal {
        background: $c-white;
        border-radius: calc(#{$calculation-width * $radius-coeff}) calc(#{$calculation-width * $radius-coeff}) 0 0;
        bottom: -1px;
        height: calc(#{$calculation-width} * #{$width-coeff} + 1px);
        left: calc((-1)*(#{$calculation-width} * #{$width-coeff})*(2/3));
        position: absolute;
        width: calc(20% + (#{$calculation-width} * #{$width-coeff})*(13/6));
    }

    .corners {
        bottom: calc(#{$calculation-width} * #{$width-coeff});
        height: calc(#{$calculation-width * $radius-coeff});
        margin-left: calc(-1 * #{$calculation-width * $radius-coeff});
        max-width: calc(#{$calculation-width} * #{$width-coeff} + (#{$calculation-width * $radius-coeff} * 2));
        overflow: hidden;
        padding-left: calc(-1 * #{$calculation-width * $radius-coeff});
        position: absolute;
        width: calc((#{$calculation-width} * #{$width-coeff}) + (#{$calculation-width * $radius-coeff} * 2));

        &::before,
        &::after {
            border-radius: calc(#{$calculation-width * $radius-coeff});
            box-shadow: 0 0 0 calc(#{$calculation-width * $radius-coeff} * 4) $c-white;
            content: '';
            margin: calc(-1 * #{$calculation-width * $radius-coeff});
            padding: calc(#{$calculation-width * $radius-coeff});
            position: absolute;
        }

        &::after {
            right: 0;
        }
    }
}

.l-shape-wrapper {
    @include tablet() {
        @include overlay(100vw, $lane-width-tablet-coeff, $radius-coeff);
    }

    @include desktop() {
        @include overlay(100vw, $lane-width-coeff, $radius-coeff);
    }

    @include media ($storyblock-max-width) {
        @include overlay($storyblock-max-width, $lane-width-coeff, $radius-coeff);
    }
}

.tablet-enabled {
    .l-shape-wrapper {
        @include tablet() {
            left: calc(20% - (100vw * .12));
            right: calc(20% - (100vw * .12));
        }

        @include desktop() {
            @include overlay(100vw, $lane-width-coeff, $radius-coeff);
        }

        @include media ($storyblock-max-width) {
            @include overlay($storyblock-max-width, $lane-width-coeff, $radius-coeff);
        }
    }
}

// Reverse L-shape styles
.l-shape-block {
    &[data-reverse-block='true'] {
        .l-shape-wrapper {
            @include desktop() {
                left: calc(30% - (100vw * .126));
            }

            @include media($sb-max-width) {
                left: calc(30% - (1680px * .126));
            }
        }
    }

    &[data-reverse-block='false'] {
        .l-shape-wrapper {
            @include desktop() {
                right: calc(30% - (100vw * .126));
            }

            @include media($sb-max-width) {
                right: calc(30% - (1680px * .126));
            }
        }
    }

    &[data-reverse-l-shape='true'] {
        [data-element="caption_wrapper"] {
            &.right {
                @include tablet() {
                    right: 20px;
                }
            }
        }

        .l-shape-wrapper {
            @include desktop() {
                left: calc(74.5% - (100vw * .11));
                transform: none;
            }

            @include media ($storyblock-max-width) {
                left: calc(74.5% - (1680px * .11));
                transform: none;
            }

            .horizontal {
                @include tablet() {
                    left: calc((-1)*(100vw * .12)*(2/4.1));
                    width: calc(19% + (100vw * .12)*(13/6));
                }

                @include desktop() {
                    left: calc((-1) * (100vw * .11) * (2 / 2.72));
                    width: calc(21.2% + (100vw * .11) * (13 / 6));
                }

                @include media ($storyblock-max-width) {
                    left: calc((-1) * (1680px * .11) * (2 / 2.6));
                    width: calc(21.2% + (1680px * .11) * (13 / 6));
                }
            }
        }
    }

    &.tablet-enabled {
        &[data-reverse-l-shape='true'] {
            .l-shape-wrapper {
                @include tablet() {
                    left: calc(71.9% - (100vw * .11));
                    transform: none;
                }

                @include desktop() {
                    left: calc(74.1% - (100vw * .11));
                    transform: none;
                }

                @include media ($storyblock-max-width) {
                    left: calc(74.5% - (1680px * .11));
                    transform: none;
                }
            }

            &[data-reverse-block='true'] {
                [data-element="caption_wrapper"] {
                    &.left {
                        @include tablet() {
                            left: 20px;
                        }
                    }
                }

                .l-shape-wrapper {
                    @include tablet() {
                        left: auto;
                        right: calc(71.9% - (100vw * .11));
                        transform: scaleX(-1);
                    }

                    @include desktop() {
                        left: auto;
                        right: calc(74.1% - (100vw * .11));
                        transform: scaleX(-1);
                    }

                    @include media ($storyblock-max-width) {
                        left: auto;
                        right: calc(74.5% - (1680px * .11));
                        transform: scaleX(-1);
                    }
                }
            }
        }
    }
}

.l-shape-bottom {
    .l-shape-wrapper {
        display: none;
    }

    .middle {
        padding-left: 40px;
        transform: translateY(-40%);

        @include tablet() {
            max-width: 420px;
            padding-left: 0;
            transform: translateY(-37%);
        }

        &.center {
            @include tablet() {
                text-align: center;
                transform: translate(-50%, -37%);
            }

            .caption-text {
                @include desktop() {
                    font-size: 58px;
                }
            }
        }

        &.right {
            @include tablet() {
                padding: 0 30px;
                transform: translateY(-50%);
                width: 25%;
            }
        }

        &.center,
        &.right {
            .caption-text {
                @include tablet() {
                    letter-spacing: 4px;
                    width: 100%;
                }

                &::first-line {
                    @include tablet() {
                        letter-spacing: -1px;
                    }
                }
            }

            .cms-button {
                @include tablet() {
                    padding: 3px 20px 10px;
                }
            }
        }
    }

    .top {
        @include tablet() {
            transform: translateY(-19%);
        }
    }

    .caption-text {
        font-family: $f-lafayette148;
        font-weight: $f-weight-base;
        letter-spacing: 1px;
        width: 150px;

        @include tablet() {
            width: 420px;
        }

        &::first-line{
            font-family: $f-perpetua-titling;
            font-weight: $f-weight-light;
            letter-spacing: -3px;

            @include tablet() {
                letter-spacing: -6px;
            }
        }
    }

    .cms-button {
        font-size: $f-size-base;
        margin-top: 12px;
        min-width: 112px;
        padding: 0 0 5px 8px;

        @include tablet() {
            font-size: $f-size-m-larger;
            margin-top: 23px;
            min-width: 174px;
            padding: 7px 20px 10px;
        }
    }
}

.storyblock-slider {
    margin: 0 auto !important; // scss-lint:disable ImportantRule
    max-width: $storyblock-max-width;

    .slick-next,
    .slick-prev {
        @include size(22px, 35px);
        padding: 0;

        @include tablet() {
            @include size(82px);
        }

        &:hover,
        &:active {
            background-color: transparent;
        }
    }

    .slick-next::before,
    .slick-prev::before {
        @include size(100%);
        background: url('../images/icon-arrow-left.png') no-repeat center;
        background-size: contain;
        content: '';
        display: block;

        @include tablet() {
            background-image: url('../images/icon-arrow-left-circle.png');
        }
    }

    .slick-prev {
        left: 5px;

        @include tablet() {
            left: 20px;
        }
    }

    .slick-next {
        right: 5px;

        @include tablet() {
            right: 20px;
        }

        &::before {
            background-image: url('../images/icon-arrow-right.png');

            @include tablet() {
                background-image: url('../images/icon-arrow-right-circle.png');
            }
        }
    }

    &[data-show-dots="true"] {
        [data-element="caption_wrapper"] {
            &[data-caption-position-mobile="caption-under"] {
                @include media(max-width $bp-tablet - 1px) {
                    padding-top: 65px;
                }
            }
        }
    }
}
